<div class=" row justify-content-around">
    <div class="col-12 col-md-6 col-lg-4 cont ">
        <a href="#national" class="chosse" role="img" (click)="cityPick(cairo)">
          <img src="../../../assets/img/top-city/cairo.svg" class="d-block w-100" alt="..."  style="background: #98b3b3">
          <div class="carousel-caption  d-md-block">
            <small>cairo </small>
            <h5>EGYPT</h5>
          </div>
        </a>
      </div>
      <div class="col-12 col-md-6 col-lg-4 cont ">
        <a href="#national" class="chosse" role="img" (click)="cityPick(kuwait)">
          <img src="../../../assets/img/top-city/kuwait.svg" class="d-block w-100" alt="..."  style="background: #98b3b3">
          <div class="carousel-caption  d-md-block">
            <small>KUWAIT </small>
            <h5>KUWAIT</h5>
          </div>
        </a>
      </div>
      <div class="col-12 col-md-6 col-lg-4 cont ">
        <a href="#national" class="chosse" role="img" (click)="cityPick(marakesh)">
          <img src="../../../assets/img/top-city/marrakech.svg" class="d-block w-100" alt="..."  style="background: #98b3b3">
          <div class="carousel-caption  d-md-block">
            <small>CASABLANCA </small>
            <h5>MOROCCO</h5>
          </div>
        </a>
      </div>
  <div class="col-12 col-md-6 col-lg-4 cont ">
    <a href="#national" class="chosse" role="img" (click)="cityPick(dubai)">
      <img src="../../../assets/img/top-city/dubai.svg" class="d-block w-100"  alt="..." style="background: #98b3b3">
      <div class="carousel-caption  d-md-block">
        <small>Dubai</small>
        <h5>UNITED ARAB EMIRATES</h5>
      </div>
    </a>
  </div>
  <div class="col-12 col-md-6 col-lg-4 cont ">
    <a href="#national" class="chosse" role="img" (click)="cityPick(paris)">
      <img src="../../../assets/img/top-city/paris.svg" class="d-block w-100"  alt="..." style="background: #98b3b3">
      <div class="carousel-caption  d-md-block">
        <small>Paris </small>
        <h5>France</h5>
      </div>
    </a>
  </div>

  <div class="col-12 col-md-6 col-lg-4 cont ">
    <a href="#national" class="chosse" role="img" (click)="cityPick(pavaria)">
      <img src="../../../assets/img/top-city/bavaria.svg" class="d-block w-100" alt="..."  style="background: #98b3b3">
      <div class="carousel-caption  d-md-block">
        <small>Hamburg</small>
        <h5>Germany</h5>
      </div>
    </a>
  </div>

</div>