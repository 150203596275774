import { Injectable } from '@angular/core';
import { FlightSearchResult } from '../flight-search-result';
import { Router } from '@angular/router';
declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class FlightServiceService {
  response: FlightSearchResult;
  serachId: string;
  sequenceNumber: number[] = [];
  providerKey: string;
  flightsInfo: string;

  constructor(private router: Router) { }


  // getDepartueCity&& departueCountry
  departureCity() {
    // item[0].allJourney.flights[0].flightDTO[0].departureTerminalAirport.cityName
    let city: string = this.response.airItineraries[0].allJourney.flights[0].flightDTO[0].departureTerminalAirport.cityName;
    let country: string = this.response.airItineraries[0].allJourney.flights[0].flightDTO[0].departureTerminalAirport.countryName;
    return city + "," + country;
  }
  // getlanding in oneway 
  landingCity() {
    // airItineraries[""0""].allJourney.flights[""0""].flightDTO[""0""].arrivalTerminalAirport

    let city: string = "";
    let country: string = '';
    // check if stops more than one
    let stops = this.response.airItineraries[0].allJourney.flights[0].stopsNum;

    if (stops > 0) {
      let flength = this.response.airItineraries[0].allJourney.flights[0].flightDTO.length;
      city = this.response.airItineraries[0].allJourney.flights[0].flightDTO[flength - 1].arrivalTerminalAirport.cityName;
      country = this.response.airItineraries[0].allJourney.flights[0].flightDTO[flength - 1].arrivalTerminalAirport.countryName;

    }
    else {
      city = this.response.airItineraries[0].allJourney.flights[0].flightDTO[0].arrivalTerminalAirport.cityName;
      country = this.response.airItineraries[0].allJourney.flights[0].flightDTO[0].arrivalTerminalAirport.countryName;
    }
    return city + "," + country;
  }

  // get depatrure in oneway 
  getDepartueDate() {


    let flight = this.flightsInfo.split("-");
    let departure = flight[2];
    return departure;
  }



  // Round Trip  //
  arrivalCity(item: any, flightN: number) {
    let ar: [] = item.allJourney.flights[flightN]["flightDTO"];
    let m: number = ar.length;
    let i: number = m - 1
    return item.allJourney.flights[flightN].flightDTO[i]["arrivalTerminalAirport"]["cityName"];
  }

  arrivalAircode(item: any, flightN: number) {
    let ar: [] = item.allJourney.flights[flightN]["flightDTO"];
    let m: number = ar.length;
    let i: number = m - 1
    return item.allJourney.flights[flightN].flightDTO[i]["arrivalTerminalAirport"]["airportCode"];
  }
  arrivalDate(item: any, flightN: number) {
    let ar: [] = item.allJourney.flights[flightN]["flightDTO"];
    let m: number = ar.length;
    let i: number = m - 1
    return item.allJourney.flights[flightN].flightDTO[i]['arrivalDate'];
  }

  arrivalStopCity(item: any, flightN: number) {
    let ar: [] = item.allJourney.flights[flightN]["flightDTO"];
    let m: number = ar.length;
    let i: number = 0
    for (i; i < m - 1; i++) {
      return item.allJourney.flights[flightN].flightDTO[i]["arrivalTerminalAirport"]["cityName"];
    }

  }
  arrivalStopAirPort(item: any, flightN: number) {
    let ar: [] = item.allJourney.flights[flightN]["flightDTO"];
    let m: number = ar.length;
    let i: number = 0
    for (i; i < m - 1; i++) {
      return item.allJourney.flights[flightN].flightDTO[i]["arrivalTerminalAirport"]["airportName"];
    }

  }
  arrivalStopAircode(item: any, flightN: number) {
    let ar: [] = item.allJourney.flights[flightN]["flightDTO"];
    let m: number = ar.length;
    let i: number = 0;
    for (i; i < m - 1; i++) {
      return item.allJourney.flights[flightN].flightDTO[i]["arrivalTerminalAirport"]["airportCode"];
    }
  }

  arrivalStopDate(item: any, flightN: number) {
    let ar: [] = item.allJourney.flights[flightN]["flightDTO"];
    let m: number = ar.length;
    let i: number = 0
    for (i; i < m - 1; i++) {
      return item.allJourney.flights[flightN].flightDTO[i]['arrivalDate'];
    }
  }

  direct(item: any) {
    if (item == 0) {
      return 'Direct';
    }
    else {
      return item + 'Stops ';
    }

  }


  showStopStation(item: any, flightN: number) {
    let stopArr: [] = [];
    let ar: [] = item.allJourney.flights[flightN]["flightDTO"];
    let m: number = ar.length;
    let i: number = 0;
    for (i; i < m; i++) {
      stopArr.push(ar[i]);
    }
    return stopArr;


  }

  fillSequenceArr() {

    for (let i = 0; i < this.response.airItineraries.length; i++) {
      this.sequenceNumber.push(this.response.airItineraries[i].sequenceNum);

    }
    console.log("sequenceNum", this.sequenceNumber, 'provider key', this.providerKey)

  }

  // route to check out
  toCheckout(sid: string, sequenceNum: number, providerKey: string) {
    this.router.navigate(['/checkout'], { queryParams: { 'sid': sid, 'sequenceNum': sequenceNum, 'providerKey': providerKey } });
  }

  // fareRulesbtn
  valuesoftrue(array: any[]) {
    let out: any[] = [];
    let arryalengty = array.length;
    for (let index = 0; index < arryalengty; index++) {
      let truth: boolean = true;
      out.push(truth);

    }
    return out;
  }

}
