<div class="backdrop">

</div>
<div class="alert-box container-fluid">
  <div class="row justify-content-center" >
    <div class="col-12 col-md-4 justify-content-center align-self-center text-center">
      <img  src="../../../assets/img/travellii logo.png" class="img-fluid errorLogo ">

    </div>
    <div class="col-12 alertTitle" >
      <!-- <p [innerHTML]="normalError"> </p> -->
      <p>  {{"searchFlight.normalError"|translate}}</p>
     

    
    </div>
    <div class=" col-12 col-md-4 ">
        <button mat-raised-button color="accent" class="searcButton mt-4" [routerLink]="['']">Search</button>
     </div>
  </div>
</div>
