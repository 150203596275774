<div class="backdrop">

  </div>
  <div class="alert-box container-fluid">
    <div class="row justify-content-center" style="width: 105%;">
      <div class="col-12 alertTitle">
      {{paymentError}} 
      </div>
      <div class=" col-12 col-md-4 ">
          <button mat-raised-button color="accent" class="searcButton mt-4"  [routerLink]="['/Contact']" routerLinkActive="router-link-active" >Search</button>
       </div>
    </div>
  </div>
  