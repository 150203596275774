
  <div class="container-fluid Search">
    <!--Start Btns-->
    <!-- <ul class="Btns row"> -->
      <!--FlightIcon-->
      <!-- <li class="searchBTN active">
        <button>
          <img src="../../../assets/img/FlightIcon.svg">
          <span>{{"header.flight"|translate}}</span>
        </button>
      </li> -->
      <!--HotelIcon-->
      <!-- <li class="searchBTN" [routerLink]="['/hotel']"   >
        <button>
          <img src="../../../assets/img/hotelIcon.svg">
          <span>{{"header.hotels"|translate}}</span>  
        </button>
      </li> -->
      <!--CarslIcon-->
      <!-- <li class="searchBTN">
        <button>
          <img  class="Holy"src="../../../assets/img/CarsIcon.svg">
          <span>{{"header.cars"|translate}}</span>  
        </button>
      </li> -->
      <!--HolidayslIcon-->
      <!-- <li class="searchBTN">
        <button>
          <img class="Cars" src="../../../assets/img/holidaysIcon.svg">
          <span>{{"header.holidays"|translate}}</span>  
        </button>
      </li> -->
    <!-- </ul> -->
    <!--start search form-->
    <form [formGroup]="searchFlight" class="SearchForm " (ngSubmit)="onSubmit()">
      <div class="row">
        <!-- pl-lg-0 -->
        <mat-radio-group aria-label="Select an option" formControlName="flightType" class="col-12 col-md-8 col-lg-6 p-0 araligment">
          <mat-radio-button value="oneway" >{{"searchFlight.oneway"|translate}}</mat-radio-button>
          <mat-radio-button value="RoundTrip" class="mopFix2">{{"searchFlight.RoundTrip"|translate}}</mat-radio-button>
          <mat-radio-button value="Multicity" class="ml-xs-0 mopFix">{{"searchFlight.multi"|translate}}</mat-radio-button>
        </mat-radio-group>
        <!-- <mat-checkbox class="col-2 alignmetar" formControlName="Direct">{{"searchFlight.dirc"|translate}}</mat-checkbox> -->
      </div>
      <div class="formInputs">

          <div class="row divinput1 ">
              <ng-container formArrayName="Flights">
                <ng-container *ngFor="let item of searchFlight.get('Flights')['controls']| slice:0:1; let i= index;">
                  <ng-container [formGroupName]='0'>
                    <mat-form-field class="col-12 col-md-4 col-lg-2 padding0 " color='accent' appearance="outline"
                      [ngClass]="{'col-lg-3': searchFlight.get('flightType').value != 'RoundTrip'}">
                      <input type="text" placeholder='{{"searchFlight.dpart"|translate}}' aria-label="City" matInput
                        formControlName="departing" [matAutocomplete]="autoa" #firstD>

                      <!-- <mat-autocomplete #autoa="matAutocomplete">
                        <mat-option *ngFor="let city of cities|filterCity:item.get('departing').value|limitTo:5"
                          [value]="city.cityName +'-'+ city.airportName +'-'+ city.airportCode">
                          {{city.airportCode}}-{{city.airportName}}-{{city.cityName}}
                        </mat-option>
                      </mat-autocomplete> -->

                      <mat-autocomplete #autoa="matAutocomplete">
                        <mat-option *ngFor="let city of cities|filterCity:item.get('departing').value|limitTo:5"
                        [value]="city.cityName +'-'+ city.airportName +'-'+ city.airportCode">
                        <i class="fas fa-map-marker-alt" style="color:#779b3d;padding:0 5px;"></i>
                        <span [innerHTML]="city.airportCode| highlighter:item.get('departing').value"></span> -- 
                        <span [innerHTML]="city.airportName| highlighter:item.get('departing').value"></span> --
                        <span  [innerHTML]="city.cityName| highlighter:item.get('departing').value"></span>
                        </mat-option>
                      </mat-autocomplete>

                    </mat-form-field>
                    <button class="col-12 col-md-1  switch" type="button" (click)='switchDes(item)'>
                      <!-- <i class="fas fa-exchange-alt"></i> -->
                      <img src="../../../assets/img/switch-dist-ico.svg" />
                    </button>
                    <mat-form-field class="col-12 col-md-4 col-lg-2 padding0  mr-2 " color='accent' appearance="outline"
                      [ngClass]="{'col-lg-3': searchFlight.get('flightType').value != 'RoundTrip'}">
                      <input type="text" placeholder='{{"searchFlight.land"|translate}}' aria-label="City" matInput
                        formControlName="landing" [matAutocomplete]="autob" #firstAr>
                   
                        <!-- <mat-autocomplete #autob="matAutocomplete">
                        <mat-option *ngFor="let city of cities|filterCity:item.get('landing').value|limitTo:5"
                        [value]="city.cityName +'-'+ city.airportName +'-'+ city.airportCode">
                          {{city.airportCode}}-{{city.airportName}}-{{city.cityName}}
                        </mat-option>
                      </mat-autocomplete> -->


                      
                      <mat-autocomplete #autob="matAutocomplete">
                        <mat-option *ngFor="let city of cities|filterCity:item.get('landing').value|limitTo:5"
                        [value]="city.cityName +'-'+ city.airportName +'-'+ city.airportCode">
                        <i class="fas fa-map-marker-alt" style="color:#779b3d;padding:0 5px;"></i>
                        <span [innerHTML]="city.airportCode| highlighter:item.get('landing').value"></span> -- 
                        <span [innerHTML]="city.airportName| highlighter:item.get('landing').value"></span> --
                        <span  [innerHTML]="city.cityName| highlighter:item.get('landing').value"></span>
                        </mat-option>
                      </mat-autocomplete>


                    </mat-form-field>
                    <mat-form-field class="col-12 col-md-4 col-lg-2 padding0 mr-2 datePointer " color='accent'
                      appearance="outline" (click)="picker1.open()">
                      <input matInput [matDatepicker]="picker1" placeholder='{{"searchFlight.dpartd"|translate}}'
                        formControlName='departingD' value="{{value|date:'MMM dd, y'}}" [min]='todayDate()' disabled
                        style="display: none" #firstARd>
                      {{searchFlight.get('Flights')['controls'][0]?.value['departingD']|date:'MMM dd, y'}}
                      <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                      <mat-datepicker #picker1 disabled="false"></mat-datepicker>
                    </mat-form-field>
                  </ng-container>
                </ng-container>
              </ng-container>
              <mat-form-field class="col-12 col-md-4 col-lg-2 padding0 mr-2 datePointer" color='accent' appearance="outline"
                *ngIf="searchFlight.get('flightType').value === 'RoundTrip' " (click)="picker.open()">
                <input matInput [matDatepicker]="picker" placeholder='{{"searchFlight.retDate"|translate}}'
                  formControlName='returnDate' [min]="searchFlight.get('Flights')['controls'][0]?.value['departingD']"
                  [value]="searchFlight.get('Flights')['controls'][0]?.value['departingD']" disabled style="display: none">
                <span *ngIf="!searchFlight.get('Flights')['controls'][1]?.value['departingD']">
                  {{searchFlight.get('Flights')['controls'][0]?.value['departingD']|date:'MMM dd, y'}} </span>
                {{searchFlight.get('Flights')['controls'][1]?.value['departingD']|date:'MMM dd, y'}}
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker disabled="false"></mat-datepicker>
              </mat-form-field>
              <div ngbDropdown class="d-inline-block menue ">
                <div class="col-12 col-md-4   menue">
                  <div ngbDropdown class="d-inline-block" autoClose="outside">
                    <button class="btn  mb-3 dropbtn " id="dropdownForm1" mat-stroked-button ngbDropdownToggle type="button"
                      ><span
                        *ngIf="searchFlight.get('passengers').valid && !searchFlight.get('passengers').dirty">{{"searchFlight.tAndC"|translate}}</span>
                      <span
                        *ngIf="searchFlight.get('passengers').valid && searchFlight.get('passengers').dirty">{{searchFlight.get('passengers.adults').value + searchFlight.get('passengers.child').value + searchFlight.get('passengers.infent').value}}|{{searchFlight.get('class').value}}</span>
                    </button>
                    <div ngbDropdownMenu aria-labelledby="dropdownForm1" style="padding-bottom:0px ;border-radius: 0;">
                      <ng-container formGroupName="passengers">
                        <div class="px-4 py-4">
                          <div class="form-group">
                            <mat-form-field style="height:50px;" appearance="outline" color='accent'>
                              <mat-label>{{"searchFlight.adults"|translate}}</mat-label>
                              <input matInput placeholder='{{"searchFlight.adults"|translate}}' value="1" type="number"
                                min="1" formControlName="adults">
                            </mat-form-field>
                          </div>
                          <div class="form-group" appearance="outline">
                            <mat-form-field style="height:50px;" appearance="outline" color='accent'>
                              <mat-label>{{"searchFlight.child"|translate}}</mat-label>
                              <input matInput placeholder='{{"searchFlight.child"|translate}}' value="0" type="number" min="0"
                                formControlName="child">
                            </mat-form-field>
                          </div>
                          <div class="form-group">
                            <mat-form-field style="height:50px;" appearance="outline" color='accent'>
                              <mat-label>{{"searchFlight.infent"|translate}}</mat-label>
                              <input matInput placeholder='{{"searchFlight.infent"|translate}}' value="0" type="number"
                                min="0" formControlName="infent" [max]="maxinfent">
                            </mat-form-field>
                          </div>
                        </div>
                      </ng-container>
                      <div class="dropdown-divider"></div>
                      <div class="form-group px-4 py-4 ">
                        <mat-form-field appearance="outline" color='accent'>
                          <mat-label>{{"searchFlight.class"|translate}}</mat-label>
                          <select matNativeControl class="custom-select" formControlName="class">
                            <option value="Economy">
                              {{"searchFlight.economy"|translate}}
                            </option>
                            <option value="Business">
                              {{"searchFlight.business"|translate}}
                            </option>
                            <option value="First">
                              {{"searchFlight.first"|translate}}
                            </option>
                          </select>
                        </mat-form-field>
                      </div>
                      <button ngbDropdownToggle class="btn  dropdown-toggle donebuttone"
                        type="button">{{"searchFlight.done"|translate}}</button>
                    </div>
                  </div>
                </div>
              </div>
      
              <!-- <button color='accent' class="col btn btn-warning btn-lg float-right mar4 searchbutton "
                type="submit">
                <i class="fa fa-search"></i>
                {{"searchFlight.search"|translate}}
              </button> -->
              <!-- <div class="col" *ngIf="searchFlight.get('flightType').value != 'Multicity'">
                <button color='accent' class="col btn btn-warning btn-lg float-right mar4 searchbutton " type="submit"
                  routerLinkActive="router-link-active">
                  <i class="fa fa-search"></i>
                  {{"searchFlight.search"|translate}}
                </button>
              </div> -->
            </div>
      
      
            <ng-container *ngIf="searchFlight.get('flightType').value === 'Multicity' ">
              <ng-container formArrayName="Flights" class="col-12"
                *ngIf="searchFlight.get('flightType').value === 'Multicity' ">
                <ng-container class="form-group"
                  *ngFor="let item of searchFlight.get('Flights')['controls']| slice:1; let i= index;">
      
                  <div [formGroupName]='indexadd1(i)' class="row">
                    <mat-form-field class="col-lg-2 padding0" color='accent' appearance="outline"
                      [ngClass]="{'col-lg-3': searchFlight.get('flightType').value != 'RoundTrip'}">
                      <input type="text" placeholder='{{"searchFlight.dpart"|translate}}' aria-label="City" matInput
                        formControlName="departing" [matAutocomplete]="ii" (change)='update()'>

<!-- 
                      <mat-autocomplete #ii="matAutocomplete">
                        <mat-option *ngFor="let city of cities|filterCity:item.get('departing').value|limitTo:5"
                        [value]="city.cityName +'-'+ city.airportName +'-'+ city.airportCode">
                          {{city.airportCode}}-{{city.airportName}}-{{city.cityName}}
                        </mat-option>
                      </mat-autocomplete> -->
                                
                      <mat-autocomplete  #ii="matAutocomplete">
                        <mat-option *ngFor="let city of cities|filterCity:item.get('departing').value|limitTo:5"
                        [value]="city.cityName +'-'+ city.airportName +'-'+ city.airportCode">
                        <i class="fas fa-map-marker-alt" style="color:#779b3d;padding:0 5px;"></i>
                        <span [innerHTML]="city.airportCode| highlighter:item.get('departing').value"></span> -- 
                        <span [innerHTML]="city.airportName| highlighter:item.get('departing').value"></span> --
                        <span  [innerHTML]="city.cityName| highlighter:item.get('departing').value"></span>
                        </mat-option>
                      </mat-autocomplete>


                    </mat-form-field>
                    <!-- <div> -->
                    <button class="col  switch" type="button" (click)='switchDes(item)'>
                      <!-- <i class="fas fa-exchange-alt"></i> -->
                      <img src="../../../assets/img/switch-dist-ico.svg" />
                    </button>
                    <!-- </div> -->
                    <mat-form-field class="col-lg-2 padding0  margAr" color='accent' appearance="outline"
                      [ngClass]="{'col-lg-3': searchFlight.get('flightType').value != 'RoundTrip'}">
                      <input type="text" placeholder='{{"searchFlight.land"|translate}}' aria-label="City" matInput
                        formControlName="landing" [matAutocomplete]="iii" (change)='update()'>

<!-- 
                      <mat-autocomplete #iii="matAutocomplete">
                        <mat-option *ngFor="let city of cities|filterCity:item.get('landing').value|limitTo:5"
                        [value]="city.cityName +'-'+ city.airportName +'-'+ city.airportCode">
                          {{city.airportCode}}-{{city.airportName}}-{{city.cityName}}
                        </mat-option>
                      </mat-autocomplete> -->

                      <mat-autocomplete  #iii="matAutocomplete">
                        <mat-option *ngFor="let city of cities|filterCity:item.get('landing').value|limitTo:5"
                        [value]="city.cityName +'-'+ city.airportName +'-'+ city.airportCode">
                        <i class="fas fa-map-marker-alt" style="color:#779b3d;padding:0 5px;"></i>
                        <span [innerHTML]="city.airportCode| highlighter:item.get('landing').value"></span> -- 
                        <span [innerHTML]="city.airportName| highlighter:item.get('landing').value"></span> --
                        <span  [innerHTML]="city.cityName| highlighter:item.get('landing').value"></span>
                        </mat-option>
                      </mat-autocomplete>


                    </mat-form-field>
                    <mat-form-field class="col-lg-2 DateMulti padding0 datepicker datePointer" color='accent'
                      appearance="outline" (click)="iiii.open()">
                      <input matInput [matDatepicker]="iiii" placeholder='{{"searchFlight.dpartd"|translate}}'
                        formControlName='departingD' [min]="searchFlight.get('Flights')['controls'][i].value['departingD']"
                        [value]="searchFlight.get('Flights')['controls'][i].value['departingD']" style="display: none;" #mg>
                      <span> {{mg.value|date:'MMM dd, y'}} </span>
      
                      <mat-datepicker-toggle matSuffix [for]="iiii"></mat-datepicker-toggle>
                      <mat-datepicker #iiii></mat-datepicker>
                    </mat-form-field>
                  </div>
      
                </ng-container>
      
              </ng-container>
      
            </ng-container>

         <!-- remove  add-->
         <div class="row" *ngIf="searchFlight.get('flightType').value === 'Multicity' ">
          <button type="button" class="btn morebtn float-right" style="border-radius: 0px" (click)='onAddFlight()'
            *ngIf="searchFlight.get('Flights').length <4">
            <i class="fa fa-plus"></i>
            {{"searchFlight.add"|translate}}
          </button>
          <button type="button" class="btn morebtn float-right" (click)='removeflight()' style="border-radius: 0px"
            *ngIf="searchFlight.get('Flights').length >2">
            <i class="fa  fa-minus"></i>
            {{"searchFlight.remove"|translate}}
          </button>
        </div>
           <!-- remove add-->

      
 

      </div>

            <!-- direct only -->
            <div class="row" *ngIf="searchFlight.get('flightType').value != 'Multicity' ">
              <div class="col-12  col-md-4 p-0 mt-2 ">
                  <mat-checkbox  formControlName="Direct">{{"searchFlight.dirc"|translate}}</mat-checkbox>
              </div>
              <div class="col-12  col-md-8">
                <div class="row">
                  <div class="col-12 col-md-6">
                      <button color='accent' class=" btn searchbutton "  color='accent' appearance="outline"  mat-raised-button type="submit"
                      routerLinkActive="router-link-active">
                      <!-- <i class="fa fa-search"></i> -->
                      {{"searchFlight.search"|translate}}
                    </button>
                  </div>

                </div>
   
              </div>
            </div>
            <!-- direct only -->
            <!-- submit button -->
            <!-- <div class="row justify-content-center align-items-center">
                <div class="col-12 col-md-3 align-self-center" *ngIf="searchFlight.get('flightType').value != 'Multicity'">
                    <button color='accent' class=" btn searchbutton "  color='accent' appearance="outline"  mat-raised-button type="submit"
                      routerLinkActive="router-link-active">

                      {{"searchFlight.search"|translate}}
                    </button>
                  </div>
            </div> -->
           <!-- submit button -->
  
          <!-- direct only -->
          <div class="row"  *ngIf="searchFlight.get('flightType').value === 'Multicity'">
              <div class="col-12  col-md-4 p-0 mt-2 ">
                  <mat-checkbox  formControlName="Direct">{{"searchFlight.dirc"|translate}}</mat-checkbox>
              </div>
              <div class="col-12  col-md-8">
                  <div class="row">
                    <div class="col-12 col-md-6">
                        <button color='accent' class=" btn   searchbutton " mat-raised-button type="submit"
                        routerLinkActive="router-link-active">
    
                        {{"searchFlight.search"|translate}}
                      </button>
                    </div>
  
                  </div>
     
                </div>
            </div>
            <!-- direct only -->
        <!-- <div class="row justify-content-center align-items-center" *ngIf="searchFlight.get('flightType').value === 'Multicity'">
          <div class="col-12 col-md-3">
              <button color='accent' class="col btn   searchbutton " mat-raised-button type="submit"
              routerLinkActive="router-link-active">
        
              {{"searchFlight.search"|translate}}
            </button>
          </div>
  
        </div> -->

    </form>
    <ng-container *ngIf="searchFlight.touched">
      <ng-container *ngTemplateOutlet="alerts">

      </ng-container>
    </ng-container>

    <ng-template #alerts>
      <p *ngIf="!searchFlight.get('passengers.adults').valid && searchFlight.get('passengers.adults').touched ">
        <ngb-alert type="warning" [dismissible]="false"> {{"searchFlight.alert1"|translate}}</ngb-alert>
      </p>
      <p *ngIf="!searchFlight.get('passengers.infent').valid && searchFlight.get('passengers.infent').touched ">
        <ngb-alert type="warning" [dismissible]="false">{{"searchFlight.alert2"|translate}}</ngb-alert>
      </p>
      <ng-container *ngIf="!searchFlight.get('passengers').valid">
        <p *ngIf="searchFlight.get('passengers').errors">
          <ngb-alert type="warning" [dismissible]="false">{{"searchFlight.alert3"|translate}} </ngb-alert>
        </p>
      </ng-container>
      <ng-container *ngFor="let flight of searchFlight.get('Flights')['controls']">
        <p *ngIf="!flight.valid && flight.dirty && flight.errors">
          <ngb-alert type="warning" [dismissible]="false">{{"searchFlight.alert4"|translate}}</ngb-alert>
        </p>
        <p *ngIf="!flight['controls']['departing'].valid && (flight['controls']['departing'].dirty || flight['controls']['departing'].touched )">
          <ngb-alert type="warning" [dismissible]="false">{{"searchFlight.alert5"|translate}}</ngb-alert>
        </p>
        <p *ngIf="!flight['controls']['landing'].valid &&( flight['controls']['landing'].dirty|| flight['controls']['landing'].touched)">
          <ngb-alert type="warning" [dismissible]="false">{{"searchFlight.alert6"|translate}}</ngb-alert>
        </p>
      </ng-container>
    </ng-template>
  </div>



