<div class="row justify-content-center align-items-center " style="width: 100%;">
    <div class=" col-12 col-12 col-md-4 card" style="margin-top: 5vh ;padding: 20px;">
        <div class="col-12 text-center">
            <a routerLink="/">
                <img src="../../../../assets/img/travellii logo.png" class="logo mb-4" alt="logo">
            </a>

        </div>
        <div class="col-12 text-center">
            <h4 class="login-title">Login to your account</h4>
        </div>
        <div style="min-height: fit-content ">
            <form [formGroup]="loginform" class="row justify-content-center " (ngSubmit)="onSubmit()">
                <div class="col-12 col-md-7">
                    <mat-form-field color="accent" appearance="outline">
                        <i class="fas fa-envelope feildicon-login" matPrefix></i>
                        <input matInput placeholder="Email address" required formControlName='Email' type="email" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$">
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-7">
                    <mat-form-field color="accent" appearance="outline">
                        <i class="fas fa-lock feildicon-login" matPrefix></i>
                        <input matInput placeholder="Password" required formControlName='Password' type="password">
                    </mat-form-field>

                </div>
                <div class=" col-12 col-md-7 text-right">
                    <!-- <a href=""> Forget PassWord ?</a> -->
                </div>
                <div class=" col-12 col-md-7 text-center">
                    <button class="SingInButton" mat-raised-button color="accent" click='onSubmit'>Sign In</button>
                </div>
                <div class="col-12 col-md-7 text-center">
                    <a [routerLink]="['/signup']"> Sign Up</a>
                </div>

            </form>

        </div>
        <ng-container *ngIf="preloader">
            <ng-container *ngTemplateOutlet="loadingkst"></ng-container>
        </ng-container>
    </div>
</div>

<ng-template #loadingkst>
    <div class="preloader">

        <div class="sk-circle">
            <div class="sk-circle1 sk-child"></div>
            <div class="sk-circle2 sk-child"></div>
            <div class="sk-circle3 sk-child"></div>
            <div class="sk-circle4 sk-child"></div>
            <div class="sk-circle5 sk-child"></div>
            <div class="sk-circle6 sk-child"></div>
            <div class="sk-circle7 sk-child"></div>
            <div class="sk-circle8 sk-child"></div>
            <div class="sk-circle9 sk-child"></div>
            <div class="sk-circle10 sk-child"></div>
            <div class="sk-circle11 sk-child"></div>
            <div class="sk-circle12 sk-child"></div>
        </div>
    </div>

</ng-template>