<div class="home">
   <header (switch)="switch($event)"></header>
   <app-big-headder></app-big-headder>
   <!-- mainContent -->
  <!-- <div class="Content"> -->
    
   <!-- <div class="searchBox">
      <app-new-search  class="newSearch" ></app-new-search>

   </div> -->
    <!-- <div class="hslider">
       
         <ngb-carousel  [showNavigationArrows]="false" >
               <ng-template ngbSlide>
                     <div class="slide-img">
                        <div class="img">
                              <img src="../../assets/img/s1.jpg" alt="Random first slide" class="img-fluid bg">
                        </div>
                    
                        <div class="img-overlay"></div>
                     </div>
                     <div class="carousel-caption">
                        <h3 class="mb-0">{{"hslider.spread"|translate}} <strong class="upperCaption"> {{"hslider.wing"|translate}}</strong></h3>
                        <p class="innercap">{{"hslider.time"|translate}} <strong class="lowerCaption"> {{"hslider.fly"|translate}}</strong></p>
                     </div>
                </ng-template>
                <ng-template ngbSlide>

                     <div class="slide-img">
                           <div class="img">
                                 <img src="../../assets/img/s4.jpg" alt="Random first slide" class="img-fluid bg">
                           </div>
                       
                        <div class="img-overlay"></div>
                     </div>
                     <div class="carousel-caption">
                        <h3 class="mb-0">{{"hslider.spread"|translate}}<strong class="upperCaption"> {{"hslider.wing"|translate}}</strong></h3>
                        <p class="innercap">{{"hslider.there"|translate}} <strong class="lowerCaption"> {{"hslider.world"|translate}}</strong></p>
                     </div>
                </ng-template>
                <ng-template ngbSlide>
                     <div class="slide-img">
                           <div class="img">
                                 <img src="../../assets/img/s3.jpg" alt="Random first slide" class="img-fluid bg">
                           </div>
                     
                           <div class="img-overlay"></div>
                      
                     </div>
                     <div class="carousel-caption">
                        <h3 class="mb-0">{{"hslider.spread"|translate}} <strong class="upperCaption"> {{"hslider.wing"|translate}}</strong></h3>
                        <p class="innercap">{{"hslider.time"|translate}} <strong class="lowerCaption">  {{"hslider.fly"|translate}}</strong></p>
                     </div>
              
                </ng-template>
          
     
         </ngb-carousel>
         
    </div> -->

<!-- slider-form -->
<div class="main-content"  [ngClass]="{'mob': type == 'Multicity' }">
     
<div class="slideArea">
     
   <ngb-carousel  [showNavigationArrows]="false" >
      <ng-template ngbSlide>
            <div class="slide-img">
               <div class="img">
                     <img src="../../assets/img/s1.jpg" alt="Random first slide" class="img-fluid bg">
               </div>
               <div class="img-overlay"></div>
           
               
            </div>
            <div class="carousel-caption">
               <h3 class="mb-0">{{"hslider.spread"|translate}} <strong class="upperCaption"> {{"hslider.wing"|translate}}</strong></h3>
               <p class="innercap">{{"hslider.time"|translate}} <strong class="lowerCaption"> {{"hslider.fly"|translate}}</strong></p>
            </div>
       </ng-template>
       <ng-template ngbSlide>

            <div class="slide-img">
                  <div class="img">
                        <img src="../../assets/img/s4.jpg" alt="Random first slide" class="img-fluid bg">
                  </div>

                   <div class="img-overlay"></div>
         
            </div>
            <div class="carousel-caption">
               <h3 class="mb-0">{{"hslider.spread"|translate}}<strong class="upperCaption"> {{"hslider.wing"|translate}}</strong></h3>
               <p class="innercap">{{"hslider.there"|translate}} <strong class="lowerCaption"> {{"hslider.world"|translate}}</strong></p>
            </div>
       </ng-template>
       <ng-template ngbSlide>
            <div class="slide-img">
                  <div class="img">
                        <img src="../../assets/img/s3.jpg" alt="Random first slide" class="img-fluid bg">
                  </div>
            
                  <div class="img-overlay"></div>
             
            </div>
            <div class="carousel-caption">
               <h3 class="mb-0">{{"hslider.spread"|translate}} <strong class="upperCaption"> {{"hslider.wing"|translate}}</strong></h3>
               <p class="innercap">{{"hslider.time"|translate}} <strong class="lowerCaption">  {{"hslider.fly"|translate}}</strong></p>
            </div>
     
       </ng-template>
 
  
</ngb-carousel>
</div>
<div class="formArea">
      <div class="navlist">
         <app-navlist (switch)="switch($event)"></app-navlist>
     </div>
   <app-new-search  class="newSearch"  (chooseType)="onChoose($event)"  *ngIf="!switchHF"></app-new-search>
   <app-hotel-search class="newSearch" *ngIf="switchHF" ></app-hotel-search>
</div>

</div>
   
<!-- slider-form --> 

   
    
      
    
  <!-- </div> -->
   <!-- mainContent -->


   <!-- hotel form -->
   
   <!-- flight form -->
   <!-- <ng-container *ngIf="!switchHF">
      <div class="row justify-content-center maincontainer2">
         <app-flight-search style="width: 100%"></app-flight-search>
      </div>
   </ng-container> -->
   
  <app-top-destination></app-top-destination> 
   <app-footer></app-footer>
</div>