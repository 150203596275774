<div class="container">
  <div class="modal-header">
    <h4 class="modal-title">Enter promo code</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <mat-form-field class="example-form-field"
        floatLabel="never" style="width: 100%;" appearance="legacy">
      <!-- <mat-label>Clearable input</mat-label> -->
      <input matInput  input  type="text" [(ngModel)]="PromoCode" minlength="3" placeholder="promo code" [disabled]='loading' >
      <button mat-button *ngIf="PromoCode && !loading" matSuffix mat-icon-button aria-label="Clear" (click)="PromoCode=''">
        <mat-icon>close</mat-icon>
      </button>
      <button mat-button *ngIf="PromoCode && PromoCode !=''&& !loading" matSuffix  color="primary" (click)="submit()">
        apply 
      </button>
      <mat-spinner  matSuffix diameter="20" *ngIf="loading"></mat-spinner>
    </mat-form-field>
    <p *ngIf="status=='Expired'">Sorry this promo is no longer exist</p>
    <p *ngIf="status == 'Invalid'"> Invalid promo code</p>
    <p *ngIf="status == 'Active'"> promo successfully applied</p>
  </div>
  <div class="modal-footer">
    <button type="button" mat-button (click)="activeModal.close('Close click')">Close</button>
  </div>




</div>

