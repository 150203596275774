import { TopDestinationService } from './../../Services/top-destination.service';
import { CurruncyServiceService } from './../../Services/curruncy-service.service';
import { FlightsInfoModule } from 'src/app/models/flights-info/flights-info.module';
import { DatePipe } from '@angular/common';
import { HotelsCitiesModule } from 'src/app/models/hotels-cities/hotels-cities.module';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { NgbDate, NgbCalendar, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { CitiesModule } from './../../models/cities/cities.module';
import { Component, OnInit } from '@angular/core';
import { MyApiService } from 'src/app/Services/my-api.service';
import { TranslateService } from '@ngx-translate/core';
import { PointOfsaleModule } from 'src/app/models/point-ofsale/point-ofsale.module';
import { SearchFlightModule } from 'src/app/models/search-flight/search-flight.module';
import { Router } from '@angular/router';
import { SessionService } from 'src/app/Services/session.service';
import airportar from "src/assets/airports/airportar.json";
import airporten from "src/assets/airports/airporten.json";

@Component({
  selector: 'app-flight-search',
  templateUrl: './flight-search.component.html',
  styleUrls: ['./flight-search.component.css'],
  providers: [DatePipe]
})
export class FlightSearchComponent implements OnInit {
  isflight: boolean = true;
  cities: CitiesModule[];
  citiesar: CitiesModule[];
  citiesen: CitiesModule[];
  citiesNames: string[] = [];
  citiesCode: string[] = [];
  fromDate: NgbDate;
  toDate: NgbDate;
  formday: Date;
  today: Date;
  currentday: NgbDate;
  roundType: boolean = false;
  dateT: boolean = false;
  dateChanged: boolean = false;
  dateErr: boolean = false;
  datStep: boolean = false;
  hoveredDate: NgbDate;
  step = 'Step0';
  searchFlight: FormGroup;
  maxinfent: number = 1;
  maxnumber: number = 9;
  travellerNo = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  flightsnumber: number = 0;
  pointof: PointOfsaleModule = new PointOfsaleModule("", "", "", "", "AE", "AE", "", false, "", 0, 0, "etyety", "erty", "erty", "AED", "ar", "", "")
  returnlink: Subscription;
  alltravellerNo: number = 0;
  multiDest: boolean = false;
  single:boolean = true;
  model: NgbDateStruct;

  private subscription: Subscription = new Subscription();

  constructor(private myApi: MyApiService, private calendar: NgbCalendar, private translate: TranslateService, private datePipe: DatePipe, private carruncy: CurruncyServiceService, private Router: Router, private session: SessionService, private topD:TopDestinationService) {



    this.fromDate = this.calendar.getToday();
    this.currentday = this.calendar.getToday();
    console.log('next', this.toDate)
    this.formday = new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day);
    console.log('fromDate', this.formday);
    this.toDate = null;
    //  this.today= new Date(this.toDate.year,this.toDate.month -1,this.toDate.day); 
    //  console.log('todate',this.today)

  }

  ngOnInit() {




    //setting up the Form with  Defult values
    this.searchFlight = new FormGroup({
      // intial flightType is one way//
      // to set onther flight type as intial stae make sure to 
      //add Fligts in the Flights array
      "flightType": new FormControl('oneway', [Validators.required]),
      "Direct": new FormControl(false, [Validators.required]),
      "Flights": new FormArray([], [Validators.required]),
      "returnDate": new FormControl(null),
      "passengers": new FormGroup({
        "adults": new FormControl(1, [Validators.required, Validators.min(1)]),
        "child": new FormControl(0, [Validators.required, Validators.min(0)]),
        "infent": new FormControl(0, [Validators.required, Validators.max(this.maxinfent), Validators.min(0)]),
      }, [this.maxValueReached.bind(this)])
      ,
      "class": new FormControl('Economy', [Validators.required])
    });
    // set Flight array with OneWay as initial state
    //uncument another one for round and another for multi
    this.onAddFlight();

    this.subscription.add(this.searchFlight.get("flightType").valueChanges.subscribe(
      (value) => {
        this.intial(value);

      }
    ));

    this.flightsnumber = this.searchFlight.get('Flights').value.length;

    this.citiesar = airportar;
    this.citiesNameExtract(this.citiesar);
    this.citiesen = airporten;
    this.citiesNameExtract(this.citiesen);
    if (localStorage.getItem('lang') == 'en') {
      this.cities = this.citiesen;
    }
    else {
      this.cities = this.citiesar;
    };

    // get cities data 
    // this.subscription.add(this.myApi.cityData(localStorage.getItem('lang')).subscribe(
    //   (res: CitiesModule[]) => { this.cities = res, this.citiesNameExtract() },
    //   (error) => {console.log(error)}
    // ));
    // get citites data in ar
    // this.subscription.add(this.myApi.cityData('ar').subscribe(
    //   (res: CitiesModule[]) => { this.citiesar = res;this.citiesNameExtract(this.citiesar); },
    //   (error) => {console.log(error)}
    // ));

    // get cities data in en
    // this.subscription.add(this.myApi.cityData('en').subscribe(
    //   (res: CitiesModule[]) => { this.citiesen = res;this.citiesNameExtract(this.citiesen);
    //     // debugger
    //   if(localStorage.getItem('lang') == 'en')

    //   {this.cities = this.citiesen;
    //   }
    //   else{
    //     this.cities = this.citiesar;
    //   }; },
    //   (error) => {console.log(error)}
    // ));
    this.subscription.add(
      this.translate.onLangChange.subscribe(

        (result) => {
          if (result.lang == 'ar') {
            debugger
            this.cities = this.citiesar;
            this.citiesNameExtract(this.citiesar);
          }
          else {
            this.cities = this.citiesen;
            this.citiesNameExtract(this.citiesen);
          }
        }
      )
    );

    this.subscription.add(this.myApi.pointOfSale().subscribe(
      (result: PointOfsaleModule) => { this.pointof = result },
      (error) => { console.log(error) }
    ));


    this.searchFlight.valueChanges.subscribe(
      () => {
        // debugger;
        // if flight-type oneWay 

        if (this.flightsnumber < 2) {
          //  oneway
          if (this.searchFlight.get('Flights').get('0').get('departing').valid && this.searchFlight.get('Flights').get('0').get('landing').invalid) {
            this.mystepper('Step1');
            this.datStep = false;
          }
          else {

            // if(this.searchFlight.get('Flights').get('0').get('departing').valid && this.searchFlight.get('Flights').get('0').get('landing').valid &&!this.datStep)
            if (this.searchFlight.get('Flights').get("0").valid && !this.datStep) {
              this.mystepper('Step2');
            }
            else {
              if (this.searchFlight.get('Flights').get('0').get('departing').invalid) {
                this.mystepper('Step0');
              }
            }
          }
        }

      }
    )
    //check passenger number
    this.searchFlight.get("passengers").valueChanges.subscribe(
      (value) => {
        console.log("valuepassenger", value)
      }
    );
    this.searchFlight.get("passengers.child").valueChanges.subscribe(
      (value) => {
        console.log("childValue", value);
      }
    );

    this.searchFlight.get("passengers.infent").valueChanges.subscribe(
      (value) => {
        console.log("infentValue", value);
      }
    );


    this.subscription.add(this.searchFlight.get('passengers.adults').valueChanges.subscribe(
      (value) => {
        this.setmaxinfentval(value);
        this.searchFlight.get('passengers.infent').updateValueAndValidity();
      }
    ));


  }


  // to add more flights to flights array by add buton
  onAddFlight() {
    let i = (<FormArray>this.searchFlight.get("Flights")).length;
    console.log(i, "flight must increased in round")
    let reval = '';
    if (i > 0) {
      reval = (<FormArray>this.searchFlight.get("Flights")).value[i - 1]['landing'];
    }
    else {
      reval = '';
    }

    (<FormArray>this.searchFlight.get("Flights")).push(new FormGroup({
      "departing": new FormControl(reval, [Validators.required, this.citynotfound.bind(this)]),
      "landing": new FormControl("", [Validators.required, this.citynotfound.bind(this)]),
      "departingD": new FormControl(this.formday, [Validators.required])
    }, [this.invalidFlightDis]));



    this.flightsnumber = this.searchFlight.get('Flights').value.length;
    // console.log(this.flightsnumber);
  }

  // reset the inetial value of the array when flightType is changed

  intial(mvalue: string) {
    if (mvalue == 'oneway') {
      this.single=true;
      this.roundType = false;
      this.multiDest = false;
      if (this.returnlink) {
        this.returnlink.unsubscribe();
      }
      this.removeArrayControllers();
      this.searchFlight.get('returnDate').setValidators(Validators.nullValidator);
      this.searchFlight.get('returnDate').updateValueAndValidity();
      // this.onAddFlight();

      return
    }
    if (mvalue == 'RoundTrip') {
      this.single = false;
      this.multiDest = false;
      this.fromDate = this.calendar.getToday();
      this.formday = new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day);
      this.toDate = this.calendar.getNext(this.calendar.getToday(), 'd', 7);
      this.today = new Date(this.toDate.year, this.toDate.month - 1, this.toDate.day);
      this.roundType = true;
      this.removeArrayControllers();

      (<FormArray>this.searchFlight.get("Flights")).push(new FormGroup({
        "departing": new FormControl(this.searchFlight.get('Flights').get('0').get('landing').value, [Validators.required, this.citynotfound.bind(this)]),
        "landing": new FormControl(this.searchFlight.get('Flights').get('0').get('departing').value, [Validators.required, this.citynotfound.bind(this)]),
        "departingD": new FormControl(this.today, [Validators.required])
      }, [this.invalidFlightDis]));

      this.returnlink = this.searchFlight.get('returnDate').valueChanges.subscribe(
        (myvalue) => {

          this.searchFlight.get('Flights').get('1').get('departingD').setValue(myvalue),
            this.searchFlight.get('Flights').get('1').get('departing').setValue(this.searchFlight.get('Flights').get('0').get('landing').value),
            this.searchFlight.get('Flights').get('1').get('landing').setValue(this.searchFlight.get('Flights').get('0').get('departing').value)
        }

      );
      //  this.roundTripI();
      this.searchFlight.get('returnDate').updateValueAndValidity();
      // console.log(this.pointof); 

      // console.log('result',this.cities);
      // console.log(this.searchFlight)
      return
    }

    if (mvalue == 'Multicity') {
      this.single=false;
      this.multiDest = true;
      this.roundType = false;
      if (this.returnlink) {
        this.returnlink.unsubscribe();
      }
      // this.returnlink.unsubscribe();
      this.searchFlight.get('returnDate').setValidators(Validators.nullValidator);
      this.searchFlight.get('returnDate').updateValueAndValidity();
      this.removeArrayControllers();
      if ((<FormArray>this.searchFlight.get("Flights")).length < 2) {
        this.onAddFlight();
      }



    }


  }

  //round trip start//
  //   roundTripI() {

  //     this.removeArrayControllers();

  //   const Rflight = new FormGroup({
  //     "departing": new FormControl(this.searchFlight.get('Flights').get("0").get("landing").setValidators([Validators.required, this.citynotfound.bind(this)])),
  //     "landing": new FormControl(this.searchFlight.get('Flights').get("0").get("departing").setValidators([Validators.required, this.citynotfound.bind(this)])),
  //     "departingD": new FormControl(this.searchFlight.get('returnDate').value)
  //   }, [this.invalidFlightDis]);

  //   (<FormArray>this.searchFlight.get("Flights")).push(Rflight);
  // }

  //call to rest form array
  removeArrayControllers() {
    while ((<FormArray>this.searchFlight.get("Flights")).length > 1) {
      (<FormArray>this.searchFlight.get("Flights")).removeAt(1);
    }
  }
  //returns an array of  cities name in lowercase used for input validations
  citiesNameExtract(cities: CitiesModule[]) {
    cities.forEach(element => {
      let city = element.cityName
      let airportName = element.airportName
      let airportcode = element.airportCode
      this.citiesNames.push(city + '-' + airportName + '-' + airportcode);
    });
  }



  // my stepper shange the steps
  mystepper(step: string) {
    this.step = step;
  }


  onDateSelection(date: NgbDate) {


    if (this.roundType) {
      debugger;

      if (!this.fromDate && !this.toDate) {
        console.log("no detection", date);

        this.fromDate = date;
        this.toDate = this.calendar.getNext(this.fromDate, 'd', 7); //after currentday by 7 days 

      } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
        debugger
        console.log("dateranged", date);
        console.log("fromdate", this.fromDate);
        this.toDate = date;
        this.today = new Date(this.toDate.year, this.toDate.month - 1, this.toDate.day);
        this.searchFlight.get('returnDate').setValue(this.datePipe.transform(this.today));
        this.searchFlight.get('returnDate').updateValueAndValidity();
        this.datStep = true;
        this.mystepper("Step3");

        // this.toDate = date;

      }

      else {
        console.log("elseeee", date);
        this.fromDate = date;
        this.formday = new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day);
        this.toDate = null;
        this.searchFlight.get('returnDate').setValue("");
        this.searchFlight.get('returnDate').updateValueAndValidity();
        this.datStep = false;
        this.mystepper("Step2");
        //returnhere 
        this.searchFlight.get('Flights').get('0').get('departingD').setValue(this.datePipe.transform(this.formday))
        this.searchFlight.get('Flights').get('0').get('departingD').updateValueAndValidity();


        // this.toDate = null;
        // this.fromDate = date;
        // this.datStep=false;
      }


    }
    else {
      //oneway 
      if (!this.fromDate && !this.toDate) {

        this.fromDate = date;


      } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
        console.log("assigned  here if ")

        this.toDate = null;
        this.fromDate = date;

        this.formday = new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day);
        this.searchFlight.get('Flights').get('0').get('departingD').setValue(this.datePipe.transform(this.formday));

        this.searchFlight.get('Flights').get('0').get('departingD').updateValueAndValidity();
        this.datStep = true;
        this.mystepper('Step3');



      } else {
        console.log("detected here else ")
        this.toDate = null;
        this.fromDate = date;
        this.formday = new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day);
        this.searchFlight.get('Flights').get('0').get('departingD').setValue(this.datePipe.transform(this.formday))
        this.searchFlight.get('Flights').get('0').get('departingD').updateValueAndValidity();
        this.datStep = true;
        this.mystepper('Step3');

      }
    }




  }


  // 

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || date.equals(this.toDate) || this.isInside(date) || this.isHovered(date);
  }


  dateTC() {
    this.dateT = !this.dateT;
  }

  //custom validators start
  // departing and landing not the same
  invalidFlightDis(flight: FormGroup): { [a: string]: boolean } {
    // tolowecase 
    if (localStorage.getItem('lang') == 'en') {
      if ((flight.get("departing").value).toLowerCase() === (flight.get("landing").value).toLowerCase()) {
        return { 'distenationNotValid': true };
      }
    }
    else {
      if (flight.get("departing").value === flight.get("landing").value) {
        return { 'distenationNotValid': true };
      }
    }

    return null;
  }

  // passengers number cant be more than 9
  maxValueReached(search: FormGroup): { [b: string]: boolean } {
    let adult = 0;
    let child = 0;
    let infent = 0;


    adult = Number(search.get("adults").value);
    child = Number(search.get("child").value);
    infent = Number(search.get("infent").value);
    this.alltravellerNo = adult + child + infent;

    console.log("traveller no", this.alltravellerNo);

    if (this.alltravellerNo > 9) {
      return { 'maxReched': true };
    }
    return null;
  }

  //infents number setup
  setmaxinfentval(value) {
    this.maxinfent = value;
    this.searchFlight.get("passengers.infent").setValidators([Validators.required, Validators.max(this.maxinfent), Validators.min(0)]);
    this.searchFlight.get("passengers.infent").updateValueAndValidity();
  }


  citiescode() {
    this.cities.forEach(element => {
      let city = element.cityCode.toLowerCase();
      this.citiesCode.push(city);
    });
  }

  //match Flights form array values with FlightInfoModule
  bendingFlights() {

    let flightout: FlightsInfoModule[] = [];
    for (let index = 0; index < (<FormArray>this.searchFlight.get("Flights")).length; index++) {
      const element = (<FormArray>this.searchFlight.get("Flights")).controls[index];
      let flight = new FlightsInfoModule(this.cityNametoCitycode(element.value['departing']),
        this.cityNametoCitycode(element.value['landing']),
        this.datePipe.transform(element.value['departingD'], 'MMMM dd, y'));
      flightout.push(flight);
    }

    return flightout;
  }

  cityNametoCitycode(cityname: string): string {
    let code: string = '';
    let all_cities = this.citiesen.concat(this.citiesar);
    all_cities.forEach(element => {
      if (element.cityName + '-' + element.airportName + '-' + element.airportCode == cityname) {
        code = element.airportCode;
      }
    });
    return code;

  }
  citynotfound(input: FormControl): { [c: string]: boolean } {
    if (this.citiesNames.indexOf(input.value) === -1) {
      return { 'notValidcity': true };
    }
    return null;
  }
  // multiii 
  //to match the formGroups name with the Flights array index
  indexadd1(index: number) {
    return index + 1;

  }
  // remove flight in multi 
  removeflight() {
    let i = (<FormArray>this.searchFlight.get("Flights")).length - 1;
    (<FormArray>this.searchFlight.get("Flights")).removeAt(i);
  }
  // retun today date
  todayDate() {
    let date = new Date();
    return date.toISOString().split('T')[0];
  }

  //show cityname and country
  displayFn(city: CitiesModule): string | undefined {
    return city ? city.cityName : undefined;
  }

  //data from top destination component
  pickedFromTop(city: string) {
    this.searchFlight.get('Flights').get("0").get('landing').setValue(city);
    this.searchFlight.get('Flights').get("0").get('landing').updateValueAndValidity();
    this.mystepper('Step2');
  }

  // get currency 
  getcurr() {
    let curr = this.carruncy.currentCurruncy.Currency_Code
    if (curr) {
      return curr
    }
    else {
      return "AED"
    }
  }

  // get id 
  id() {
    let date = new Date();
    let myId = date.getFullYear() + 'B' + date.getUTCMonth() + 'I' + date.getUTCDay() + 'S' + date.getMilliseconds() + 'H' + Math.floor(Math.random() * (9 - 0 + 1)) + 0 + 'B' + Math.floor(Math.random() * (9 - 0 + 1)) + 0 + 'I'
      + Math.floor(Math.random() * (9 - 0 + 1)) + 0
      + 'S' + Math.floor(Math.random() * (9 - 0 + 1)) + 0 + 'H' + Math.floor(Math.random() * (9 - 0 + 1)) + 0 + 'I' + Math.floor(Math.random() * (9 - 0 + 1)) + 0;
    return myId;
  }

  // to submit the form and call the search api
  onSubmit() {



    if (this.searchFlight.valid) {
      let lang = localStorage.getItem('lang');
      let ftype = this.searchFlight.get('flightType').value;
      let flightdetails = this.myApi.flightInfoFormatter(this.bendingFlights());
      let adult = this.searchFlight.get('passengers.adults').value;
      let child = this.searchFlight.get('passengers.child').value;
      let infent = this.searchFlight.get('passengers.infent').value;
      let flightPassengers = this.myApi.passingerFormatter([adult, child, infent]);
      let flightClass = this.searchFlight.get('class').value;
      // searchApi 
      let searchApi: SearchFlightModule = new SearchFlightModule(lang, this.getcurr(), this.pointof.country, ftype, flightdetails, flightPassengers, flightClass, this.id(), this.searchFlight.get('Direct').value, 'all');
      // searchApi 
      console.log(this.searchFlight.valid, this.searchFlight, "api", searchApi);


      let language = searchApi.lan;
      let currency = searchApi.Currency;
      let SearchPoint = searchApi.pointOfReservation;
      let flightType = searchApi.flightType;
      let flightInfo = searchApi.flightsInfo;
      let searchId = searchApi.serachId;
      let passengers = searchApi.passengers;
      let Cclass = searchApi.Cclass;
      let directOnly = searchApi.showDirect;
      // check for flightType
      console.log("flightType", flightType);
      this.Router.navigate(['/flightResult', language, currency, SearchPoint, flightType, flightInfo, searchId, passengers, Cclass, directOnly]);
      
    
      this.session.setSessionTime(30);
      this.session.startTimer();
    }


  }

  // unsubscribe 
  ngOnDestroy() {
    this.subscription.unsubscribe();

  }
}
