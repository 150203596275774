

<header class="header"></header>
<app-big-headder class="animated fadeInDown" id="navbar"></app-big-headder>


<section class="notfound">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h1>404</h1> 
      </div>
      <div class="col-md-12">
        <p>{{'notfound.text' | translate}}</p> 
      </div>
      <div class="col-md-12">
        <div class="sub"> 
          <p><a routerLink="/"> {{'notfound.backhome' | translate}}</a></p> 
         </div> 
      </div>
    </div>
  </div>
</section>


<app-footer></app-footer>
