import { Component, OnInit, Input } from '@angular/core';
import { offeritem } from './../top-destination.component';
import { TopDestinationService } from './../../../Services/top-destination.service';



@Component({
  selector: 'app-destination',
  templateUrl: './destination.component.html',
  styleUrls: ['./destination.component.css']
})
export class DestinationComponent implements OnInit {
@Input()title :string ='title';
@Input()imageSrc:string ='../../assets/img/blank.svg';
@Input()content:string = 'some data about some destination';
@Input()flight:offeritem;
@Input()hotel:offeritem
  constructor( public topD:TopDestinationService ) { }

  ngOnInit() {
  }

  ahmed() {
     console.log(this.flight);
     console.log(this.hotel)
  }

}
