import { Component, OnInit, Input } from '@angular/core';
import { MyApiService } from 'src/app/Services/my-api.service';
@Component({
  selector: 'app-upcoming-ahistory-h',
  templateUrl: './upcoming-ahistory-h.component.html',
  styleUrls: ['./upcoming-ahistory-h.component.css']
})
export class UpcomingAhistoryHComponent implements OnInit {
  @Input()email:string;
  loading:boolean = true;
  panelOpenState = false;
  upcoming:any[];
  history:any[];
  upcomingl:number = 0;
  historyl:number = 0;
  defaultImage="../../../../assets/img/blank.svg"
  constructor(private myapi:MyApiService) { }

  ngOnInit() {
    this.myapi.UpcomingAndHistoryH(this.email).subscribe(
      (result)=>{
        this.upcomingl = (<[]>result.Upcoming).length;
        this.historyl = (<[]>result.Histories).length;
        this.upcoming = result.Upcoming;
        this.history = result.Histories;
        console.log(result);
        this.loading = false;
      }
    )
  }

    // get ratingstars 
 getRating(stars:number)
 {
   let starsArr=[];
   for (let i=0;i<stars;i++)
   {
     starsArr.push(i);

   }
   
   return starsArr;
 }


}
