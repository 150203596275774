

      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{"SearchResult.terms"|translate}}
          </h5>
          <button type="button" class="close"  aria-label="Close" (click)="activeModal.dismiss('Cross click')"> 
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <!-- NEW FARE RULES   -->
        <div class="modal-body" style="direction:ltr;text-align: left;">
          <!-- fare rules details  -->
          <!-- loop farerules for each flight  -->
          <ng-container *ngIf="FareLoading">
            <ng-container *ngTemplateOutlet="loadingkst"></ng-container>

          </ng-container>
          <ng-container *ngIf="!FareLoading && fare">
            <p *ngIf="fare.fares?.length==0 || fare.fares==null" class="nofareText">
              {{"SearchResult.noRules"|translate}}
            </p>
            <ng-container *ngFor="let fareitem of fare.fares">
              <div class="fare-content">
                <h5 class="flightCode">
                  <!-- (CAI-AMM) -->
                  {{fareitem.departureCountry}} -- {{fareitem.arrivalCountry}}
                </h5>
                <!-- rules for each person age -->
                <div class="rules">
                  <ng-container *ngIf="fareitem.adtRules.length == 0">
                    <p class="nofareText">
                      {{"SearchResult.noAdult"|translate}}
                    </p>
                  </ng-container>
                  <ng-container *ngIf="fareitem.adtRules.length != 0">
                    <h5 class="person-rule"> {{"SearchResult.AdultRtitle"|translate}}</h5>
                    <!-- loop of rules  -->
                    <ng-container *ngFor="let adultRule of fareitem.adtRules">
                      <div class="all-rule-details">
                        <h5 class="rule-titel">{{adultRule.title}}</h5>
                        <p class="rule-desc">
                          {{adultRule.fareRule}}
                        </p>
                      </div>
                    </ng-container>
                  </ng-container>

                  <!-- child rules -->
                  <ng-container *ngIf="fareitem.cnnRules.length == 0">
                    <p class="nofareText">
                      {{"SearchResult.noChild"|translate}}
                    </p>
                  </ng-container>
                  <div class=" " *ngIf="fareitem.cnnRules.length > 0">
                    <h5 class="person-rule">{{"SearchResult.childRtitle"|translate}}</h5>
                    <ng-container *ngFor="let childRule of fareitem.cnnRules">
                      <div class="all-rule-details">
                        <h5 class="rule-titel">{{childRule.title}}</h5>
                        <p class="rule-desc">
                          {{childRule.fareRule}}
                        </p>
                      </div>
                    </ng-container>
                  </div>

                  <!-- infRules -->
                  <ng-container *ngIf="fareitem.infRules.length == 0">
                    <p class="nofareText">
                      {{"SearchResult.noInfent"|translate}}
                    </p>
                  </ng-container>
                  <div class="" *ngIf="fareitem.infRules.length > 0">
                    <h5 class="person-rule"> {{"SearchResult.InfentRtitle"|translate}}</h5>
                    <ng-container *ngFor="let infRules of fareitem.infRules">
                      <div class="all-rule-details">
                        <h5 class="rule-titel">{{infRules.title}}</h5>
                        <p class="rule-desc">
                          {{infRules.fareRule}}
                        </p>
                      </div>

                    </ng-container>
                  </div>

                </div>
                <!-- rules for each person age -->


              </div>
            </ng-container>

          </ng-container>



        </div>
        <!-- NEW FARE RULES  -->
   
      </div>
 
 

    <ng-template #loadingkst>
        <div class="preloader">
           <img src=" ../../../../../../assets/img/loading.gif" />
        </div>
      
      </ng-template>