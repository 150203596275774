<div class="Fresult" (scroll)="onWindowScroll($event);">
    <div class="my-headder">
        <section>
            <header></header>
            <app-big-headder class="animated fadeInDown" id="navbar"></app-big-headder>
        </section>

        <div class="my-headder-main">
            <div class="container">

                <div class="page-header">
                    <div class="row" style="padding-top: 50px;">
                        <div class="col-md-12">
                            <app-research></app-research>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <ng-container *ngIf="loading">
        <ng-container *ngTemplateOutlet="flightloading"></ng-container>
    </ng-container>
    <ng-container *ngIf="ResultFound">
        <!-- <ng-container *ngTemplateOutlet="flightloading"></ng-container> -->
        <ng-container *ngTemplateOutlet="flightContent"></ng-container>
    </ng-container>
    <app-normalerror [normalError]='normalError' *ngIf="normalErrorStatus"></app-normalerror>
    <app-footer></app-footer>
</div>





<ng-template #flightContent>
    <div class="container">

        <!-- page-header -->

        <!-- page-header -->
        <!-- filterSideNav -->
        <div class="FResultsContainer">
            <div class="main-content">
                <mat-sidenav-container class="row " style=" min-height: 1000px">
                    <!-- <div class="row"> -->
                    <!-- <div class="col-12 col-sm-12 col-md-3" > -->
                    <mat-sidenav #sidenav mode="side" opened class="col-12 col-sm-12 col-md-3" [ngClass]="{rtlClose:!sidenav.opened}" style=" border: 1px solid #e8ebf3;
          border-radius: 3px;
          -webkit-box-shadow: 3px 3px 5px 0px rgba(232,235,243,1);
-moz-box-shadow: 3px 3px 5px 0px rgba(232,235,243,1);
box-shadow: 3px 3px 5px 0px rgba(232,235,243,1);
          ">
                        <!-- filter -->
                        <!-- <div class="hotel-filter" [formGroup]="filtrationForm"> -->
                        <div class=" filterCard " [formGroup]="filterForm" style="margin-top:40px">
                            <div class="filter-item priceRange">
                                <h4> {{"SearchResult.Price"|translate}} </h4>
                                <div class="custom-slider" *ngIf="!loading && !panelOpenState[0]">
                                    <ng5-slider formControlName="priceSlider" [options]="options" value="0" highValue="3000">
                                    </ng5-slider>
                                </div>
                            </div>
                            <div class="filter-item priceRange">
                                <h4> {{"SearchResult.Stops"|translate}} </h4>
                                <div class="custom-slider" *ngIf="!loading && !panelOpenState[0]">
                                    <div formGroupName="stopsForm" class="stopsForm">
                                        <div class="row">
                                            <div class="col-12">
                                                <mat-checkbox formControlName="noStops">{{"SearchResult.Direct"|translate}}</mat-checkbox>
                                            </div>
                                            <div class="col-12">
                                                <mat-checkbox formControlName="oneStop">{{"SearchResult.OneStop"|translate}}</mat-checkbox>
                                            </div>
                                            <div class="col-12">
                                                <mat-checkbox formControlName="twoAndm">{{"SearchResult.2+Stop"|translate}}</mat-checkbox>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                            <div class="filter-item priceRange">
                                <h4> {{"SearchResult.DpartingTime"|translate}} </h4>
                                <div class="custom-slider">
                                    <ng-container>
                                        <div class="custom-slider" *ngIf="!loading && !panelOpenState[2]">
                                            <ng5-slider formControlName="dpartingSlider" [options]="optionsdeparting" value="0" highValue="3000">
                                            </ng5-slider>
                                        </div>
                                    </ng-container>

                                </div>
                            </div>
                            <div class="filter-item priceRange">
                                <h4> {{"SearchResult.FlightDuration"|translate}} </h4>
                                <div class="custom-slider">
                                    <ng-container>
                                        <div class="custom-slider" *ngIf="!loading && !panelOpenState[3]">
                                            <ng5-slider formControlName="durationSlider" [options]="optionsDurathion" value="0" highValue="600">
                                            </ng5-slider>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="filter-item priceRange">
                                <h4> {{"SearchResult.Airlines"|translate}} </h4>
                                <ng-container formGroupName="airline">
                                    <ng-container formArrayName='airlines'>
                                        <ng-container *ngFor="let item of filterForm.get('airline').get('airlines').controls;let i= index">
                                            <div class="row pl-3">
                                                <mat-checkbox [formControlName]="i">{{airlinesA[i]}}</mat-checkbox>
                                            </div>

                                        </ng-container>

                                    </ng-container>

                                </ng-container>
                            </div>
                        </div>
                        <!--filter  -->
                    </mat-sidenav>
                    <!-- </div> -->
                    <!-- <div class="col-12 col-sm-12 col-md-9"> -->
                    <mat-sidenav-content class="main" [ngClass]="{rtlAlign: sidenav.opened}" style="background: transparent;">


                        <div class="hotel-content-container">
                            <!-- google map -->

                            <!-- google map -->
                            <!-- hotel list -->
                            <div class="hotel-list-container">
                                <div class="hotel-list">

                                    <div class="sort-header-reg ">
                                        <div class="row">
                                            <div class=" col-12 col-sm-2">
                                                <button mat-fab (click)="sidenav.toggle()" color="accent" class="filter-btn">
                              <i class="fas fa-sliders-h"></i>
                            </button>
                                            </div>
                                            <div class="col-12 col-sm-6 align-self-center ">
                                                <!-- <span class="sort-header-pg" >Page 1 of 11</span> -->
                                                <span class="sort-header-qty">
                          <!-- <span>{{hotels.length}}</span> -->
                                                <span> {{response?.airItineraries.length}} </span> {{"SearchResult.ResultFound"|translate}}
                                                </span>
                                            </div>
                                            <div class="col-12 col-sm-4 ">

                                                <mat-form-field color="accent">


                                                    <mat-select color="accent" appearance="outline" placeholder='{{"SearchResult.selectOption"|translate}}'>
                                                        <mat-option value="1" selected (click)="sortMyResult(1)">{{"SearchResult.Price"|translate}}
                                                        </mat-option>
                                                        <mat-option value="2" (click)="sortMyResult(2)">{{"SearchResult.Duration"|translate}}
                                                        </mat-option>
                                                        <mat-option value="3" (click)="sortMyResult(3)">{{"SearchResult.Departing"|translate}}
                                                        </mat-option>
                                                        <mat-option value="4" (click)="sortMyResult(4)">{{"SearchResult.Arrivaltime"|translate}}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>





                                            </div>
                                        </div>


                                    </div>
                                    <div class="flight-list">
                                        <!-- oneWay -->
                                        <!-- loopinghere  -->
                                        <!-- <virtual-scroller #scroll [items]="orgnizedResponce" [parentScroll]="scroll.window" [ngStyle]="{'height.px': scrollH}"
                    [enableUnequalChildrenSizes]="true"  [bufferAmount]="5"> -->
                                        <!-- <ng-container *ngFor="let item of scroll.viewPortItems;let i= index;"> -->
                                        <ng-container *ngFor="let item of orgnizedResponce;let i= index;">
                                            <app-one-way [item]='item' [i]='i' *ngIf="flightType == 'oneway'"></app-one-way>
                                            <app-roundtrip [item]='item' [i]='i' *ngIf="flightType == 'RoundTrip'"></app-roundtrip>
                                            <app-multicity [item]='item' [i]='i' *ngIf="flightType == 'Multicity'"></app-multicity>
                                        </ng-container>
                                        <!-- </virtual-scroller> -->




                                        <!-- loopinghere  -->
                                        <!-- one way -->


                                    </div>
                                </div>
                            </div>
                            <!-- hotel list -->


                        </div>


                    </mat-sidenav-content>

                    <!-- </div> -->
                    <!-- </div> -->
                </mat-sidenav-container>
            </div>
        </div>


        <!-- filterSideNav -->

    </div>

</ng-template>

<ng-template #flightloading>
    <div class="container">


        <!-- filterSideNav -->
        <div class="FResultsContainer">
            <div class="main-content">
                <mat-sidenav-container class="row " style=" min-height: 500px">
                    <!-- <div class="row"> -->
                    <!-- <div class="col-12 col-sm-12 col-md-3" > -->
                    <mat-sidenav #sidenav mode="side" opened class="col-12 col-sm-12 col-md-3" [ngClass]="{rtlClose:!sidenav.opened}">
                        <!-- filter -->
                        <!-- <div class="hotel-filter" [formGroup]="filtrationForm"> -->
                        <div class="flight-filter" style="margin-top: 10px; background: #fff;
                padding: 10px;">
                            <!-- sildetoggle map -->


                            <div class="filter-item flightstops">
                                <h4> {{"SearchResult.Price"|translate}} </h4>

                                <!-- <ul class="stars-filter" formGroupName="rate"> -->
                                <div class="insidContainer four text-center" style="min-height: 73.4px">
                                    <div class="lds-facebook" style="top: 0.3rem; height: 41px;width: 50px;">
                                        <div></div>
                                        <div></div>
                                        <div></div>

                                    </div>
                                </div>
                            </div>
                            <!-- stops class -->
                            <div class="filter-item flightstops">
                                <h4> {{"SearchResult.Stops"|translate}} </h4>

                                <!-- <ul class="stars-filter" formGroupName="rate"> -->
                                <div class="insidContainer four text-center" style="min-height: 73.4px">
                                    <div class="lds-facebook" style="top: 0.3rem; height: 41px;width: 50px;">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                </div>
                            </div>
                            <!-- stops class -->
                            <!-- DpartingTime range -->

                            <div class="priceRange filter-item ">
                                <h4> {{"SearchResult.DpartingTime"|translate}} </h4>
                                <div class="insidContainer four text-center" style="min-height: 73.4px">
                                    <div class="lds-facebook" style="top: 0.3rem; height: 41px;width: 50px;">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                </div>
                            </div>
                            <!-- DpartingTime range -->
                            <!-- DpartingTime range -->

                            <div class="priceRange filter-item ">
                                <h4> {{"SearchResult.FlightDuration"|translate}} </h4>
                                <div class="insidContainer four text-center" style="min-height: 73.4px">
                                    <div class="lds-facebook" style="top: 0.3rem; height: 41px;width: 50px;">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                </div>
                            </div>
                            <!-- DpartingTime range -->

                            <!--	Neighborhoods range -->
                            <div class="Neighbor filter-item">
                                <h4> {{"SearchResult.Airlines"|translate}} </h4>
                                <div class="insidContainer four text-center" style="min-height: 73.4px">
                                    <div class="lds-facebook" style="top: 0.3rem; height: 41px;width: 50px;">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                </div>
                            </div>
                            <!--	Neighborhoods range -->
                        </div>
                        <!--filter  -->
                    </mat-sidenav>
                    <!-- </div> -->
                    <!-- <div class="col-12 col-sm-12 col-md-9"> -->
                    <mat-sidenav-content class="main" [ngClass]="{rtlAlign: sidenav.opened}" style="background: white;margin-left: 290px !important;">


                        <div class="hotel-content-container">
                            <!-- google map -->

                            <!-- google map -->
                            <!-- hotel list -->
                            <div class="hotel-list-container">
                                <div class="hotel-list">

                                    <div class="sort-header-reg ">
                                        <div class="row">
                                            <div class=" col-12 col-sm-2">
                                                <button mat-fab (click)="sidenav.toggle()" color="accent" class="filter-btn">
                                <i class="fas fa-sliders-h"></i>
                              </button>
                                            </div>
                                            <div class="col-12 col-sm-6 align-self-center ">
                                                <!-- <span class="sort-header-pg" >Page 1 of 11</span> -->
                                                <span class="sort-header-qty">
                          <!-- <span>{{hotels.length}}</span> -->
                                                <span> {{response?.airItineraries.length}} </span> {{"SearchResult.ResultFound"|translate}}
                                                </span>
                                            </div>
                                            <div class="col-12 col-sm-4 ">
                                                <!-- <span class="sort-header-qty">
                                                   176 hotels found
                                                 </span> -->

                                                <mat-form-field color="accent">
                                                    <mat-select #sort color="accent" appearance="outline" placeholder='{{"SearchResult.selectOption"|translate}}' [(ngModel)]="sortvalue" (change)='sortMyResult(sortvalue)'>
                                                        <mat-option value="1" selected (click)="sortMyResult(1)">{{"SearchResult.Price"|translate}}
                                                        </mat-option>
                                                        <mat-option value="2" (click)="sortMyResult(2)">{{"SearchResult.Duration"|translate}}
                                                        </mat-option>
                                                        <mat-option value="3" (click)="sortMyResult(2)">{{"SearchResult.Departing"|translate}}
                                                        </mat-option>
                                                        <mat-option value="4" (click)="sortMyResult(3)">{{"SearchResult.Arrivaltime"|translate}}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>



                                            </div>
                                        </div>


                                    </div>
                                    <div class="flight-list">
                                        <!-- oneWay -->
                                        <!-- loopinghere  -->
                                        <!-- <ng-container   *ngFor="let item of orgnizedResponce;let i= index;"> -->
                                        <ng-container>
                                            <div class="flight-item-container " autofocus>
                                                <!-- flightItem -->
                                                <div class="flight-item" id="container">
                                                    <div class="row one" style="margin: 0">
                                                        <!-- flight-details  -->

                                                    </div>


                                                    <!-- bookbtn+detailsbtn-->

                                                    <!-- bookbtn+detailsbtn-->


                                                    <!-- show flight details -->
                                                    <!-- <div class="row " [id]="'detailsItem'+i" [ngbCollapse]="trueT[i]"> -->

                                                    <!-- show flight details -->
                                                </div>
                                                <!-- flightItem -->
                                                <!-- more flight btn -->

                                                <!-- <ng-container *ngIf="item.length > 1">
                                                        <button type="button" class="btn seeMore" (click)="trueT[item[0].sequenceNum]= !trueT[item[0].sequenceNum]" [attr.aria-expanded]="!trueT[item[0].sequenceNum]"
                                                        [attr.aria-controls]="'showTerm'+item[0].sequenceNum" >  
                                                        <span class="more-btn-txt">
                                                          {{item.length -1}} More <i class="fa fa-chevron-down fa-x"></i>
                                                        </span>                                     
                                                      </button>
                                                
                                                      <div style="border:1px solid">{{item[0].sequenceNum}}</div>
                                                     </ng-container>                              -->
                                                <!-- more flight btn-->
                                                <!-- more flights card  -->








                                                <!-- more flights card  -->
                                            </div>
                                        </ng-container>

                                        <ng-container>
                                            <div class="flight-item-container ">
                                                <!-- flightItem -->
                                                <div class="flight-item" id="container">
                                                    <div class="row one" style="margin: 0">
                                                        <!-- flight-details  -->

                                                    </div>


                                                    <!-- bookbtn+detailsbtn-->

                                                    <!-- bookbtn+detailsbtn-->


                                                    <!-- show flight details -->
                                                    <!-- <div class="row " [id]="'detailsItem'+i" [ngbCollapse]="trueT[i]"> -->

                                                    <!-- show flight details -->
                                                </div>
                                                <!-- flightItem -->
                                                <!-- more flight btn -->

                                                <!-- <ng-container *ngIf="item.length > 1">
                                                    <button type="button" class="btn seeMore" (click)="trueT[item[0].sequenceNum]= !trueT[item[0].sequenceNum]" [attr.aria-expanded]="!trueT[item[0].sequenceNum]"
                                                    [attr.aria-controls]="'showTerm'+item[0].sequenceNum" >  
                                                    <span class="more-btn-txt">
                                                      {{item.length -1}} More <i class="fa fa-chevron-down fa-x"></i>
                                                    </span>                                     
                                                  </button>
                                            
                                                  <div style="border:1px solid">{{item[0].sequenceNum}}</div>
                                                 </ng-container>                              -->
                                                <!-- more flight btn-->
                                                <!-- more flights card  -->








                                                <!-- more flights card  -->
                                            </div>
                                        </ng-container>

                                        <ng-container>
                                            <div class="flight-item-container ">
                                                <!-- flightItem -->
                                                <div class="flight-item" id="container">
                                                    <div class="row one" style="margin: 0">
                                                        <!-- flight-details  -->

                                                    </div>


                                                    <!-- bookbtn+detailsbtn-->

                                                    <!-- bookbtn+detailsbtn-->


                                                    <!-- show flight details -->
                                                    <!-- <div class="row " [id]="'detailsItem'+i" [ngbCollapse]="trueT[i]"> -->

                                                    <!-- show flight details -->
                                                </div>
                                                <!-- flightItem -->
                                                <!-- more flight btn -->

                                                <!-- <ng-container *ngIf="item.length > 1">
                                                      <button type="button" class="btn seeMore" (click)="trueT[item[0].sequenceNum]= !trueT[item[0].sequenceNum]" [attr.aria-expanded]="!trueT[item[0].sequenceNum]"
                                                      [attr.aria-controls]="'showTerm'+item[0].sequenceNum" >  
                                                      <span class="more-btn-txt">
                                                        {{item.length -1}} More <i class="fa fa-chevron-down fa-x"></i>
                                                      </span>                                     
                                                    </button>
                                              
                                                    <div style="border:1px solid">{{item[0].sequenceNum}}</div>
                                                   </ng-container>                              -->
                                                <!-- more flight btn-->
                                                <!-- more flights card  -->








                                                <!-- more flights card  -->
                                            </div>
                                        </ng-container>



                                        <!-- loopinghere  -->
                                        <!-- one way -->


                                    </div>
                                </div>
                            </div>
                            <!-- hotel list -->


                        </div>


                    </mat-sidenav-content>

                    <!-- </div> -->
                    <!-- </div> -->
                </mat-sidenav-container>
            </div>
        </div>


        <!-- filterSideNav -->

    </div>

</ng-template>


<ng-template #loadingkst>
    <div class="preloader">
        <div class="sk-circle">
            <div class="sk-circle1 sk-child"></div>
            <div class="sk-circle2 sk-child"></div>
            <div class="sk-circle3 sk-child"></div>
            <div class="sk-circle4 sk-child"></div>
            <div class="sk-circle5 sk-child"></div>
            <div class="sk-circle6 sk-child"></div>
            <div class="sk-circle7 sk-child"></div>
            <div class="sk-circle8 sk-child"></div>
            <div class="sk-circle9 sk-child"></div>
            <div class="sk-circle10 sk-child"></div>
            <div class="sk-circle11 sk-child"></div>
            <div class="sk-circle12 sk-child"></div>
        </div>
    </div>

</ng-template>