import { FarRulesComponent } from './../far-rules/far-rules.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, Input } from '@angular/core';
import { FlightServiceService } from '../../../Services/flight-service.service';
import { MyApiService } from '../../../Services/my-api.service';
import { FareRules } from '../../../interface/fare-rules';
declare var $: any;


@Component({
  selector: 'app-roundtrip',
  templateUrl: './roundtrip.component.html',
  styleUrls: ['../flight-result.component.css', './roundtrip.component.css', '../loader.css']
})
export class RoundtripComponent implements OnInit {
  @Input() item: any[] = [];
  @Input() i: number = 0;
  @Input() over: boolean = false;
  FareLoading: boolean = true;
  fare: FareRules[];
  trueT: boolean[] = [];

  moreT: boolean = true;
  constructor(private flight: FlightServiceService, private search: MyApiService, private modalService: NgbModal) { }

  ngOnInit() {
    this.trueT = this.flight.valuesoftrue(this.item);
    console.log(this.item, this.item.length);
    console.log(this.i);

  }
  showFareRules(squencNumber: number, pKey: string) {

    console.log("called")
    this.FareLoading = true;
    this.search.fareRules(this.flight.serachId, squencNumber, pKey).subscribe(
      (result) => {
        this.FareLoading = false;
        this.fare = result;
        console.log("fareslength", this.fare);
      }

    );
  }


  togglemodel(index: number, sequenceNum: number, pKey: string) {
    let id = '#fareModal' + index;
    $(id).modal('toggle');
    this.showFareRules(sequenceNum, pKey);
  }

  togglemodelt(i: number, m: number, sequenceNum: number, pKey: string) {
    let id = '#fareModal' + i + 'l' + m;
    $(id).modal('toggle');
    this.showFareRules(sequenceNum, pKey);
  }


  open(sequenceNum: number, pKey: string) {

    const modalRef = this.modalService.open(FarRulesComponent);
    modalRef.componentInstance.serachId = this.flight.serachId;
    modalRef.componentInstance.squencNumber = sequenceNum;
    modalRef.componentInstance.providerKey = pKey;



  }

}

