<!-- start hotelSearch -->
<div class="container-fluid Search">
  <form (ngSubmit)="onSubmit()" [formGroup]="hotelSearch" class="SearchForm">
    <div class="formInputs">
      <div class="row hotelloc">
        <!-- location -->
        <mat-form-field class="example-full-width col-12 col-sm-6 col-lg-3 " appearance="outline" color="main"
          id="location" color='accent' >
          <div class="locationInput">
            <input type="text" placeholder='{{"hotelSearch.location"|translate}}' aria-label="Number" matInput
            formControlName="location" [matAutocomplete]="auto" #loca>

            <div class="loader" *ngIf="cityLoader"></div>
          </div>
          
          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">

            <mat-option *ngFor="let item of arrhotelcities|hotelecites:loca.value|limitTo:5" [value]="item">
              <i class="fas fa-map-marker-alt" style="color:#779b3d;padding:0 5px;"></i>
              <span [innerHTML]="item.City| highlighter:loca.value"></span>,
              <span [innerHTML]="item.Country| highlighter:loca.value"></span>
            </mat-option>
          </mat-autocomplete> 
        </mat-form-field>
        <!-- location -->
        <!-- Nationality -->
        <mat-form-field class="example-full-width col-12 col-sm-6 col-lg-2" appearance="outline" color="main"
          id="nation" color='accent' >
          <div class="locationInput">
            <input type="text" [placeholder]="translate.currentLang == 'en'? 'Nationality': 'الجنسيه'" aria-label="Number" matInput
            formControlName="nation" [matAutocomplete]="autoNation" #nationVal>

            <div class="loader" *ngIf="nationLoader"></div>
          </div>
          
          <mat-autocomplete #autoNation="matAutocomplete">
            <mat-option *ngFor="let item of arrhotelnations| councode:nationVal.value|limitTo:5" [value]="item.countryName">
              <i class="fas fa-map-marker-alt" style="color:#779b3d;padding:0 5px;"></i>
              <span [innerHTML]="item.countryName| highlighter:nationVal.value">{{ item.countryName }}</span>
            </mat-option>
          </mat-autocomplete> 
        </mat-form-field>

        <!-- checkIn + checkOut-->
        <mat-form-field class="example-full-width col-12 col-sm-6 col-lg-2" appearance="outline" color='accent'placement="top"
          (click)="pickerin.open()">
  
          <input matInput [matDatepicker]="pickerin" placeholder="{{'hotelSearch.checkIn' |translate}}" [value]="formday" formControlName='checkIn'
            [min]="todayDate()" (dateChange)="setMinDate($event)" readonly>

          <mat-datepicker-toggle matSuffix [for]="pickerin"></mat-datepicker-toggle>
          <mat-datepicker #pickerin disabled="false"></mat-datepicker>
        </mat-form-field>


        <mat-form-field class="example-full-width col-12 col-sm-6 col-lg-2" appearance="outline" color='accent' placement="top"
        (click)="pickerout.open()">

          <input matInput [matDatepicker]="pickerout" placeholder="{{'hotelSearch.checkOut' |translate}}"yy formControlName='checkOut' [value]="today"
            [min]="hotelSearch.get('checkIn').value "  readonly>

          <mat-datepicker-toggle matSuffix [for]="pickerout"></mat-datepicker-toggle>
          <mat-datepicker #pickerout disabled="false"></mat-datepicker>
        </mat-form-field>
        <!-- checkIn + checkOut-->

        <!-- rooomNo+ adult-child-age -->
        <div class="example-full-width col-12 col-sm-6 col-lg-3 align-self-center">
          <div ngbDropdown class="d-inline-block menue">
            <div ngbDropdown class="d-inline-block" autoClose="outside">
              <button class="btn  mb-3 dropbtn " id="dropdownForm1" mat-stroked-button ngbDropdownToggle type="button">
                {{hotelSearch.get('roomN').value}}
                <span *ngIf="hotelSearch.get('roomN').value > 1">
                  {{"hotelSearch.rooms"|translate}}</span>
                <span *ngIf="hotelSearch.get('roomN').value === 1"> {{"hotelSearch.room"|translate}} </span>
                <span> | {{allGuest}} {{"hotelSearch.guestNo" |translate}}
                </span>
              </button>
              <div ngbDropdownMenu aria-labelledby="dropdownForm1" class="guest-dropDown">
                <!-- ROOM GUEST -->
                <div class="row roomrow " style="margin:0">
                  <div class="col-12 col-sm-4 roomT">
                    <h4>{{"hotelSearch.rooms"|translate}} </h4>
                  </div>
                  <div class="row" style="display: none">
                    <mat-form-field class="col-2" appearance="outline" color="accent" placeholder="Rooms">
                      <mat-label>{{"hotelSearch.roomNo"|translate}}</mat-label>
                      <select #roomNum matNativeControl formControlName="roomN" placeholder="Rooms">
                        <option *ngFor="let num of RoomsA|slice:0:5" [value]="num">
                          {{num}}
                        </option>
                      </select>
                    </mat-form-field>
                  </div>
                  <div class="col-12 col-sm-8 roomBtn">
                    <button type="button" mat-fab color="accent" class="add-btn hidePlus"
                      [ngClass]="{showPlus: roomNum.value < 5}" (click)="addRome()">
                      <img src="../../../assets/img/plus-solid.svg" alt=""></button>
              
                    <button type="button" mat-fab color="accent" class="add-btn hideMinus"
                      [ngClass]="{showMinus: roomNum.value > 1}" (click)="removeRome()">
                      <img src="../../../assets/img/minus-solid.svg" alt=""></button>
                  </div>
                </div>

                <!--adult-child-age  -->

                <!-- formArrayName="guestInfo" -->
                <div class="guestRow  align-self-center" formArrayName="guestInfo">

                  <ng-container *ngFor="let guestN of hotelSearch.get('guestInfo')['controls'] ;let i=index; ">
                    <!--[formGroupName]="i"  -->
                    <div class="row guest" [formGroupName]="i">

                      <div class="col-12 col-sm-12  col-md-4 align-self-center">
                        <h4 class="roomNo">
                          {{"hotelSearch.roomNo"|translate}}
                          {{i+1}}
                        </h4>

                      </div>

                      <mat-form-field class="col-12 col-sm-12 col-md-4 p-0 " appearance="outline" color="accent">
                        <mat-label>{{"hotelSearch.adults"|translate}}</mat-label>
                        <!-- formControlName="adultN" -->
                        <select #adultNum matNativeControl formControlName="adultN"
                          placeholder='{{"hotelSearch.adults"|translate}}'>
                          <option *ngFor="let num of RoomsA|slice:0:5" [value]="num">
                            {{num}}
                          </option>
                        </select>
                      </mat-form-field>

                      <mat-form-field class="col-12 col-sm-12 col-md-4 p-0 pl-1" appearance="outline" color="accent">
                        <mat-label>{{"hotelSearch.Children"|translate}}</mat-label>
                        <!-- formControlName="childN" -->
                        <select matNativeControl #ChildNum formControlName="childN"
                          placeholder='{{"hotelSearch.Children"|translate}}' (change)="addChildAge(guestN)">
                          <option *ngFor="let num of childrenA" [value]="num">
                            {{num}}
                          </option>
                        </select>
                      </mat-form-field>



                      <div class="col-12 p-0">
                        <!-- formArrayName="childGroup" -->
                        <div class="row" style="margin: 10px 0;" formArrayName="childGroup">
                          <div class="col-12 col-sm-12 col-md-4"></div>

                          <div class="col-6 col-sm-6 col-md-4  p-0 formGroup"
                            *ngFor="let childAge of guestN.get('childGroup')['controls'] ;let c=index; "
                            [ngClass]="{'pl-1': c==1 }">
                            <!-- [formGroupName]="c" -->
                            <ng-container [formGroupName]="c">
                              <mat-form-field appearance="outline" color="accent" style="width: 100%" >
                                <mat-label>{{"hotelSearch.age"|translate}}</mat-label>
                                <!-- formControlName="age" -->
                                <select matNativeControl formControlName="age"
                                  placeholder='{{"hotelSearch.age"|translate}}'>
                                  <option *ngFor="let Cage of RoomsA|slice:1" [value]="Cage">
                                    {{Cage}}
                                  </option>
                                </select>
                              </mat-form-field>
                            </ng-container>

                            <p *ngIf="childAge.get('age').value < 2   && childAge.get('age').touched ">
                              <ngb-alert type="warning" [dismissible]="false"> minimum child age is 2 </ngb-alert>
                            </p>
                            <p *ngIf="childAge.get('age').value > 11   && childAge.get('age').touched ">
                              <ngb-alert type="warning" [dismissible]="false"> maximum child age is 11 </ngb-alert>
                            </p>
                          </div>

                        </div>
                      </div>
                    </div>


                  </ng-container>




                </div>


                <!--adult-child-age  -->




                <!--ROOM GUEST  -->

                <button ngbDropdownToggle class="btn dropdown-toggle "color='accent' appearance="outline"  mat-raised-button
                  type="button">{{"searchFlight.done"|translate}} </button>
              </div>
            </div>
            <!-- </div> -->
          </div>
        </div>
        <!-- rooomNo+ adult-child-age -->
      </div>
    </div>
    <!-- submit Button -->
    <div class="row justify-content-center">
      <div class="example-full-width col-12 col-md-4 align-self-center ">
        <button class=" btn-lg btn  searchbutton " color='accent' appearance="outline"  mat-raised-button type="submit"
          routerLinkActive="router-link-active">
          {{"hotelSearch.search"|translate}}
        </button>
      </div>
    </div>
  </form>
</div>

<ng-container *ngIf="hotelSearch.touched">
  <ng-container *ngTemplateOutlet="alerts">

  </ng-container>
</ng-container>





<!-- validation alert start -->
<ng-template #alerts>
  <p *ngIf="!hotelSearch.get('checkOut').valid && hotelSearch.get('checkOut').touched ">
    <ngb-alert type="warning" [dismissible]="false"> {{"hotelSearch.checoutAlert" |translate}} </ngb-alert>
  </p>

  <ng-container *ngIf="!hotelSearch.get('guestInfo').valid">
    <p>
      <ngb-alert type="warning" [dismissible]="false">{{"hotelSearch.GuestAlert" |translate}} </ngb-alert>
    </p>
  </ng-container>

  <p *ngIf="!hotelSearch.get('nation').valid && hotelSearch.get('nation').touched">
    <ngb-alert type="warning" [dismissible]="false">{{"hotelSearch.nationAlert" |translate}}</ngb-alert>
  </p>
  <p *ngIf="!hotelSearch.get('location').valid && (hotelSearch.get('location').dirty || hotelSearch.get('location').touched)">
    <ngb-alert type="warning" [dismissible]="false">{{"hotelSearch.locationAlert" |translate}}</ngb-alert>
  </p>
  <p *ngIf="hotelSearch.get('location').valid && cityInputValidation">
    <ngb-alert type="warning" [dismissible]="false">{{"hotelSearch.locationAlert" |translate}}</ngb-alert>
  </p>

</ng-template>
<!-- validation alert end -->
<!-- calender -->


<!-- end  hotelSearch -->