<div class="container" [formGroup]="users">
  <div *ngIf="memberNumber === 0">
    <h4> Add family members to your acount <button mat-button color="accent"(click)="addmember(i)"><i class="fas fa-plus"></i></button></h4>
    
  </div>
  
  <ng-container formArrayName="usersarray">
    <ng-container *ngFor="let user of users.get('usersarray')['controls'];let i = index;">
      <ng-container [formGroupName]="i">
        <ng-container *ngIf="edit[i]">
            <div class="buttond-cont"> 
                <button mat-button color="accent"(click)="save(i)"><i class="fas fa-save"></i></button>
            </div>
              
          <div class="row form-card">
            <div class="col-12 col-md-6 col-lg-3">
              <mat-form-field color="accent" appearance="outline" class="col padding0 ">
                <mat-select formControlName='Salutations' placeholder="Title" #title>

                  <mat-option *ngFor="let title of titels" [value]="title.value">
                    {{title.value}}
                  </mat-option>

                </mat-select>
              </mat-form-field>

            </div>
            <div class="col-12 col-md-6 col-lg-4">
              <mat-form-field color="accent" appearance="outline" class="col padding0 ">
                <input matInput placeholder="First Name" required formControlName='First_Name' type="text"
                  >
              </mat-form-field>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
              <mat-form-field color="accent" appearance="outline" class="col padding0 ">
                <input matInput placeholder="LastName" required formControlName='Last_Name' type="text">
              </mat-form-field>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
                <mat-form-field color="accent" appearance="outline" class="col padding0 ">
                    <input matInput placeholder="phone" required formControlName='Phone' type="text">
                </mat-form-field>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
              <mat-form-field class="col padding0  datepicker " color='accent' appearance="outline">
                <input matInput [matDatepicker]="ii" placeholder='Birth Date' 
                  formControlName='Date_Of_Birth' >
                <mat-datepicker-toggle matSuffix [for]="ii"></mat-datepicker-toggle>
                <mat-datepicker #ii></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-12 col-md-6 col-lg-3">
                <mat-form-field class="col padding0 " color='accent' appearance="outline">
                  <input type="text" placeholder='Country Of Residence' aria-label="country" matInput
                    formControlName="Nationality" [matAutocomplete]="iii" #iiii >
                  <mat-autocomplete #iii="matAutocomplete">
                    <mat-option *ngFor="let country of countries|councode:iiii.value" [value]="country.countryName">
                      {{country.countryName}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
          </div>
          
        </ng-container>
        <ng-container *ngIf="!edit[i]">
          <div class="buttond-cont"> 
              <button mat-button color="accent"(click)="editmemer(i)"><i class="fas fa-edit"></i></button>
              <button mat-button color="accent" (click)="deletemember(user)"><i class="fas fa-user-times"></i></button>
              <button mat-button color="accent"(click)="addmember(i)"><i class="fas fa-plus"></i></button>
          </div>
            
          <div class="row form-card">
              
            <div class="col-12 col-md-6 col-lg-3">
              
                <mat-form-field color="accent" appearance="outline" class="col padding0 ">
                    <input matInput placeholder="title" required formControlName='Salutations' type="text"
                      readonly>
                  </mat-form-field>
           

            </div>
             <div class="col-12 col-md-6 col-lg-4">
                <mat-form-field color="accent" appearance="outline" class="col padding0 ">
                    <input matInput placeholder="First Name" required formControlName='First_Name' type="text"
                      readonly>
                  </mat-form-field>
             </div>
            <div class="col-12 col-md-6 col-lg-4">
              <mat-form-field color="accent" appearance="outline" class="col padding0 ">
                <input matInput placeholder="LastName" required formControlName='Last_Name' type="text"
                  readonly>
              </mat-form-field>
            </div>
            <div class="col-12  col-md-6 col-lg-4">
                <mat-form-field class="col padding0  datepicker " color='accent' appearance="outline">
                    <input matInput [matDatepicker]="ii" placeholder='Birth Date' 
                      formControlName='Date_Of_Birth' readonly>
                    <mat-datepicker-toggle matSuffix [for]="ii" disabled></mat-datepicker-toggle>
                    <mat-datepicker #ii disabled></mat-datepicker>
                </mat-form-field>
              <!-- <mat-form-field class="col padding0  datepicker " color='accent' appearance="outline">
                <input matInput placeholder='Birth Date' type="date"
                  formControlName='Date_Of_Birth' readonly>  
              </mat-form-field> -->
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-4">
                <mat-form-field class="col padding0  datepicker " color='accent' appearance="outline">
                    <input matInput placeholder='phone' 
                      formControlName='Phone' readonly>  
                </mat-form-field>
            </div>
            <div class="col-12 col-sm-6  col-md-6 col-lg-3">
                <mat-form-field class="col padding0 " color='accent' appearance="outline">
                  <input type="text" placeholder='Country Of Residence' aria-label="country" matInput
                    formControlName="Nationality"  readonly>
                 
                </mat-form-field>
            </div>
          </div>


        </ng-container>


      </ng-container>

    </ng-container>
  </ng-container>
</div>