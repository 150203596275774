import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MyApiService } from 'src/app/Services/my-api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
  selector: 'app-edit-acount',
  templateUrl: './edit-acount.component.html',
  styleUrls: ['./edit-acount.component.css']
})
export class EditAcountComponent implements OnInit {
  editForm:FormGroup;
  constructor(private myapi: MyApiService, private _snackBar: MatSnackBar, private Router: Router) { }
  @Input()name ='';
  @Input()email = '';
  @Input()Phone = '';
  preloader:boolean = true;
  ngOnInit() {
    this.preloader = false;
    this.editForm = new FormGroup({
    'Isbase':new FormControl(1),
    'Email': new FormControl(this.email,[Validators.email,Validators.minLength(8),Validators.required]),
    'Password': new FormControl('',[Validators.required,Validators.minLength(8)]),
    'FirstName': new FormControl(''),
    'LastName': new FormControl(''),
    'ImageURL': new FormControl(''),
    'PhoneNumber': new FormControl(this.Phone,[Validators.required,Validators.minLength(5)]),
    'User_Name': new FormControl (this.name)
    })
  }
 onSubmit(){
   this.myapi.userupdate(this.editForm.value).subscribe(
     (result)=>{
       this.openSnackBar('edit succesfully',result,'succes');
       console.log(result);
    }
   )
   this.preloader=true;
 }
 openSnackBar(message: string, action: string,Staus:string) {
  this._snackBar.open(message, action, {
    duration: 2000,
    panelClass:[Staus]
  });
}
}
