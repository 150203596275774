


<div class="checkOutPage">
  <header [disable]='disabled'></header>
  <app-big-headder class="animated fadeInDown" id="navbar"></app-big-headder>
  <div class="checkout-title"> 
      <h1 class="Title">
       <span> {{"SearchResult.adventure"|translate}}</span>
      </h1>
 </div>
  <div class="checkout-content"  >
      <ng-container *ngTemplateOutlet="checkoutTemplate" ></ng-container>

      <app-payment-erorr [paymentError]="paymentError" *ngIf="paymentErrorStatus" style="background: #fff;
      border-radius: 10px;
      box-shadow: 0 1px 6px -1px rgba(37,75,152,0.45);
      margin-bottom: 30px;
      min-height: 400px;
      padding: 5px;"></app-payment-erorr>
      <app-normalerror [normalError]='normalError' *ngIf="normalErrorStatus"></app-normalerror>
  </div>

</div>

<app-footer></app-footer>


<ng-template #checkoutTemplate>

<div class="container">

  <div class="row">
    <div class="col">
      <mat-horizontal-stepper linear #stepper>
       <!-- FlightReview -->
        <mat-step>
          <ng-template matStepLabel>
            <span class="Stepperlable">{{"checkout.FlightReview"|translate}} </span>
          </ng-template>
          <ng-container *ngIf="flight && !sessionex" >
            <ng-container *ngTemplateOutlet="flightReview"></ng-container>
          </ng-container>

          <ng-container *ngIf="!flight">
              <ng-container *ngTemplateOutlet="loadingkst"></ng-container>
           </ng-container>
           <div class="row">
            <div class="col-2 direcition-r">
              <button mat-raised-button  matStepperNext color="primary" class="NextButton">{{"checkout.Next"|translate}}</button>
            </div>
           </div>

          
        </mat-step>
    <!-- FlightReview -->
      <!-- users information -->
      <mat-step [stepControl]='users'>
          <div>
            <ng-template matStepLabel> <span class="Stepperlable">{{"checkout.PassengersInformations"|translate}}</span>
            </ng-template>
          </div>

     
          <div class="row">
            <div class="col">
      
              <h4 class="modelDA" style="text-transform: capitalize;
                  margin-bottom: 14px;
           
                  color: #779b3d;
                  font-weight: 600;">
                {{"checkout.passportxt"|translate}}
              </h4>
            </div>

          </div>
          <div class="row">
            <div class="col-12">
              <ng-container *ngFor="let serv of offline|offlineFilter:'PassangerTop' ; let n = index ">
                <div class="col-12 col-md-6 p-0 mb-2" >
                  <mat-card  class="card-style" dir="ltr">
                    <!-- <mat-card-title> <span class="card-title">{{serv.serviceName}}</span>  <span  class="card-price item-price">{{serv.servicePrice}} <span class="currency">{{serv.currency}}</span></span></mat-card-title> -->
                    <mat-card-title> <mat-checkbox [(ngModel)]="servTrue[offline.indexOf(serv)]" (change)="mapserv(serv,offline.indexOf(serv))" class="serv-check-box" color="primary"><span class="card-title">{{serv.serviceName}}</span></mat-checkbox>   </mat-card-title>
                    <!-- <mat-card-title>{{serv.serviceName}}</mat-card-title> -->
                    <mat-card-subtitle><p [innerHTML]="serv.serviceDescription"></p></mat-card-subtitle>
                    
                    <!-- <div class="actions">
                      <mat-card-actions class="card-action-style">
                       
                      </mat-card-actions>
                    </div> -->
              
                  </mat-card>
                </div>
              </ng-container>
            </div>
          </div>
          <div class="row " style="min-height: 300px; direction: ltr; text-align: left!important">
            
            <form [formGroup]="users" class="col-12 " (ngSubmit)="onSubmit()">
              <ng-container formArrayName="usersarray">
                <ng-container *ngFor="let user of users.get('usersarray')['controls'];let i = index;">
                  <ng-container [formGroupName]="i">
                    <div class="row  fCheckOut">
                      <div class=" col-12 col-md-12  room-form-field">


                        <div class="row">
                          <div class="col">
                            <h4 style="margin-bottom: 20px; color: #779b3d;
                                font-weight: 600;"> {{"checkout.Travellernumber"|translate}} {{i+1}}
                              <small>({{pasengertype(i)}})</small>

                            </h4>
                          </div>

                        </div>
                        <div class="row">
                          <!-- name and titlle -->
                          <div class="col-12 col-md-6 col-lg-3">
                            <mat-form-field color="accent" appearance="outline" class="col padding0 ">
                              <mat-select formControlName='title' placeholder='{{"checkout.title" |translate}}' #title
                                [errorStateMatcher]="matcher">
                                <ng-container *ngIf="pasengertype(i) == 'Adult'">
                                  <mat-option *ngFor="let title of titels" [value]="title.value"  class="toTheleft">
                                    {{title.value}}
                                  </mat-option>
                                </ng-container>
                                <ng-container *ngIf="pasengertype(i) == 'Child'">
                                  <mat-option *ngFor="let title of titelsC" [value]="title.value"  class="toTheleft">
                                    {{title.value}}
                                  </mat-option>
                                </ng-container>
                                <ng-container *ngIf="pasengertype(i) == 'Infant on lap'">
                                  <mat-option *ngFor="let title of titleInf" [value]="title.value"  class="toTheleft">
                                    {{title.value}}
                                  </mat-option>
                                </ng-container>



                              </mat-select>
                            </mat-form-field>
                            <!-- <mat-form-field color="accent" appearance="outline">
                                <mat-label>Title</mat-label>
                                <select class="custom-select titleselect" formControlName='title'>
                                  <option *ngFor="let title of titels" [value]="title.value" class="titleselecto">
                                    {{title.value}}
                                  </option>
                                </select>
                              </mat-form-field> -->
                          </div>
                          <div class="col-12 col-md-6 col-lg-3">
                            <mat-form-field color="accent" appearance="outline" class="col padding0 ">
                              <input matInput placeholder='{{"checkout.firstName" |translate}}' required
                                formControlName='firstName' type="text" [errorStateMatcher]="matcher">
                            </mat-form-field>
                          </div>
                          <!-- <div class="col-12 col-md-6 col-lg-3">
                              <mat-form-field color="accent" appearance="outline" class="col padding0 ">
                                <input matInput placeholder="Middel Name" required formControlName='middleName' type="text">
                              </mat-form-field>
                            </div> -->
                          <div class="col-12 col-md-6 col-lg-3">
                            <mat-form-field color="accent" appearance="outline" class="col padding0 ">
                              <input matInput placeholder='{{"checkout.lastName" |translate}}' required
                                formControlName='lastName' type="text" [errorStateMatcher]="matcher">
                            </mat-form-field>
                          </div>
                          <div class="col-12  col-md-6 col-lg-3">


                            <ng-container *ngIf="pasengertype(i) == 'Adult'">
                              <mat-form-field class="col padding0  datepicker " color='accent' appearance="outline">
                                <input matInput [matDatepicker]="ii" placeholder='{{"checkout.dateOfBirth" |translate}}'
                                  value="{{value|date:'MMMM dd, y'}}" formControlName='dateOfBirth'>
                                <mat-datepicker-toggle matSuffix [for]="ii"></mat-datepicker-toggle>
                                <mat-datepicker #ii [startAt]="startDate"></mat-datepicker>
                              </mat-form-field>
                            </ng-container>
                            <ng-container *ngIf="pasengertype(i) == 'Child'">
                              <mat-form-field class="col padding0  datepicker " color='accent' appearance="outline">
                                <input matInput [matDatepicker]="ii" placeholder='{{"checkout.dateOfBirth" |translate}}'
                                  value="{{value|date:'MMMM dd, y'}}" formControlName='dateOfBirth' [min]=" childmin">
                                <mat-datepicker-toggle matSuffix [for]="ii"></mat-datepicker-toggle>
                                <mat-datepicker #ii [startAt]="startDate"></mat-datepicker>
                              </mat-form-field>
                            </ng-container>
                            <ng-container *ngIf="pasengertype(i) == 'Infant on lap'">
                              <mat-form-field class="col padding0  datepicker " color='accent' appearance="outline">
                                <input matInput [matDatepicker]="ii" placeholder='{{"checkout.dateOfBirth" |translate}}'
                                  value="{{value|date:'MMMM dd, y'}}" formControlName='dateOfBirth' [min]=" infantmin">
                                <mat-datepicker-toggle matSuffix [for]="ii"></mat-datepicker-toggle>
                                <mat-datepicker #ii [startAt]="startDate"></mat-datepicker>
                              </mat-form-field>
                            </ng-container>
                          </div>
                          <!-- email and phone number -->

                        </div>
                        <!-- email and phone number -->
                        <div class="row">
                          <div class="col-12  col-md-6 col-lg-3" *ngIf="i==0">
                            <mat-form-field color="accent" appearance="outline" class="col padding0 ">
                              <input matInput placeholder="Email" required formControlName='email'
                                [errorStateMatcher]="matcher">
                            </mat-form-field>
                          </div>
                          <!-- <div class="col-3">
                              <mat-form-field color="accent" appearance="outline">
                                <input matInput placeholder="Confirm your email" required formControlName='emailconf'> 
                              </mat-form-field>
                            </div> -->
                          <div class="col-12 col-sm-6 col-md-6 col-lg-3 phonecol " (click)="invalidPhone()"
                            *ngIf="i==0">
                            <style *ngIf="!user.get('phonenum').valid && user.get('phonenum').dirty">

                            </style>
                            <ngx-intl-tel-input [cssClass]="phonenumber" [preferredCountries]="['us', 'gb', 'eg', 'sa']"
                              [enableAutoCountrySelect]="true" [enablePlaceholder]="true" name="phone"
                              formControlName='phonenum' (change)="invalidPhone()"></ngx-intl-tel-input>
                          </div>
                          <div class="col-12 col-sm-6  col-md-6 col-lg-3">
                            <mat-form-field class="col padding0 " color='accent' appearance="outline">
                              <input type="text" placeholder='Nationality' aria-label="country" matInput
                                formControlName="national" [matAutocomplete]="iii" #iiii>
                              <mat-autocomplete #iii="matAutocomplete">
                                <mat-option *ngFor="let country of countriescode|councode:iiii.value"
                                  [value]="country.countryName" class="toTheleft">
                                  {{country.countryName}}
                                </mat-option>
                              </mat-autocomplete>
                            </mat-form-field>
                          </div>
                          <div class="col-12  col-md-6 col-lg-3">
                            <mat-form-field class="col padding0 " color='accent' appearance="outline">
                              <input type="text" placeholder='Country of residence' aria-label="country" matInput
                                formControlName="countryofresident" [matAutocomplete]="iiiii" #iiiiii>
                              <mat-autocomplete #iiiii="matAutocomplete">
                                <mat-option *ngFor="let country of countriescode|councode:iiiiii.value"
                                  [value]="country.countryName" class="toTheleft">
                                  {{country.countryName}}
                                </mat-option>
                              </mat-autocomplete>
                            </mat-form-field>
                          </div>
                        </div>
                        <!-- passport informion -->
                        <div class="row" *ngIf="flight.passportDetailsRequired">
                          <!-- passportnumber -->
                          <div class="col-12 col-md-6 col-lg-3">
                            <mat-form-field color="accent" appearance="outline" class="col padding0 ">
                              <input matInput placeholder="Passport number" required formControlName='passportnumber'
                                type="text" [errorStateMatcher]="matcher">
                            </mat-form-field>
                          </div>
                          <!-- exp date -->
                          <div class="col-12 col-md-6 col-lg-3">
                            <mat-form-field class="col padding0  datepicker " color='accent' appearance="outline">
                              <input matInput [matDatepicker]="iiiiiiiii" placeholder='Exp date'
                                value="{{value|date:'MMMM dd, y'}}" formControlName='expdate' [min]=" passportmin">
                              <mat-datepicker-toggle matSuffix [for]="iiiiiiiii"></mat-datepicker-toggle>
                              <mat-datepicker #iiiiiiiii [startAt]="passportmin"></mat-datepicker>
                            </mat-form-field>
                          </div>
                          <!-- issued country -->
                          <div class="col-12 col-md-6 col-lg-3">
                            <mat-form-field class="col padding0 " color='accent' appearance="outline">
                              <input type="text" placeholder='issued country' aria-label="country" matInput
                                formControlName="issuedcountry" [matAutocomplete]="iiiiiii" #iiiiiiii>
                              <mat-autocomplete #iiiiiii="matAutocomplete">
                                <mat-option *ngFor="let country of countriescode|councode:iiiiiiii.value"
                                  [value]="country.countryName">
                                  {{country.countryName}}
                                </mat-option>
                              </mat-autocomplete>
                            </mat-form-field>
                          </div>
                        </div>
                        <!-- <div class="row">
                            
                            <div class="col-3">
                              <mat-form-field color="accent" appearance="outline">
                                <input matInput placeholder="Passport Number" required formControlName='passportnum' maxlength="12">
                              </mat-form-field>
                            </div>
                            
                          </div> -->

                      </div>
                    </div>
                  </ng-container>

                </ng-container>
              </ng-container>
              <div class="row direcition-r">
                <div class="col-4 direcition-r">
                  <button mat-raised-button matStepperPrevious color="accent"
                  class="NextButton m-1">{{"checkout.Back"|translate}}</button>

                <button mat-raised-button matStepperNext color="accent" class="NextButton m-1"
                  type="submit">{{"checkout.Next"|translate}} </button>
              </div>
                </div>
               
            </form>
          </div>
          <div class="row">
            <div class="col-12">
              <ng-container *ngFor="let serv of offline|offlineFilter:'PassangerBottom' ; let n = index ">
                <div class="col-12 col-md-6 p-0 mt-2" >
                  <mat-card  class="card-style" dir="ltr">
                    <!-- <mat-card-title> <span class="card-title">{{serv.serviceName}}</span>  <span  class="card-price item-price">{{serv.servicePrice}} <span class="currency">{{serv.currency}}</span></span></mat-card-title> -->
                    <mat-card-title> <mat-checkbox [(ngModel)]="servTrue[offline.indexOf(serv)]" (change)="mapserv(serv,offline.indexOf(serv))" class="serv-check-box" color="primary"><span class="card-title">{{serv.serviceName}}</span></mat-checkbox>   </mat-card-title>
                    <!-- <mat-card-title>{{serv.serviceName}}</mat-card-title> -->
                    <mat-card-subtitle><p [innerHTML]="serv.serviceDescription"></p></mat-card-subtitle>
                    
                    <!-- <div class="actions">
                      <mat-card-actions class="card-action-style">
                       
                      </mat-card-actions>
                    </div> -->
              
                  </mat-card>
                </div>
              </ng-container>
            </div>
          </div>
        </mat-step>
 <!-- users information -->
   <!-- insuranceStatus -->
        <mat-step *ngIf="insuranceStatus" [completed]='insurraceChoosed'>
          <ng-template matStepLabel> <span class="Stepperlable">{{"checkout.Extras"|translate}} </span> </ng-template>
          <app-insurance [insurance]='insurance' (insuranceUpdate)='insuranceUpdate($event)'></app-insurance>
          <div>
            <button mat-raised-button  matStepperPrevious color="primary"
              class="NextButton">{{"checkout.Back"|translate}}</button>
            <button mat-raised-button  matStepperNext color="primary" class="NextButton" [disabled]="!insurraceChoosed"
              (click)="getPayment()">{{"checkout.Next"|translate}}</button>
          </div>
        </mat-step>
  <!-- insuranceStatus -->
  <!-- Payment -->
        <mat-step>
          <ng-template matStepLabel><span class="Stepperlable">{{"checkout.Payment"|translate}}</span> </ng-template>
          <ng-container *ngIf="iframeLoader">
              <h2 class="title animationLoadingT"> {{"checkout.infoprocess"|translate}} </h2>
              <ng-container *ngTemplateOutlet="loadingkst"></ng-container>
      
          </ng-container>
          <ng-container *ngIf="link != '' ">
            <iframe name="myframe" [src]="mysafeurl" frameborder="0" width="100%" style="min-height:100vh;"
              allowfullscreen='true' (load)='iframeloaded()'>if</iframe>
          </ng-container>
        </mat-step>
  <!-- Payment -->
      </mat-horizontal-stepper>
    </div>
  </div>
</div>

</ng-template>








<!-- user info old -->
<ng-template #userInfo>
<div class="row">
  <div class="col">
    <h4 class="modelDA" style="text-transform: capitalize;
    margin-bottom: 14px;
    color: #779b3d;
    font-weight: 600;">
       {{"checkout.passportxt"|translate}}
    </h4>

  </div>

</div>
<div class="row justify-content-center" style="min-height: 300px; direction: ltr; text-align: left!important">
  <form [formGroup]="users" class="col-12 justify-content-center" (ngSubmit)="onSubmit()">
    <ng-container formArrayName="usersarray">
      <ng-container *ngFor="let user of users.get('usersarray').controls;let i = index;">
        <ng-container [formGroupName]="i">
          <div class="row justify-content-center">
            <div class="FRcard col-12 col-md-10 infocard info room-form-field">


              <div class="row">
                <div class="col">
                  <h4 style="margin-bottom: 20px; color: #779b3d;
                  font-weight: 600;"> {{"checkout.Travellernumber"|translate}} {{i+1}}
                    <small>({{pasengertype(i)}})</small>

                  </h4>
                </div>

              </div>
              <div class="row">
                <!-- name and titlle -->
                <div class="col-12 col-md-6 col-lg-3">
                  <mat-form-field color="primary" appearance="outline" class="col padding0 ">
                    {{title.value}}
                    <mat-select formControlName='title' placeholder="Title" #title>
                      <ng-container *ngIf="pasengertype(i) == 'Adult'">
                        <mat-option *ngFor="let title of titels" [value]="title.value">
                          {{title.value}}
                        </mat-option>
                      </ng-container>
                      <ng-container *ngIf="pasengertype(i) == 'Child'">
                        <mat-option *ngFor="let title of titelsC" [value]="title.value">
                          {{title.value}}
                        </mat-option>
                      </ng-container>
                      <ng-container *ngIf="pasengertype(i) == 'Infant on lap'">
                        <mat-option *ngFor="let title of titleInf" [value]="title.value">
                          {{title.value}}
                        </mat-option>
                      </ng-container>



                    </mat-select>
                  </mat-form-field>
             
                </div>
                <div class="col-12 col-md-6 col-lg-3">
                  <mat-form-field color="primary" appearance="outline" class="col padding0 ">
                    <input matInput placeholder="First Name" required formControlName='firstName' type="text">
                  </mat-form-field>
                </div>

                <div class="col-12 col-md-6 col-lg-3">
                  <mat-form-field color="primary" appearance="outline" class="col padding0 ">
                    <input matInput placeholder="LastName" required formControlName='lastName' type="text">
                  </mat-form-field>
                </div>
                <div class="col-12  col-md-6 col-lg-3">


                  <ng-container *ngIf="pasengertype(i) == 'Adult'">
                    <mat-form-field class="col padding0  datepicker " color='primary' appearance="outline">
                      <input matInput [matDatepicker]="ii" placeholder='Birth Date'
                        value="{{value|date:'MMMM dd, y'}}" formControlName='dateOfBirth'>
                      <mat-datepicker-toggle matSuffix [for]="ii"></mat-datepicker-toggle>
                      <mat-datepicker #ii [startAt]="startDate"></mat-datepicker>
                    </mat-form-field>
                  </ng-container>
                  <ng-container *ngIf="pasengertype(i) == 'Child'">
                    <mat-form-field class="col padding0  datepicker " color='primary' appearance="outline">
                      <input matInput [matDatepicker]="ii" placeholder='Birth Date'
                        value="{{value|date:'MMMM dd, y'}}" formControlName='dateOfBirth' [min]=" childmin">
                      <mat-datepicker-toggle matSuffix [for]="ii"></mat-datepicker-toggle>
                      <mat-datepicker #ii [startAt]="startDate"></mat-datepicker>
                    </mat-form-field>
                  </ng-container>
                  <ng-container *ngIf="pasengertype(i) == 'Infant on lap'">
                    <mat-form-field class="col padding0  datepicker " color='primary' appearance="outline">
                      <input matInput [matDatepicker]="ii" placeholder='Birth Date'
                        value="{{value|date:'MMMM dd, y'}}" formControlName='dateOfBirth' [min]=" infantmin">
                      <mat-datepicker-toggle matSuffix [for]="ii"></mat-datepicker-toggle>
                      <mat-datepicker #ii [startAt]="startDate"></mat-datepicker>
                    </mat-form-field>
                  </ng-container>
                </div>
                <!-- email and phone number -->

              </div>
              <!-- email and phone number -->
              <div *ngIf="i==0" class="row">
                <div class="col-12  col-md-6 col-lg-3">
                  <mat-form-field color="primary" appearance="outline" class="col padding0 ">
                    <input matInput placeholder="Enter your email" required formControlName='email'>
                  </mat-form-field>
                </div>
           
                <div class="col-12 col-sm-6 col-md-6 col-lg-3 phonecol ">
                  <style *ngIf="!user.get('phonenum').valid && user.get('phonenum').dirty">

                  </style>
                  <ngx-intl-tel-input [cssClass]="phonenumber" [preferredCountries]="['us', 'gb', 'eg', 'sa']"
                    [enableAutoCountrySelect]="true" [enablePlaceholder]="true" name="phone"
                    formControlName='phonenum'></ngx-intl-tel-input>
                </div>
                <div class="col-12 col-sm-6  col-md-6 col-lg-3">
                  <mat-form-field class="col padding0 " color='primary' appearance="outline">
                    <input type="text" placeholder='Country Of Residence' aria-label="country" matInput
                      formControlName="national" [matAutocomplete]="iii" #iiii>
                    <mat-autocomplete #iii="matAutocomplete">
                      <mat-option *ngFor="let country of countriescode|councode:iiii.value"
                        [value]="country.countryName">
                        {{country.countryName}}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                </div>
                <div class="col-12  col-md-6 col-lg-3">

                </div>
              </div>
      

            </div>
          </div>
        </ng-container>

      </ng-container>
    </ng-container>
  </form>
</div>
</ng-template>
<!-- user info old -->

<ng-template #content let-modal>
<div class="modal-header modaleT ">
  <h4 class="modal-title modalheadder" id="modal-basic-title">Sorry ;(</h4>
  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">X</span>
  </button>
</div>
<div class="modal-body modalebody">
{{"checkout.Noflight"|translate}}
</div>
<div class="modal-footer modalfooter MoreDetBtn">
  <button type="button" class="btn btn-outline-primary  moreResult " style="max-width:25%;" [routerLink]="['']">
    {{"checkout.newsearch"|translate}}</button>
</div>
</ng-template>







<!-- flightReview  -->

<ng-template #flightReview>
<div class="reviewConainer">
<div class="row">
  <div class="col-12 col-lg-9">
    <div class="row">
      <ng-container *ngFor="let serv of offline|offlineFilter:'ReviewTop' ; let n = index ">
      
        <div class="col-12 col-md-6 p-2" >
          <mat-card  class="card-style" dir="ltr"> 
            <!-- <mat-card-title> <span class="card-title">{{serv.serviceName}}</span>  <span  class="card-price item-price">{{serv.servicePrice}} <span class="currency">{{serv.currency}}</span></span></mat-card-title> -->
            <mat-card-title> <mat-checkbox [(ngModel)]="servTrue[offline.indexOf(serv)]" (change)="mapserv(serv,offline.indexOf(serv.serviceCode))" class="serv-check-box" color="primary"><span class="card-title">{{serv.serviceName}}</span></mat-checkbox>   </mat-card-title>
            <!-- <mat-card-title>{{serv.serviceName}}</mat-card-title> -->
            <mat-card-subtitle><p [innerHTML]="serv.serviceDescription"></p></mat-card-subtitle>
            
            <!-- <div class="actions">
              <mat-card-actions class="card-action-style">
               
              </mat-card-actions>
            </div> -->
      
          </mat-card>
        </div>
      </ng-container>
    </div>
    
      <app-one-way [item]="fligthArr" [i]='0' [over]='true'
      *ngIf="flightType == 'oneway'"></app-one-way>
      <app-roundtrip [item]="fligthArr" [i]='0' [over]='true'
      *ngIf="flightType == 'roundtrip'"></app-roundtrip>
      <app-multicity [item]="fligthArr" [i]='0' [over]='true'
                  *ngIf="flightType != 'oneway' && flightType != 'roundtrip'"></app-multicity>
                  
  </div>
  
  <!-- flight Fresult -->
 <div class="col-12 col-lg-3 mt-3 ">

    <!-- price && taxes -->
    <div class="flightPrice">
        <h5 class="summary-title">{{"checkout.TripCostSummarry"|translate}}</h5> 
        <div class="price-item">
            <span>   {{"checkout.Fare"|translate}} </span>
            <span class="price"> 
               {{ flight['airItineraryDTO']['itinTotalFare']['amount'] - 
                flight['airItineraryDTO']['itinTotalFare']['totalTaxes']|number:'1.2-3'|exchange:'value'}}
                 <b class="currency">   {{flight.airItineraryDTO.itinTotalFare.currencyCode|exchange:'code'}}</b>
            </span>
         

          </div>

          <div class="price-item">
              <span>   {{"checkout.Taxes"|translate}} </span>
              <span class="price"> 
                  {{flight.airItineraryDTO.itinTotalFare.totalTaxes|exchange:'value'}}
                   <b class="currency">  {{flight.airItineraryDTO.itinTotalFare.currencyCode|exchange:'code'}}</b>
              </span>
           

            </div>
            <div class="price-item" *ngIf="oflinseCost">
              <span>   {{"checkout.services"|translate}} </span>
              <span class="price"> 
                {{oflinseCost|exchange:'value'}}
                   <b class="currency">  {{offline[0]['currency']|exchange:'code'}}</b>
              </span>
           

            </div>
            <div class="price-item total-price" *ngIf="!applypromo && applypromo != 0">
                <span>   {{"checkout.Total"|translate}} </span>
                <span class="price"> 
                    {{flight.airItineraryDTO.itinTotalFare.amount + oflinseCost |exchange:'value'|exchange:'value'}}
                     <b class="currency"> {{flight.airItineraryDTO.itinTotalFare.currencyCode|exchange:'code'}}</b>
                </span>
             
                <div *ngIf="!applypromo && applypromo != 0">
                  <button mat-button color="primary" (click)="cobonmodel()"> Add promotion </button>
                </div>
                <div *ngIf="applypromo || applypromo == 0">
                  <button mat-button color="primary" (click)="removecobon()"> {{promoCode}} &times; </button>
                </div>
            </div>
            <div class="price-item total-price" *ngIf="applypromo || applypromo == 0">
              <!-- style 1 -->
              <div class="style-1">
                <div>
                  <del>
                    <span class="amount"
                      *ngIf="oflinseCost == 0">{{flight.airItineraryDTO.itinTotalFare.amount|exchange:'value'}}
                      {{flight.airItineraryDTO.itinTotalFare.currencyCode|exchange:'code'}}</span>
                    <span class="amount"
                      *ngIf="oflinseCost > 0">{{flight.airItineraryDTO.itinTotalFare.amount + oflinseCost |exchange:'value'}}
                      {{flight.airItineraryDTO.itinTotalFare.currencyCode|exchange:'code'}}</span>
                  </del>
                </div>
                <div>
                  <ins>
                    <span class="amount"
                      *ngIf="oflinseCost == 0">{{applypromo|exchange:'value'}}{{flight.airItineraryDTO.itinTotalFare.currencyCode|exchange:'code'}}</span>
                    <span class="amount" *ngIf="oflinseCost > 0">{{applypromo + oflinseCost|exchange:'value'}}
                      {{flight.airItineraryDTO.itinTotalFare.currencyCode|exchange:'code'}}</span>
                  </ins>
                </div>
              </div>
            </div>
    </div>
    <ng-container *ngFor="let serv of offline|offlineFilter:'ReviewBottom' ; let n = index ">
      <div class="col-12 col-md-12 p-0 mt-2" >
        <mat-card  class="card-style" dir="ltr">
          <!-- <mat-card-title> <span class="card-title">{{serv.serviceName}}</span>  <span  class="card-price item-price">{{serv.servicePrice}} <span class="currency">{{serv.currency}}</span></span></mat-card-title> -->
          <mat-card-title> <mat-checkbox [(ngModel)]="servTrue[offline.indexOf(serv)]" (change)="mapserv(serv,offline.indexOf(serv))" class="serv-check-box" color="primary"><span class="card-title">{{serv.serviceName}}</span></mat-checkbox>   </mat-card-title>
          <!-- <mat-card-title>{{serv.serviceName}}</mat-card-title> -->
          <mat-card-subtitle><p [innerHTML]="serv.serviceDescription"></p></mat-card-subtitle>
          
          <!-- <div class="actions">
            <mat-card-actions class="card-action-style">
             
            </mat-card-actions>
          </div> -->
    
        </mat-card>
      </div>
    </ng-container>
    <!-- price && taxes -->
  </div>

</div>












</div>


</ng-template>

<!-- flightReviw -->


<!-- loading -->
<ng-template #loadingkst>
  <div class="preloader" style="margin-top: 100px;">
     <img src=" ../../../../../../assets/img/loading.gif" />
  </div>

</ng-template>
<!-- loading -->

