
    <div class="row card pl-0" (click)="topD.sendTopDestination(flight,hotel)">
        <div class="des-pic col-5 p-0">
            <img [src]="imageSrc" alt="image" class="img-fluid card-img float-left">
        </div>
        <div class="card-content col-7 pl-2 pr-2">
          <div class=" card-title">
             <h4 class="title">{{title}} </h4>
          </div>
          <div class=" card-body p-0 pb-2" >
            <p class="pragraph">
              {{content}}
            </p>
          </div>
        </div>
        </div>


