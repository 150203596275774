<footer>
  <div class="container">
    <div class="top-footer">
      <div class="row justify-content-between ">
          <div class="col-12 col-sm-3">
            <img (click)="gotoHomePage()" src="../../../assets/img/travellii_logo.png" alt="logo" class="logo img-fluid">
          </div>
          <div class="col-12 col-sm-3">
              <a href="https://www.apple.com/eg/ios/app-store/" class="btn btn-store" target="_blank" style="display: none;">
                <img src="../../../assets/img/appStore.svg" alt="appStor" width="200" class="img-fluid">
              </a>
              <a href="https://play.google.com/store?hl=en" class="btn btn-store" target="_blank" style="display: none;">
               <img src="../../../assets/img/googlePlay.svg" alt="googlePlay" width="200" class="img-fluid">
              </a>
          </div>

      </div>

    </div>
    <div class="sec-footer row">
      <div class="col-12 col-md-2">
        <div class="kst-social">
          <ul class="list-unstyled list-inline social ">
            <li class="list-inline-item"><a href="https://www.facebook.com/" target="_blank"><i class="fab fa-facebook-f "></i></a></li>
            <li class="list-inline-item"><a href="https://twitter.com/" target="_blank"><i class="fab fa-twitter "></i></a></li>
            <li class="list-inline-item"><a href="https://instagram.com/" target="_blank"><i class="fab fa-instagram "></i></a></li>
          </ul>

        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="sitemap">

          <ul class="list-unstyled quick-links">
            <li><a   routerLink="/">{{"footer.home"|translate}}</a></li>
            <li><a routerLink="/aboutUs">{{"footer.aboutUs"|translate}}</a></li>
            <li><a routerLink="/Contact">{{"footer.contactUs"|translate}}</a></li>
            <li><a routerLink="/termsOfuse">{{"footer.TOU"|translate}}</a></li>
            <li><a routerLink="/privacyPolicy">{{"footer.PP"|translate}}</a></li>
          </ul>

        </div>
      </div>
      <div class="col-12 col-md-4 " >
        <div class="copyRights">
            <p><i class="far fa-copyright"></i> Travellii 2018</p>
            <img src="../../../assets/img/Iata.svg" alt="iata" class="IATA">
        </div> 
      </div>
    </div>
  </div>



</footer>



<!-- old -->
<ng-template #old>
  <div class="footer-border-top">

  </div>
  <div class="footer-up">
    <div class="container">
      <div class="row">
        <div class="col-12 col-sm-6 col-md-4">
          <div class="about-kst">
            <div class="kst-logo">
              <img src="../../../assets/img/logoen.svg" alt=" kst-logo" />
            </div>
            <div class="about-txt">
              {{"footer.about-txt" |translate}}
            </div>
            <div class="kst-social">
              <ul class="list-unstyled list-inline social ">
                <li class="list-inline-item"><a href="javascript:void();"><i class="fab fa-facebook-f "></i></a></li>
                <li class="list-inline-item"><a href="javascript:void();"><i class="fab fa-twitter "></i></a></li>
                <li class="list-inline-item"><a href="javascript:void();"><i class="fab fa-instagram "></i></a></li>

              </ul>

            </div>
          </div>


        </div>
        <div class="col-12 col-sm-6 col-md-5 offset-md-3">
          <div class="kst-app-download">
            <h4>{{"footer.appDownload" |translate}}</h4>
            <div class="app-btns">
              <a href="#" class="btn btn-store">
                <span class="fab fa-apple fa-3x pull-left"></span>
                <span class="btn-label">{{"footer.Download"|translate}}</span>
                <span class="btn-caption">App Store</span>
              </a>
              <a href="#" class="btn btn-store">
                <span class="fab fa-android fa-3x pull-left"></span>
                <span class="btn-label">{{"footer.Download"|translate}}</span>
                <span class="btn-caption">Google Play</span>
              </a>
            </div>

          </div>

        </div>
      </div>
    </div>

  </div>
  <div class="footer-down">
    <div class="container">
      <div class="row">
        <div class="col-12 col-sm-6">
          <div class="copy-right">
            <p> {{"footer.copyRights"|translate}}
              <span class="app-name"> {{"footer.kst"|translate}} </span> </p>
          </div>
          <div class="copy-right">
            <img src="../../../assets/img/Iata.svg" alt="iata">
          </div>
              
        </div>
        <div class="col-12 col-sm-6 ">
          <div class="sitemap">

            <ul class="list-unstyled quick-links">
              <!-- <li><a href=""><i class="fa fa-angle-double-right"></i>{{"footer.home"|translate}}</a></li>
                  <li><a routerLink="/aboutUs"><i class="fa fa-angle-double-right"></i>{{"footer.aboutUs"|translate}}</a></li>
                  <li><a routerLink="/Contact"><i class="fa fa-angle-double-right"></i>{{"footer.contactUs"|translate}}</a></li>
                  <li><a routerLink="/termsOfuse"><i class="fa fa-angle-double-right"></i>Terms of Use</a></li>
                  <li><a routerLink="/privacyPolicy"><i class="fa fa-angle-double-right"></i>Privacy Policy</a></li> -->
              <li><a href="">{{"footer.home"|translate}}</a></li>
              <li><a routerLink="/aboutUs">{{"footer.aboutUs"|translate}}</a></li>
              <li><a routerLink="/Contact">{{"footer.contactUs"|translate}}</a></li>
              <li><a routerLink="/termsOfuse">{{"footer.TOU"|translate}}</a></li>
              <li><a routerLink="/privacyPolicy">{{"footer.PP"|translate}}</a></li>
            </ul>

          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<!-- old -->