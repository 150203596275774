<ng-container *ngIf="item.length > 0">
  <ng-container *ngTemplateOutlet="flightResult"></ng-container>
</ng-container>





<ng-template #flightResult>

  <div class="flight-item-container">

    <div class="flight-item" >

      <div class="row"   (click)="trueT[0]= !trueT[0]" [attr.aria-expanded]="!trueT[0]"
      [attr.aria-controls]="'detailsItem'+0">
        <!-- flight-details  -->

        <div class=" col-12 col-md-9 pr-0   ">
          <!-- looping on multi flight -->
          <ng-container *ngFor="let flightM  of item[0].allJourney.flights;let f=index">
            <!-- firstrow departure -->
            <div class="row flight-sec">
              <!-- airlineLogo -->
              <div class="col-12 col-md-3 align-self-center p-0">
                <div class="flight-type">
                  <h5> {{"SearchResult.flight"|translate}}
                    <span> {{f+1}} </span>
                  </h5>
                </div>
                <div class="item-airline-logo">
                  <img src="{{flightM.flightDTO[0].flightAirline.airlineLogo}}" alt="airline logo" title="Image Title">
                </div>

              </div>
              <!-- airlineLogo -->
              <!-- departure - landing info  -->
              <div class="col-12 col-md-9  trip-details-box ">
               
                <!-- cities -trip type --->
                <!-- trip details -->
                <div class="trip-details">

                  <!-- departure -arrival- totalDur - stops detailbtn   -->
                  <div class="item-box align-self-center">
                   
                    <div class="date">
                      <h5>
                        <!-- departure  -->
                        {{flightM.flightDTO[0].departureTerminalAirport.cityName}}
                    <sub> ({{flightM.flightDTO[0].departureTerminalAirport.airportCode}}) </sub>
                      </h5>
                      <p class="item-date">
                        <!-- Sun, Mar 22 -->
                        {{flightM.flightDTO[0].departureDate | date:'mediumDate'}}
                        <br>
                        <b>
                          <!-- 10:25 PM -->
                          {{flightM.flightDTO[0].departureDate | date:'shortTime'}}</b>
                      </p>
                    </div>


                  </div>
                  <div class="item-box align-self-center">

                    
                    <div class="date">
                      <h5>
                        <span> <img  class="rtl-arrow mob" src="../../../../assets/img/flightarrow.svg" alt=""> </span>
                        <!-- arrival  -->
                        
                      </h5>

                      <p class="item-date">
                        <!-- non-stop -->
                        {{flightM.elapsedTime| hourminute}}

                        <br>
                        <b>
                          <!-- 22h 50m -->
                          {{flight.direct(flightM.stopsNum)}} </b>
                      </p>
                    </div>


                  </div>
                  <div class="item-box align-self-center">

                    <div class="date">
                      <h5>
                        <!-- arrival  -->
                        {{flight.arrivalCity(item[0],f)}} <sub>({{flight.arrivalAircode(item[0],f)}}) </sub>
                      </h5>
                      <p class="item-date">
                        <!-- Sat, Mar 23 -->
                        {{flight.arrivalDate(item[0],f) | date:'mediumDate'}}
                        <br>
                        <b>
                          <!-- 12:25 PM -->
                          {{flight.arrivalDate(item[0],f) | date:'shortTime'}}</b>
                      </p>
                    </div>


                  </div>
                  <!-- total time and no stops  -->
                 
                  <!-- total time and no stops  -->








                  <!-- departure -arrival- totalDur - stops detailbtn   -->
                </div>
                <!-- trip details -->
              </div>
            </div>
            <!-- firstrow departure -->

            <!-- secRow return -->

            <!-- secRow return -->
          </ng-container>
        </div>
        <!-- flight-details  -->
        <!-- price & booknow & see details &fare rules  -->
        <div class="col-12 col-md-3 align-self-center price-details">
          <span class="item-price">
            {{item[0].itinTotalFare.amount|exchange:'value'}}
            <!-- 3403.4 -->
            <b class="currency">
              {{item[0].itinTotalFare.currencyCode|exchange:'code'}}
              <!-- AED -->
            </b></span>
          <!-- class-passenger-refundable -->
          <div class="item-trip">
            <span>
              <i class="fas fa-redo"></i>
              <b *ngIf="item[0].isRefundable">{{"SearchResult.Refundable"|translate}} </b>
              <b *ngIf="!item[0].isRefundable"> {{"SearchResult.NonRefundable"|translate}} </b>
            </span>
          </div>

     
          <div class="booking-btn">
            <button mat-raised-button color="primary" class="bookingbutton"
            (click)="flight.toCheckout(flight.response.searchCriteria.searchId,item[0].sequenceNum)" *ngIf="!over">{{"SearchResult.book"|translate}}</button>
          </div>

        </div>

        <!-- price & booknow & see details &fare rules  -->



      </div>

      <!-- show details of flight -->

      <div class="row " [id]="'detailsItem'+0" [ngbCollapse]="trueT[0]">
          <div class="col-12 flight-details">
                <ng-container *ngFor="let jer of item[0].allJourney.flights ;let l =index">
                    <div class="row fligt-details-container">
                        <div class=" col-12 col-md-3 fligt-details-pre" style="    align-self: center;
                              padding-left: 30px;">
                          <h6>{{"SearchResult.Departing"|translate}}</h6>
                          <h5>{{item[0].allJourney.flights[l].flightDTO[0].departureDate | date:'EEEE, MMM d'}}</h5>
                          <p><strong>{{item[0].allJourney.flights[l].flightDTO[0].departureTerminalAirport.cityName}} </strong> <img
                              src="../../../../assets/img/arrow1.svg" alt="arrow" class="arrow1"><strong>
                              {{flight.arrivalCity(item[0],l)}}</strong></p>
                          <p class="mb-1">{{item[0].allJourney.flights[l].elapsedTime| hourminute}}</p>
                          <p class="stopnumber">{{flight.direct(item[0].allJourney.flights[l].stopsNum)}}</p>
            
                        </div>
                        <div class=" col-12 col-md-9">
                          <div class="row details-wraber">
                            <div class="col-12 col-md-1 separater-img" style="align-self: center;"><img src="../../../../assets/img/separater.svg"
                                alt=""></div>
                            <div class="col-12 col-md-11">
                              <div class=" row">
                                <ng-container *ngFor="let stop of flight.showStopStation(item[0],l);let s =index ">
            
                                  <div class="col-12 col-md-6 p-0  segment-details">
                                    <h5>{{stop.departureDate | date:'EEEE, MMM d'}}</h5>
                                    <p class="sigment-time" style="margin-right: 18px;">{{stop.departureDate | date:'shortTime'}}
                                    </p> <img src="../../../../assets/img/slash.svg" alt="" class="slash">
                                    <p class="sigment-time" style="margin-left: 18px;"> {{stop.arrivalDate | date:'shortTime'}}</p>
                                    <div>
                                      <p class="SCityName" style="margin-right: 10px;">{{ stop.departureTerminalAirport.cityName }}
                                      </p> 
                                      <img src="../../../../assets/img/long arrow.svg" class="rtl-arrow" alt="">
                                   <span class="mobile-ar"><i class="fas fa-arrow-down"></i></span>
                                      <p style="margin-left: 10px;" class="SCityName">{{stop.arrivalTerminalAirport.cityName}}</p>
                                    </div>
            
            
                                  </div>
                                  <div class="col-12 col-md-6 baggage-operated-by text-left" style="padding-left: 15%;">
                                    <p class="bags-info" *ngIf="item[0].baggageInformation"><i class="fas fa-suitcase-rolling"
                                        style="color:#b9d543; margin: 10px; font-size: 15px;"></i><span>
                                          {{"SearchResult.baggage"|translate}} =   {{ item[0].baggageInformation[s]?.baggage }}
                                      </span>
                                      <ng-container
                                        *ngIf="item[0].baggageInformation[s].childBaggage != '' && item[0].baggageInformation[s].childBaggage">
                                        <br> <i class="fas fa-suitcase-rolling"
                                        style="color: #779b3d; margin: 0 10px; font-size: 15px;"></i> {{"SearchResult.childBags"|translate}} = <span>
                                          {{item[0].baggageInformation[s]?.childBaggage}} </span>
                                      </ng-container>
                                    </p>
                                    <p class="operated-info" *ngIf="stop.operatedAirline.airlineName">
                                        <i class="fas fa-plane "
                                        style="color: #779b3d; margin: 0 10px; font-size: 15px;"></i>
                                      {{"SearchResult.operated"| translate}}<span>
                                        {{stop.operatedAirline?.airlineName}}
                                      </span>
                                    </p>
                                    <p class="cabinClass">
                                      <i class="fab fa-creative-commons" style="color:#b9d543; margin: 10px; font-size: 15px;"></i>
                                      <!-- {{"searchFlight.class"|translate}}: -->
                                      {{item[0]['cabinClass']}}
                                    </p>
                                  </div>
                                  <div class="col-12 col-md-12 p-0" *ngIf="stop.isStopSegment">
                              
                                        <p class="airport-name text-center mt-2 mb-2"> {{stop.arrivalTerminalAirport?.airportName}}
                                          <span class="flight-dur">
                                            <!-- 5h 30 m -->
                                            {{stop.transitTime }}
            
                                          </span>
                                        </p>
            
                            
                                  </div>
            
                                </ng-container>
                              </div>
                            </div>
            
            
                          </div>
            
                        </div>
            
                      </div>
                </ng-container>
           

            <!-- togglemodel(i,item[0].sequenceNum) -->
            <div class="row detais-footer">
              <button mat-button color="accent" data-toggle="modal"
                (click)="open(item[0].sequenceNum , item[0].pKey)">{{"SearchResult.terms"|translate}}</button>
  
  
            </div>
  
  
  
          </div>
      </div>

      <!-- show details of flight -->

    </div>


    <!-- looping on multi flight -->
    <!-- more flight btn -->
    <ng-container *ngIf="item.length > 1">
      <button type="button" class="btn seeMore" (click)="moreT=!moreT" [attr.aria-expanded]="!moreT"
        [attr.aria-controls]="'showTerm'+f">
        <span class="more-btn-txt">
          {{item.length -1}} {{"SearchResult.more"|translate}} <i class="fa fa-chevron-down fa-x"></i>
        </span>
        <span class="more-btn-num">
          {{item.length -1}}
        </span>
      </button>

      <!-- <div style="border:1px solid">{{item[0].sequenceNum}}</div> -->
    </ng-container>
    <!-- more flight btn -->


    <!-- show term  -->

    <ng-container *ngIf="!moreT">
        <div [id]="'showTerm'+f" [ngbCollapse]="moreT" *ngFor="let term of item|slice:1;let m= index;">
            <div class="flight-item" >

                <div class="row"  (click)="trueT[m+1]= !trueT[m+1]" [attr.aria-expanded]="!trueT[m+1]"
                [attr.aria-controls]="'detailsItem'+m+1">
                  <!-- flight-details  -->
          
                  <div class=" col-12 col-md-9 pr-0   ">
                    <!-- looping on multi flight -->
                    <ng-container *ngFor="let flightM  of term.allJourney.flights;let f=index">
                      <!-- firstrow departure -->
                      <div class="row flight-sec">
                        <!-- airlineLogo -->
                        <div class="col-12 col-md-3 align-self-center p-0">
                          <div class="flight-type">
                            <h5> {{"SearchResult.flight"|translate}}
                              <span> {{f+1}} </span>
                            </h5>
                          </div>
                          <div class="item-airline-logo">
                            <img src="{{flightM.flightDTO[0].flightAirline.airlineLogo}}" alt="airline logo" title="Image Title">
                          </div>
          
                        </div>
                        <!-- airlineLogo -->
                        <!-- departure - landing info  -->
                        <div class="col-12 col-md-9  trip-details-box ">
                         
                          <!-- cities -trip type --->
                          <!-- trip details -->
                          <div class="trip-details">
          
                            <!-- departure -arrival- totalDur - stops detailbtn   -->
                            <div class="item-box align-self-center">
                             
                              <div class="date">
                                <h5>
                                  <!-- departure  -->
                                  {{flightM.flightDTO[0].departureTerminalAirport.cityName}}
                              <sub> ({{flightM.flightDTO[0].departureTerminalAirport.airportCode}}) </sub>
                                </h5>
                                <p class="item-date">
                                  <!-- Sun, Mar 22 -->
                                  {{flightM.flightDTO[0].departureDate | date:'mediumDate'}}
                                  <br>
                                  <b>
                                    <!-- 10:25 PM -->
                                    {{flightM.flightDTO[0].departureDate | date:'shortTime'}}</b>
                                </p>
                              </div>
          
          
                            </div>
                            <div class="item-box align-self-center">
          
                              
                              <div class="date">
                                <h5>
                                  <span> <img  class="rtl-arrow mob" src="../../../../assets/img/flightarrow.svg" alt=""> </span>
                                  <!-- arrival  -->
                                  
                                </h5>
          
                                <p class="item-date">
                                  <!-- non-stop -->
                                  {{flightM.elapsedTime| hourminute}}
          
                                  <br>
                                  <b>
                                    <!-- 22h 50m -->
                                    {{flight.direct(flightM.stopsNum)}} </b>
                                </p>
                              </div>
          
          
                            </div>
                            <div class="item-box align-self-center">
          
                              <div class="date">
                                <h5>
                                  <!-- arrival  -->
                                  {{flight.arrivalCity(term,f)}} <sub>({{flight.arrivalAircode(term,f)}}) </sub>
                                </h5>
                                <p class="item-date">
                                  <!-- Sat, Mar 23 -->
                                  {{flight.arrivalDate(term,f) | date:'mediumDate'}}
                                  <br>
                                  <b>
                                    <!-- 12:25 PM -->
                                    {{flight.arrivalDate(term,f) | date:'shortTime'}}</b>
                                </p>
                              </div>
          
          
                            </div>
                            <!-- total time and no stops  -->
                           
                            <!-- total time and no stops  -->
          
          
          
          
          
          
          
          
                            <!-- departure -arrival- totalDur - stops detailbtn   -->
                          </div>
                          <!-- trip details -->
                        </div>
                      </div>
                      <!-- firstrow departure -->
          
                      <!-- secRow return -->
          
                      <!-- secRow return -->
                    </ng-container>
                  </div>
                  <!-- flight-details  -->
                  <!-- price & booknow & see details &fare rules  -->
                  <div class="col-12 col-md-3 align-self-center price-details">
                    <span class="item-price">
                      {{term.itinTotalFare.amount|exchange:'value'}}
                      <!-- 3403.4 -->
                      <b class="currency">
                        {{term.itinTotalFare.currencyCode|exchange:'code'}}
                        <!-- AED -->
                      </b></span>
                    <!-- class-passenger-refundable -->
                    <div class="item-trip">
                      <span>
                        <i class="fas fa-redo"></i>
                        <b *ngIf="term.isRefundable">{{"SearchResult.Refundable"|translate}} </b>
                        <b *ngIf="!term.isRefundable"> {{"SearchResult.NonRefundable"|translate}} </b>
                      </span>
                    </div>
          
               
                    <div class="booking-btn">
                      <button mat-raised-button color="primary" class="bookingbutton"
                      (click)="flight.toCheckout(flight.response.searchCriteria.searchId,term.sequenceNum)" *ngIf="!over">{{"SearchResult.book"|translate}}</button>
                    </div>
          
                  </div>
          
                  <!-- price & booknow & see details &fare rules  -->
          
          
          
                </div>
          
                <!-- show details of flight -->
          
                <div class="row " [id]="'detailsItem'+m" [ngbCollapse]="trueT[m+1]">
                    <div class="col-12 flight-details">
                          <ng-container *ngFor="let jer of term.allJourney.flights ;let l =index">
                              <div class="row fligt-details-container">
                                  <div class=" col-12 col-md-3 fligt-details-pre" style="    align-self: center;
                                        padding-left: 30px;">
                                    <h6>{{"SearchResult.Departing"|translate}}</h6>
                                    <h5>{{term.allJourney.flights[l].flightDTO[0].departureDate | date:'EEEE, MMM d'}}</h5>
                                    <p><strong>{{term.allJourney.flights[l].flightDTO[0].departureTerminalAirport.cityName}} </strong> <img
                                        src="../../../../assets/img/arrow1.svg" alt="arrow" class="arrow1"><strong>
                                        {{flight.arrivalCity(term,l)}}</strong></p>
                                    <p class="mb-1">{{term.allJourney.flights[l].elapsedTime| hourminute}}</p>
                                    <p class="stopnumber">{{flight.direct(term.allJourney.flights[l].stopsNum)}}</p>
                      
                                  </div>
                                  <div class=" col-12 col-md-9">
                                    <div class="row details-wraber">
                                      <div class="col-12 col-md-1 separater-img" style="align-self: center;"><img src="../../../../assets/img/separater.svg"
                                          alt=""></div>
                                      <div class="col-12 col-md-11">
                                        <div class=" row">
                                          <ng-container *ngFor="let stop of flight.showStopStation(term,l);let s =index ">
                      
                                            <div class="col-12 col-md-6 p-0 segment-details">
                                              <h5>{{stop.departureDate | date:'EEEE, MMM d'}}</h5>
                                              <p class="sigment-time" style="margin-right: 18px;">{{stop.departureDate | date:'shortTime'}}
                                              </p> <img src="../../../../assets/img/slash.svg" alt="" class="slash">
                                              <p class="sigment-time" style="margin-left: 18px;"> {{stop.arrivalDate | date:'shortTime'}}</p>
                                              <div>
                                                <p class="SCityName" style="margin-right: 10px;">{{ stop.departureTerminalAirport.cityName }}
                                                </p> 
                                                <img src="../../../../assets/img/long arrow.svg" class="rtl-arrow" alt="">
                                                <span class="mobile-ar"><i class="fas fa-arrow-down"></i></span>
                                                <p style="margin-left: 10px;" class="SCityName">{{stop.arrivalTerminalAirport.cityName}}</p>
                                              </div>
                      
                      
                                            </div>
                                            <div class="col-12 col-md-6 baggage-operated-by text-left" style="padding-left: 15%;">
                                              <p class="bags-info" *ngIf="term.baggageInformation"><i class="fas fa-suitcase-rolling"
                                                  style="color:#b9d543; margin: 10px; font-size: 15px;"></i><span>
                                                    {{"SearchResult.baggage"|translate}} =   {{ term.baggageInformation[s]?.baggage }}
                                                </span>
                                                <ng-container
                                                  *ngIf="term.baggageInformation[s].childBaggage != '' && term.baggageInformation[s].childBaggage">
                                                  <br> <i class="fas fa-suitcase-rolling"
                                                  style="color: #779b3d; margin: 0 10px; font-size: 15px;"></i> {{"SearchResult.childBags"|translate}} = <span>
                                                    {{term.baggageInformation[s]?.childBaggage}} </span>
                                                </ng-container>
                                              </p>
                                              <p class="operated-info" *ngIf="stop.operatedAirline.airlineName">
                                                  <i class="fas fa-plane "
                                                  style="color: #779b3d; margin: 0 10px; font-size: 15px;"></i>
                                                {{"SearchResult.operated"| translate}}<span>
                                                  {{stop.operatedAirline?.airlineName}}
                                                </span>
                                              </p>
                                              <p class="cabinClass">
                                                <i class="fab fa-creative-commons" style="color:#b9d543; margin: 10px; font-size: 15px;"></i>
                                                <!-- {{"searchFlight.class"|translate}}: -->
                                                {{term['cabinClass']}}
                                              </p>
                                            </div>
                                            <div class="col-12 col-md-12 p-0 " *ngIf="stop.isStopSegment">
                                  
                                                  <p class="airport-name text-center mt-2 mb-2"> {{stop.arrivalTerminalAirport?.airportName}}
                                                    <span class="flight-dur">
                                                      <!-- 5h 30 m -->
                                                      {{stop.transitTime }}
                      
                                                    </span>
                                                  </p>
                                            </div>
                      
                                          </ng-container>
                                        </div>
                                      </div>
                      
                      
                                    </div>
                      
                                  </div>
                      
                                </div>
                          </ng-container>
                     
          
                      <!-- togglemodel(i,term.sequenceNum) -->
                      <div class="row detais-footer">
                        <button mat-button color="accent" data-toggle="modal"
                          (click)="open(term.sequenceNum,term.pKey)">{{"SearchResult.terms"|translate}}</button>
            
            
                      </div>
            
            
            
                    </div>
                </div>
          
                <!-- show details of flight -->

                <button type="button" class="btn seeMore lessbtn " (click)="moreT=!moreT" [attr.aria-expanded]="!moreT"
                aria-controls="!moreT" *ngIf="m+2 == item.length">
                <span class="more-btn-txt">
                    {{"SearchResult.less"|translate}}
                  </span>
                  <span class="more-btn-num">
                      <i class="fa fa-chevron-up fa-x"></i>
                    </span>
          
              </button>
          
              </div>


        </div>
    </ng-container>




    <!-- show term  -->

  </div>

</ng-template>


<ng-template #loadingkst>
  <div class="preloader">
    <img src=" ../../../../../../assets/img/loading.gif" />
  </div>

</ng-template>