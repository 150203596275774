import { Component, OnInit, HostListener,OnDestroy, Output } from '@angular/core';
import { trigger, state, style, transition, animate, query, stagger } from '@angular/animations';
export interface offeritem {
   title:string,
   body:string,
   imgur:string,
   flight:string,
   hotel:object
}

@Component({
  selector: 'app-top-destination',
  templateUrl: './top-destination.component.html',
  styleUrls: ['./top-destination.component.css'],
  animations:[
    trigger('fadeInOut', [
      state('void', style({
        opacity: 0
      })),
      transition('void => *',animate(1000) ),
      transition('* => void',animate(500))
    ]),
    trigger('pageAnimations', [
      transition(':enter', [
        query('app-destination, form', [
          style({opacity: 0, transform: 'translateY(-100px)'}),
          stagger(-30, [
            animate('500ms cubic-bezier(0.35, 0, 0.25, 1)', style({ opacity: 1, transform: 'none' }))
          ])
        ])
      ])
    ]),
  ]
})
export class TopDestinationComponent implements OnInit,OnDestroy {
  innerWidth:any=800;
  listItem = [];
  trans:string;
  list_order: number = 1;
 
  @Output() offerList:offeritem[]=[ {
    title:'Seoul',
    body:'Megacity in Maharashtra, India',
    imgur:'./../assets/img/top-city/slide1.jpg',
    flight:"Seo De Urgel-Pirineus - la Seu d'Urgel Airport-LEU'",
    hotel:{
      CityId: 22762,
      City: "SEOUL",
      Country: "SOUTH KOREA",
      CityWithCountry: "SEOUL,SOUTH KOREA"
    }
  },
  {
    title:'Birmingham',
    body:'Capital of Texas',
    imgur:'./../assets/img/top-city/slide2.jpg',
    flight:'Birmingham-Birmingham-Shuttlesworth International Airport-BHM',
    hotel:{
      CityId: 1748,
      City: "BIRMINGHAM - AL",
      Country: "UNITED STATES OF AMERICA",
      CityWithCountry: "BIRMINGHAM - AL,UNITED STATES OF AMERICA"
    }
  },
  {
    title:'Madrid',
    body:'State capital of Massachusetts, U.S.',
    imgur:'./../assets/img/top-city/slide3.jpg',
    flight:'Madrid-Adolfo Suárez Madrid–Barajas Airport-MAD',
    hotel:{
      CityId: 14869,
      City: "MADRID",
      Country: "SPAIN",
      CityWithCountry: "MADRID,SPAIN"
    }
  },
  {
    title:'Dubai',
    body:'Metropolis in United Arab Emirates',
    imgur:'./../assets/img/top-city/slide4.jpg',
    flight:'Dubai-Dubai International Airport-DXB',
    hotel:{
      CityId: 6125,
      City: "DUBAI",
      Country: "UNITED ARAB EMIRATES",
      CityWithCountry: "DUBAI,UNITED ARAB EMIRATES"
    }
  },
  {
    title:'Paris',
    body:'Capital of France',
    imgur:'./../assets/img/top-city/slide5.jpg',
    flight:'Beauvais-Paris Beauvais Tillé Airport-BVA',
    hotel:{
      CityId: 18745,
      City: "PARIS",
      Country: "FRANCE",
      CityWithCountry: "PARIS,FRANCE"
    }
  },
  {
    title:'San Francisco',
    body:'City in Washington, United States',
    imgur:'./../assets/img/top-city/slide6.jpg',
    flight: 'San Francisco-San Francisco International Airport-SFO',
    hotel:{
      CityId: 21862,
      City: "SAN FRANCISCO - CA",
      Country: "UNITED STATES OF AMERICA",
      CityWithCountry: "SAN FRANCISCO - CA,UNITED STATES OF AMERICA"
    }
  }
  ];
  windowlist:any[]=[]
  windownumb :number = 4;
  interval:any;
  tim:any;


  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    // console.log(this.innerWidth);
  }
  constructor() { }
 
  ngOnInit() {
    if(window.innerWidth<576){
      this.windownumb =1;
    }
    else if(window.innerWidth>576 && window.innerWidth<768){
      this.windownumb =1;
    }
    else if ( window.innerWidth>750 && window.innerWidth<992 ){
      this.windownumb =3;
    }
    else {
     this.windownumb =4;
    }
    for (let index = 0; index < this.windownumb; index++) {
      this.windowlist.push(this.offerList[index]);
      
    }
    this.interval = setInterval(()=>this.next(),30000);
  }
 
  // addItem() {
  //   var listitem = "ListItem " + this.list_order;
  //   this.list_order++;
  //   this.listItem.push(listitem);
  // }
  // removeItem() {
  //   this.listItem.length -= 1;
  // }
  next(){
    clearInterval(this.tim);
    let firs:any = this.offerList[0];
    let res:any[] = this.offerList.slice(1);
    res.push(firs);
    this.offerList = res;
    this.windowlist.length=this.windownumb;
    this.windowlist = [];
    this.tim=setInterval(()=>{
      for (let index = 0; index < this.windownumb; index++) {
        this.windowlist.push(this.offerList[index]);
        
      }
      clearInterval(this.tim);
    },500)
    

  }
  prev(){
    clearInterval(this.tim);
    let las:any = this.offerList[this.offerList.length-1];
    console.log(las)
    let rest:any[] = this.offerList.slice(0,this.offerList.length-1);
    let res : any[]=[];
    res.push(las);
    rest.map((item)=>res.push(item));
    this.offerList = res
    this.windowlist = [];
    this.tim=setInterval(()=>{
          for (let index = 0; index < this.windownumb; index++) {
        this.windowlist.push(this.offerList[index]);
        
      }
      clearInterval(this.tim);
    },500)
  
  }
  ngOnDestroy(){
    clearInterval(this.interval);
  }
}
