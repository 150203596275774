import { HighlighterPipe } from './pipes/highlighter.pipe';
import { FarRulesComponent } from './Components/flight-result/far-rules/far-rules.component';
import { TermOfuseComponent } from './Components/term-ofuse/term-ofuse.component';
import { PrivacyComponent } from './Components/privacy/privacy.component';
import { ContactusCOntainerComponent } from './Components/contactus-container/contactus-container.component';
import { FamilyMembersComponent } from './Components/users/family-members/family-members.component';
import { UsersComponent } from './Components/users/users.component';
import { ChangePasswordComponent } from './Components/users/change-password/change-password.component';
import { EditAcountComponent } from './Components/users/edit-acount/edit-acount.component';
import { ForgetPasswordComponent } from './Components/users/forget-password/forget-password.component';
import { UpcomingAhistoryComponent } from './Components/users/upcoming-ahistory/upcoming-ahistory.component';
import { UpcomingAhistoryHComponent } from './Components/users/upcoming-ahistory-h/upcoming-ahistory-h.component';
import { UserLoginComponent } from './Components/users/user-login/user-login.component';
import { UserSignupComponent } from './Components/users/user-signup/user-signup.component';
import { OrdersComponent } from './Components/users/orders/orders.component';
//angular Imports Start//
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
//angular import End //

//Component Import start//
import { AppComponent } from './app.component';
import { SearchResultComponent } from './Components/search-result/search-result.component';
import { HeadderComponent } from './Components/headder/headder.component';
import { BestOffersComponent } from './Components/best-offers/best-offers.component';
//Component Tmport end//

//Services Import start//
import { MyApiService } from './Services/my-api.service';
//Services Import end//

//pipes Import start //
import { FilterCityPipe } from './pipes/filter-city.pipe';
import { DatePipe } from '@angular/common';
import { HourMinutePipe } from './pipes/hour-minute.pipe';
import { DurationToHourMinPipe } from './pipes/duration-to-hour-min.pipe';
//pipes Import end//
//other libraries statr //
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { NewSearchComponent } from './Components/new-search/new-search.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatChipsModule } from '@angular/material/chips';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatStepperModule } from '@angular/material/stepper';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
// import ngx-translate and the http loader
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { TopDestinationComponent } from './Components/top-destination/top-destination.component';

import { Ng5SliderModule } from 'ng5-slider';
import { ResearchComponent } from './Components/research/research.component';
import { CheckoutComponent } from './Components/checkout/checkout.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CouncodePipe } from './pipes/councode.pipe';
import { ConfirmationComponent } from './Components/confirmation/confirmation.component';
import { PreConfirmationComponent } from './Components/pre-confirmation/pre-confirmation.component';
import { BestOffersDetailsComponent } from './Components/best-offers/best-offers-details/best-offers-details.component';
import { MultiResultCardComponent } from './Components/multi-result-card/multi-result-card.component';
import { NormalerrorComponent } from './Components/normalerror/normalerror.component';
import { PaymentErorrComponent } from './Components/payment-erorr/payment-erorr.component';
import { CodToCityPipe } from './pipes/cod-to-city.pipe';
import { InsuranceComponent } from './Components/insurance/insurance.component';
import { FooterComponent } from './Components/footer/footer.component';
import { ExchangePipe } from './pipes/exchange.pipe';
import { AboutUsComponent } from './Components/about-us/about-us.component';
import { ContactComponent } from './Components/contact/contact.component';
import { FareRulesComponent } from './Components/fare-rules/fare-rules.component';
import { HotelSearchComponent } from './Components/hotel-search/hotel-search.component';
import { HomeComponent } from './home/home.component';
import { LimitToPipe } from './pipes/limit-to.pipe';
import { HotelecitesPipe } from './pipes/hotelecites.pipe';
import { HotelTopDistComponent } from './Components/hotel-top-dist/hotel-top-dist.component';
import { TopNationalitiesComponent } from './Components/top-nationalities/top-nationalities.component';
import { AgmCoreModule } from '@agm/core';
import { FlightSearchComponent } from './Components/flight-search/flight-search.component';
import { FlightResultComponent } from './Components/flight-result/flight-result.component';
import { RoundtripComponent } from './Components/flight-result/roundtrip/roundtrip.component';
import { MulticityComponent } from './Components/flight-result/multicity/multicity.component';
import { HotelsPreConfirmationComponent } from './Components/hotels-pre-confirmation/hotels-pre-confirmation.component';
import { ConfirmationHotelComponent } from './confirmation-hotel/confirmation-hotel.component';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { OneWayComponent } from './Components/flight-result/one-way/one-way.component';
import { BigHeadderComponent } from './Components/big-headder/big-headder.component';
import { DestinationComponent } from './Components/top-destination/destination/destination.component';
import { NavlistComponent } from './Components/navlist/navlist.component';
import { NotfoundComponent } from './Components/notfound/notfound.component';
import { AuthInterceptorService } from './interseptor/auth-interceptor.service';
import { CobonComponent } from "./Components/checkout/cobon/cobon.component";
import { OfflineFilterPipe } from './pipes/offline-filter.pipe';
import { MatTooltipModule } from "@angular/material/tooltip";
import { BidiModule } from '@angular/cdk/bidi';
const routes: Routes =
  //  language,currency,SearchPoint,flightType,flightInfo,searchId,passengers,Cclass,directOnly
  [
    { path: '', component: HomeComponent },
    { path: 'flightResult/:language/:currency/:SearchPoint/:flightType/:flightInfo/:searchId/:passengers/:Cclass/:directOnly', component: FlightResultComponent },
    { path: 'multiCity/:language/:currency/:SearchPoint/:flightType/:flightInfo/:searchId/:passengers/:Cclass/:directOnly', component: MulticityComponent },

    { path: 'checkout', component: CheckoutComponent },
    { path: 'checkout/:sid/:sequenceNum/:providerKey', component: CheckoutComponent },
    { path: 'confirmation', component: ConfirmationComponent },
    { path: 'confirmation/:HGNum/:sid', component: ConfirmationComponent },
    { path: 'paymentresult', component: PreConfirmationComponent },
    { path: 'bestoffers', component: BestOffersDetailsComponent },
    { path: 'bestoffers/:id/:pos', component: BestOffersDetailsComponent },
    { path: 'insurance', component: InsuranceComponent },
    { path: 'aboutUs', component: AboutUsComponent },
    { path: 'Contact', component: ContactusCOntainerComponent },
    { path: 'terms/:sid/:sequenceNum', component: FareRulesComponent },
    { path: 'hotel', component: HotelSearchComponent },
    { path: 'error', component: NormalerrorComponent },
    { path: "hotel-paymentresult", component: HotelsPreConfirmationComponent },
    { path: "confirmation-hotel", component: ConfirmationHotelComponent },
    { path: 'privacyPolicy', component: PrivacyComponent },
    { path: 'termsOfuse', component: TermOfuseComponent },
    { path: 'login', component: UserLoginComponent },
    { path: 'signup', component: UserSignupComponent },
    { path: 'forgetPassword', component: ForgetPasswordComponent },
    { path: 'profile', component: UsersComponent },
    { path: '**', component: NotfoundComponent }
  ]

//other libraries end//
@NgModule({
  declarations: [
    AppComponent,
    SearchResultComponent,
    NewSearchComponent,
    FilterCityPipe,
    HeadderComponent,
    BestOffersComponent,
    TopDestinationComponent,
    HourMinutePipe,
    DurationToHourMinPipe,
    ResearchComponent,
    CheckoutComponent,
    CouncodePipe,
    ConfirmationComponent,
    PreConfirmationComponent,
    BestOffersDetailsComponent,
    MultiResultCardComponent,
    NormalerrorComponent,
    PaymentErorrComponent,
    CodToCityPipe,
    InsuranceComponent,
    FooterComponent,
    ExchangePipe,
    AboutUsComponent,
    ContactComponent,
    FareRulesComponent,
    HotelSearchComponent,
    HomeComponent,
    NotfoundComponent,
    LimitToPipe,
    HotelecitesPipe,
    HotelTopDistComponent,
    TopNationalitiesComponent,
    FlightSearchComponent,
    FlightResultComponent,
    HotelsPreConfirmationComponent,
    ConfirmationHotelComponent,
    RoundtripComponent,
    MulticityComponent,
    HotelsPreConfirmationComponent,
    ContactusCOntainerComponent,
    PrivacyComponent,
    TermOfuseComponent,
    OneWayComponent,
    BigHeadderComponent,
    DestinationComponent,
    FarRulesComponent,
    HighlighterPipe,
    NavlistComponent,
    FamilyMembersComponent,
    UsersComponent,
    ChangePasswordComponent,
    EditAcountComponent,
    ForgetPasswordComponent,
    UpcomingAhistoryComponent,
    UpcomingAhistoryHComponent,
    UserLoginComponent,
    UserSignupComponent,
    OrdersComponent,
    CobonComponent,
    OfflineFilterPipe

  ],
  imports: [
    //angular imports
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(
      routes
      , { scrollPositionRestoration: 'enabled' }),
    //other imports
    BsDropdownModule.forRoot(),
    NgxIntlTelInputModule,
    MatFormFieldModule,
    MatRadioModule,
    Ng5SliderModule,
    MatCheckboxModule,
    MatChipsModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatButtonModule,
    MatAutocompleteModule,
    MatIconModule,
    MatTabsModule,
    MatSelectModule,
    MatCardModule,
    MatMenuModule,
    MatExpansionModule,
    MatDividerModule,
    MatProgressBarModule,
    MatStepperModule,
    MatListModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatGridListModule,
    MatProgressSpinnerModule,
    NgbModule,
    MatToolbarModule,
    MatSidenavModule,
    MatSlideToggleModule,
    BidiModule,
    // NgxGalleryModule,
    // configure the imports
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAakfCX9a_xG826EHxrwsbHtH9VbLaREDs'
    }),
    // AgmJsMarkerClustererModule,
    // AgmSnazzyInfoWindowModule,
    VirtualScrollerModule,
    LazyLoadImageModule
  ],
  providers: [MyApiService, DatePipe, MatDatepickerModule, {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptorService,
    multi: true
  }],
  bootstrap: [AppComponent],
  entryComponents: [FarRulesComponent, CobonComponent,]
})
export class AppModule { }
// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}