<!-- small nav -->
<nav class="topNav navbar navbar-expand-lg navbar-light bg-light change-inside" >
  <div class="container-fluid">
    <div class="row" style="width: 100%;position: relative;">
 
      <div class="col-12 col-md-6 offset-md-6">
        <ul class="navbar-nav lang-curr">
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                  <i class="fa fa-globe"></i> {{"header.lang"|translate}}
              </a>
              <div class="dropdown-menu Language" aria-labelledby="navbarDropdown">
                <a class="dropdown-item "  (click)='switchtoEn()'
                [ngClass]="{'active': engisActive()}">
                 <img src="../../../assets/img/English_language_-_United_States,_Canada_and_the_United_Kingdom.svg"width="20px">
                  English
                </a>
                <a class="dropdown-item"  (click)='switchtoAr()'
                [ngClass]="{'active': !engisActive()}">
                  <img src="../../../assets/img/Flag_of_the_Arabic_language.svg" width="20px">
                  Arabic
                </a>
              </div>
            </li>
            <li class="nav-item dropdown currdropDown" (click)="hideOther()">
               
                <form class="example-form">
                  <a>
                    <mat-form-field class="currHeader" color="accent" appearance="standerd" style="margin-top: 5px;" id="curuncyfeild"> 
                      <!-- <span>{{"header.currency"|translate}}  | </span> -->
                      <ng-container *ngIf="curruncey.value">
                        <img class="example-option-img" aria-hidden [src]="curruncey.value.Image_Url" height="20" width="25" >
                        <span class="code"> {{curruncey.value['Currency_Code']}} </span>
                      </ng-container>
                      <mat-select [formControl]="curruncey"  class="selectbutton" [disabled]="disable" >
                          
                        <mat-option *ngFor="let item of flags " [value]="item" class="toTheleft">
                          <img class="example-option-img" aria-hidden [src]="item.Image_Url" height="18" width="25">
                          <span *ngIf="!disable">{{item.Currency_Code}} |</span> 
                          <small *ngIf="!disable">{{item.Currency_Name}}</small>
                        </mat-option>
                      </mat-select>
                    </mat-form-field>  
                  </a>
                    
                </form>
              </li>
          </ul>
        <!-- </div> -->
      </div>
    </div>



 </div>
</nav>



