import { Injectable, EventEmitter } from '@angular/core';
import { Cobon } from '../interface/cobon';

@Injectable({
  providedIn: 'root'
})
export class CobonService {
  applaycobon: EventEmitter<Cobon> = new EventEmitter();
  constructor() { }

  activateCobon(value:Cobon){
    this.applaycobon.emit(value);
  }
}
