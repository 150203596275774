<div class="row justify-content-center">
  <!-- <div class="col-8"> -->
    <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
      <div class="carousel-inner row ">
          
        <ng-container *ngFor="let item of insurance.prods ;let i = index ">
          <div class="carousel-item  col-xs-12" [ngClass]="{active: i==0}">
            <div class="card">
              <div class="insuranceTitle row">
                <div class="card-body col-xs-12  col-sm-8 cardInfo">
                  <div>
                  <mat-radio-group [(ngModel)]="insuranceid" (change)="updateInsurance()">
                    <mat-radio-button [value]="item.id"  class="mb-2">
                      <h4 class="mb-0">{{item.productName}}</h4>
                    </mat-radio-button>
                  </mat-radio-group>
                  </div>
                  <h5 class="ml-2"> Protect your trip against</h5>
                  <ul class="list-group ">
                    <li class="list-group-item ml-4" *ngIf="item.details.item1"> <i class="fa fa-check-square fa-lg" style="color: #00838f"> </i>
                      {{item.details.item1}}</li>
                    <li class="list-group-item ml-4"*ngIf="item.details.item2"> <i class="fa fa-check-square fa-lg" style="color: #00838f"> </i>
                      {{item.details.item2}}</li>
                    <li class="list-group-item ml-4"*ngIf="item.details.item3"> <i class="fa fa-check-square fa-lg" style="color: #00838f"> </i>
                      {{item.details.item3}}</li>
                    <li class="list-group-item ml-4"*ngIf="item.details.item4"> <i class="fa fa-check-square fa-lg" style="color: #00838f"> </i>
                      {{item.details.item4}}</li>
                  </ul>
                </div>
                <div class="price col-11 mr-3 col-sm-4 mr-sm-0 row align-items-center">
                  <div class="col-12 align-self-center text-center myIn">
                    <h3><small>{{item.currency}}</small> {{item.totalAmount}}</h3>
                    <!-- Button trigger modal -->
                    <button type="button" class="btn  viewButton" data-toggle="modal"
                      [attr.data-target]="'#exampleModalCenter'+i+'active'">
                      View Benefits
                    </button>

                  </div>


                </div>
              </div>

            </div>
            <div class="card-footer text-muted">
              this product is underwritten and isued by {{item.disclosure}} Insurance Company <small>By purching It You Confierm to have
                Read the <a href="#">Terms and Condition</a></small>
            </div>

            <!-- Modal -->
            <div class="modal fade" [id]="'exampleModalCenter'+i+'active'" tabindex="-1" role="dialog"
              aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
              <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalCenterTitle">Insurance Coverage</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">

                    <table class="table">
                      <thead class="thead-light">
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Benefits </th>
                          <th scope="col">Sum Insurance</th>
                        </tr>
                      </thead>
                      <tbody>
                        <ng-container *ngFor="let term of item.productcover;let m = index">
                        <tr>
                          <th scope="row">{{m+1}}</th>
                          <td>{{term.header}}</td>
                          <td>{{term.currency}}{{term.amount}}</td>
                        </tr>
                        </ng-container>
                      </tbody>
                    </table>
                  </div>
                  <div class="modal-footer">
                      <label class="radio-inline">
                          <a href="#"> Terms and Conditions</a>
                      </label>
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </ng-container>

        <div class="insuranceCur">
          <a class=" insuranceCur carousel-control-prev" href="#carouselExampleControls" role="button"
            data-slide="prev">
            <span style="color: #00838f;">
              <i class="fa fa-chevron-circle-left fa-2x"></i>
            </span>
            
            <span class="sr-only">Previous</span>
          </a>
          <a class=" insuranceCur carousel-control-next" href="#carouselExampleControls" role="button"
            data-slide="next">
            <span style="color: #00838f">
              <i class="fa fa-chevron-circle-right fa-2x"></i>
            </span>
            
            <span class="sr-only">Next</span>
          </a>
        </div>

      </div>

    </div>
    <div class="col-8" style="padding-left: 25px;">
      <mat-radio-group [(ngModel)]="insuranceid" (change)="updateInsurance()">
        <mat-radio-button [value]="-1"  class="mb-2">
          <h4 class="mb-0">I Dont Need Any Insurance</h4>
        </mat-radio-button>
      </mat-radio-group>
    <!-- </div> -->
  </div>