import { Component, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-navlist',
  templateUrl: './navlist.component.html',
  styleUrls: ['./navlist.component.css']
})
export class NavlistComponent implements OnInit {
  @Output() switch:EventEmitter<boolean> = new EventEmitter();
  constructor() { }
  toggleHF:boolean = false;
  ngOnInit() {
  }

  
  switchHF(toggle:boolean){
    this.toggleHF = toggle;
    this.switch.emit(this.toggleHF);
  }

}
