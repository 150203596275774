import { Component, OnInit, Input, OnDestroy, ViewChild } from '@angular/core';
import { MyApiService } from 'src/app/Services/my-api.service';
import { CobonService } from 'src/app/Services/cobon.service';
import { Subscription } from 'rxjs';
import { error } from 'protractor';
import { Cobon } from 'src/app/interface/cobon';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MatInput } from '@angular/material/input';

@Component({
  selector: 'app-cobon',
  templateUrl: './cobon.component.html',
  styleUrls: ['./cobon.component.css']
})
export class CobonComponent implements OnInit, OnDestroy {
  @Input() SId: string;
  @Input() sequ: any;
  @Input() pkey: string;
  @ViewChild('input') input: MatInput;
  loading = false;
  PromoCode: string = '';
  status: string = "waiting"
  private subscription: Subscription = new Subscription();

  constructor(private myapi: MyApiService, private cobons: CobonService, public activeModal: NgbActiveModal) { }

  ngOnInit() {

  }
  submit() {
    this.loading = true;
    this.subscription.add(this.myapi.activateCobon(this.PromoCode, this.SId, this.sequ, this.pkey).subscribe((value) => { this.casepromo(value), this.loading = false; console.log(value) },
      (error) => { }))

  }
  casepromo(value: Cobon) {
    switch (value.status) {
      case "Active":
        this.status = "Active";
        this.cobons.activateCobon(value);
        this.activeModal.close('Close click');
        break;
      case "Expired":
        this.status = "Expired";
        break;

      case "Invalid":
        this.status = "Invalid";
        break;
      default:
        this.status = "waiting";
        break;
    }
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
