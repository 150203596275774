<div class="Fresult">


    <ng-container *ngIf="item.length > 0 || over">
        <ng-container *ngTemplateOutlet="flightContent"></ng-container>
    </ng-container>


</div>










<ng-template #flightContent>




    <div class="flight-item-container">
        <!-- flightItem -->
        <div class="flight-item mt-3">
            <div class="row" (click)="trueT[0]= !trueT[0]" [attr.aria-expanded]="!trueT[0]" [attr.aria-controls]="'detailsItem'+0">
                <div class="col-12 order-1 order-sm-1 col-md-2 align-self-center">
                    <div class="item-airline-logo">
                        <img src="{{item[0].allJourney.flights[0].flightDTO[0].flightAirline.airlineLogo}}" alt="airline logo" title="Image Title">
                    </div>

                </div>
                <div class="col-12 col-md-7 order-3 order-sm-2 align-self-start  flightData">
                    <!-- departing and return cities -->
                    <div class="row">
                        <div class="col-5 col-md-3">
                            <h5 class="item-destination mt-3 mb-0">
                                <!-- Cairo (CAI) -->
                                <strong>{{item[0].allJourney.flights[0].flightDTO[0].departureTerminalAirport.cityName}}</strong>
                                <sub>({{item[0].allJourney.flights[0].flightDTO[0].departureTerminalAirport.airportCode}})</sub>
                            </h5>
                        </div>
                        <div class=" col align-self-center text-center">

                        </div>
                        <div class="col-5 col-md-5">
                            <h5 class="item-destination mt-3 mb-0">
                                <strong>{{flight.arrivalCity(item[0],0)}}</strong>

                                <sub>({{flight.arrivalAircode(item[0],0)}})</sub>
                            </h5>

                        </div>
                    </div>
                    <!-- departing and return svg -->
                    <div class="row">
                        <div class="col-6 col-md-2 order-2 order-md-1">
                            <h5 class="flight-type-title mb-1" style="margin-top: 7px;"> {{"SearchResult.departure"|translate}} </h5>
                        </div>
                        <div class="col-12 col-md-5 order-1 order-md-2 mob">
                            <img src="../../../../assets/img/round.svg" alt="" class="img-fluid">
                        </div>
                        <div class="col-6 col-md-5 order-3 order-md-3">
                            <h5 class="flight-type-title mb-1" style="margin-top: 7px;"> {{"SearchResult.return"|translate}} </h5>
                        </div>
                    </div>
                    <!-- departing and return dates -->
                    <div class="row topmar">
                        <div class="col-6 col-sm-4 DRdate">
                            {{item[0].allJourney.flights[0].flightDTO[0].departureDate | date:'mediumDate'}}
                        </div>
                        <div class="col between">

                        </div>
                        <div class="col-6 col-sm-5 DRdate ">
                            {{item[0].allJourney.flights[1].flightDTO[0].departureDate | date:'mediumDate'}}
                            <!-- {{flight.arrivalDate(item[0],0) | date:'mediumDate'}} -->
                        </div>
                    </div>
                    <!-- time of arriva and departing -->
                    <div class="row">
                        <div class="col col-md-2 Stime ">
                            {{item[0].allJourney.flights[0].flightDTO[0].departureDate | date:'shortTime'}}
                        </div>
                        <div class="col col-1 p-0">
                            <img src="../../../../assets/img/arrow1.svg" alt="" class="arrow1">
                        </div>
                        <div class="col col-md-2 Stime ">
                            <!-- {{flight.arrivalDate(item[0],0) | date:'shortTime'}} -->
                            {{flight.arrivalDate(item[0],0) | date:'shortTime'}}

                        </div>
                        <div class="col between">

                        </div>
                        <div class="col col-md-2 Stime  marg-mob">
                            {{item[0].allJourney.flights[1].flightDTO[0].departureDate | date:'shortTime'}}
                        </div>
                        <div class="col col-1 p-0">
                            <img src="../../../../assets/img/arrow1.svg" alt="" class="arrow1">
                        </div>
                        <div class="col col-md-2 Stime ">
                            {{flight.arrivalDate(item[0],1) | date:'shortTime'}}
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-3 order-2  order-sm-3 align-self-center text-center">
                    <!-- <span class="item-price"> {{item[0].itinTotalFare.amount|exchange:'value'}}
              <b class="currency">{{flight.response.searchCriteria.currency|exchange:'code'}}</b></span> -->
                    <b> <sub class="currency">{{item[0]['itinTotalFare']['currencyCode']|exchange:'code'}}</sub>
            <strong class="item-price">
              {{item[0].itinTotalFare.amount|exchange:'value' | currency:'EGY':''}}
            </strong></b>

                    <!-- <span class="item-class">{{"searchFlight.class"|translate}}: {{flight.response.cabinClasses}}
            </span> -->
                    <!-- <span class="total-passenger">{{"SearchResult.Passenger"|translate}}:
              {{flight.response.searchCriteria?.totalPassengersNum}}</span> -->

                    <b *ngIf="item[0].isRefundable" class="refund">{{"SearchResult.Refundable"|translate}} </b>
                    <b *ngIf="!item[0].isRefundable" class="refund" style="font-size: 13px;">
            {{"SearchResult.NonRefundable"|translate}} </b>
                    <button mat-raised-button color="primary" class="bookingbutton" (click)="flight.toCheckout(flight.response.searchCriteria.searchId,item[0].sequenceNum,item[0].pKey)" *ngIf="!over">{{"SearchResult.book"|translate}}</button>

                </div>


            </div>

            <div class="row " [id]="'detailsItem'+0" [ngbCollapse]="trueT[0]">
                <div class="col-12 flight-details">

                    <div class="row fligt-details-container">
                        <div class=" col-12 col-md-3 fligt-details-pre" style="align-self: center;
                  padding-left: 30px;">
                            <h6>{{"SearchResult.Departing"|translate}}</h6>
                            <h5>{{item[0].allJourney.flights[0].flightDTO[0].departureDate | date:'EEEE, MMM d'}}</h5>
                            <p><strong class="city-mob">{{item[0].allJourney.flights[0].flightDTO[0].departureTerminalAirport.cityName}} </strong> <img src="../../../../assets/img/arrow1.svg" alt="arrow" class="arrow1"><strong class="city-mob">
                  {{flight.arrivalCity(item[0],0)}}</strong></p>
                            <p class="mb-1 totaDur">{{item[0].allJourney.flights[0].elapsedTime| hourminute}}</p>
                            <p class="stopnumber">{{flight.direct(item[0].allJourney.flights[0].stopsNum)}}</p>

                        </div>
                        <div class=" col-12 col-md-9">
                            <div class="row details-wraber">
                                <div class="col-12 col-md-1 separater-img" style="align-self: center;"><img src="../../../../assets/img/separater.svg" alt=""></div>
                                <div class="col-12 col-md-11">
                                    <div class=" row">
                                        <ng-container *ngFor="let stop of flight.showStopStation(item[0],0);let s =index ">

                                            <div class="col-12 col-md-6 segment-details">
                                                <h5>{{stop.departureDate | date:'EEEE, MMM d'}}</h5>
                                                <p class="sigment-time" style="margin-right: 18px;">{{stop.departureDate | date:'shortTime'}}
                                                </p> <img src="../../../../assets/img/slash.svg" alt="" class="slash">
                                                <p class="sigment-time" style="margin-left: 18px;"> {{stop.arrivalDate | date:'shortTime'}}</p>
                                                <div>
                                                    <p class="SCityName" style="margin-right: 10px;">{{ stop.departureTerminalAirport.cityName }}
                                                    </p>
                                                    <img src="../../../../assets/img/long arrow.svg" class="rtl-arrow" alt="">
                                                    <span class="mobile-ar"><i class="fas fa-arrow-down"></i></span>
                                                    <p style="margin-left: 10px;" class="SCityName">{{stop.arrivalTerminalAirport.cityName}}</p>
                                                </div>


                                            </div>
                                            <div class="col-12 col-md-6 baggage-operated-by text-left" style="padding-left: 15%;">
                                                <p class="bags-info" *ngIf="item[0].baggageInformation"><i class="fas fa-suitcase-rolling" style="color: #779b3d; margin: 10px; font-size: 15px;"></i><span>
                            {{"SearchResult.baggage"|translate}} = {{ item[0].baggageInformation[s]?.baggage }}
                          </span>
                                                    <ng-container *ngIf="item[0].baggageInformation[s].childBaggage != '' && item[0].baggageInformation[s].childBaggage">
                                                        <br> <i class="fas fa-suitcase-rolling" style="color: #779b3d; margin: 0 10px; font-size: 15px;"></i>{{"SearchResult.childBags"|translate}} = <span>
                              {{item[0].baggageInformation[s]?.childBaggage}} </span>
                                                    </ng-container>
                                                </p>
                                                <p class="operated-info" *ngIf="stop.operatedAirline.airlineName">
                                                    <i class="fas fa-plane " style="color: #779b3d; margin: 0 10px; font-size: 15px;"></i> {{"SearchResult.operated"| translate}}<span>
                            {{stop.operatedAirline?.airlineName}}
                          </span>
                                                </p>
                                                <p class="cabinClass">
                                                    <i class="fab fa-creative-commons" style="color: #779b3d; margin: 10px; font-size: 15px;"></i>
                                                    <!-- {{"searchFlight.class"|translate}}: -->
                                                    {{item[0]['cabinClass']}}
                                                </p>
                                            </div>
                                            <div class="col-12 col-md-12 p-0">
                                                <!-- <div class="col-12 col-md-3 align-self-center" *ngIf="stop.isStopSegment"> -->
                                                <!-- flight duration -->
                                                <p class="airport-name text-center mt-2 mb-2">
                                                    <!-- gulf air -->
                                                    {{stop.arrivalTerminalAirport.airportName}}
                                                </p>
                                                <span class="flight-dur" *ngIf="stop.transitTime !='00:00:00'">
                          <!-- 5h 30 m -->
                          {{stop.transitTime }}

                        </span>
                                                <!-- flight duration -->
                                            </div>
                                            <!-- <div class="col-12 col-md-12 p-0" *ngIf="stop.isStopSegment">
                     

                     
                            <p class="airport-name text-center mt-2 mb-2"> {{stop.arrivalTerminalAirport?.airportName}}
                              <span class="flight-dur">
                                5h 30 m
                                {{stop.transitTime }}

                              </span>
                            </p>

                     
                      </div> -->

                                        </ng-container>
                                    </div>
                                </div>


                            </div>

                        </div>

                    </div>
                    <div class="row fligt-details-container">
                        <div class=" col-12 col-md-3 fligt-details-pre" style="    align-self: center;
                  padding-left: 30px;">
                            <h6>{{"SearchResult.return"|translate}}</h6>
                            <h5>{{item[0].allJourney.flights[1].flightDTO[0].departureDate | date:'EEEE, MMM d'}}</h5>
                            <p><strong class="city-mob">{{item[0].allJourney.flights[1].flightDTO[0].departureTerminalAirport.cityName}} </strong> <img src="../../../../assets/img/arrow1.svg" alt="arrow" class="arrow1"><strong class="city-mob">
                  {{flight.arrivalCity(item[0],1)}}</strong></p>
                            <p class="mb-1 totaDur">{{item[0].allJourney.flights[1].elapsedTime| hourminute}}</p>
                            <p class="stopnumber">{{flight.direct(item[0].allJourney.flights[1].stopsNum)}}</p>

                        </div>
                        <div class=" col-12 col-md-9">
                            <div class="row details-wraber">
                                <div class="col-12 col-md-1 separater-img" style="align-self: center;"><img src="../../../../assets/img/separater.svg" alt=""></div>
                                <div class="col-12 col-md-11">
                                    <div class=" row">
                                        <ng-container *ngFor="let stop2 of flight.showStopStation(item[0],1);let r =index ">

                                            <div class="col-12 col-md-6 segment-details">
                                                <h5>{{stop2.departureDate | date:'EEEE, MMM d'}}</h5>
                                                <p class="sigment-time" style="margin-right: 18px;">{{stop2.departureDate | date:'shortTime'}}
                                                </p> <img src="../../../../assets/img/slash.svg" alt="" class="slash">
                                                <p class="sigment-time" style="margin-left: 18px;"> {{stop2.arrivalDate | date:'shortTime'}}</p>
                                                <div>
                                                    <p class="SCityName" style="margin-right: 10px;">{{ stop2.departureTerminalAirport.cityName }}
                                                    </p>
                                                    <img src="../../../../assets/img/long arrow.svg" class="rtl-arrow" alt="">
                                                    <span class="mobile-ar"><i class="fas fa-arrow-down"></i></span>

                                                    <p style="margin-left: 10px;" class="SCityName">{{stop2.arrivalTerminalAirport.cityName}}</p>
                                                </div>


                                            </div>
                                            <div class="col-12 col-md-6 baggage-operated-by text-left" style="padding-left: 15%;">
                                                <p class="bags-info" *ngIf="item[0].baggageInformation"><i class="fas fa-suitcase-rolling" style="color: #779b3d; margin: 10px; font-size: 15px;"></i><span>
                            {{"SearchResult.baggage"|translate}} = {{ item[0].baggageInformation[r]?.baggage }}
                          </span>
                                                    <ng-container *ngIf="item[0].baggageInformation[r].childBaggage != '' && item[0].baggageInformation[r].childBaggage">
                                                        <br> <i class="fas fa-suitcase-rolling" style="color: #779b3d; margin: 0 10px; font-size: 15px;"></i> {{"SearchResult.childBags"|translate}} = <span>
                              {{item[0].baggageInformation[r]?.childBaggage}} </span>
                                                    </ng-container>
                                                </p>
                                                <p class="operated-info" *ngIf="stop2.operatedAirline.airlineName">
                                                    <i class="fas fa-plane " style="color: #779b3d; margin: 0 10px; font-size: 15px;"></i> {{"SearchResult.operated"| translate}}<span>
                            {{stop2.operatedAirline.airlineName}}
                          </span>
                                                </p>
                                                <p class="cabinClass">
                                                    <i class="fab fa-creative-commons" style="color: #779b3d; margin: 10px; font-size: 15px;"></i>
                                                    <!-- {{"searchFlight.class"|translate}}: -->
                                                    {{item[0]['cabinClass']}}
                                                </p>
                                            </div>
                                            <div class="col-12 col-md-12 p-0 " *ngIf="stop2.isStopSegment">



                                                <p class="airport-name text-center mt-2 mb-2"> {{stop2.arrivalTerminalAirport.airportName}}
                                                    <span class="flight-dur">
                                <!-- 5h 30 m -->
                                {{stop2.transitTime }}

                              </span>
                                                </p>



                                            </div>

                                        </ng-container>
                                    </div>
                                </div>


                            </div>

                        </div>

                    </div>
                    <!-- togglemodel(i,item[0].sequenceNum) -->
                    <div class="row detais-footer">
                        <button mat-button color="accent" data-toggle="modal" (click)="open(item[0].sequenceNum,item[0].pKey)">{{"SearchResult.terms"|translate}}</button>


                    </div>



                </div>
            </div>

        </div>
        <!-- flightItem -->
        <!-- more details -->

        <!-- more flight btn -->

        <ng-container *ngIf="item.length > 1">
            <button type="button" class="btn seeMore" (click)="moreT=!moreT" [attr.aria-expanded]="!moreT" [attr.aria-controls]="'showTerm'+i">
        <span class="more-btn-txt">
          {{item.length -1}} {{"SearchResult.more"|translate}} <i class="fa fa-chevron-down fa-x"></i>
        </span>
        <span class="more-btn-num">
          {{item.length -1}}
        </span>
      </button>

            <!-- <div style="border:1px solid">{{item[0].sequenceNum}}</div> -->
        </ng-container>
        <!-- more flight btn-->
        <!-- more flights card  -->
        <!-- <div class="flight-item" *ngFor="let term of item|slice:1;let m= index;"> -->
        <ng-container *ngIf="!moreT">
            <div [id]="'showTerm'+i" [ngbCollapse]="moreT" *ngFor="let term of item|slice:1;let m= index;">

                <div class="flight-item mt-3">
                    <div class="row" (click)="trueT[m+1]= !trueT[m+1]" [attr.aria-expanded]="!trueT[m+1]" [attr.aria-controls]="'detailsItem'+m+1">
                        <div class="col-12 col-md-2 align-self-center">
                            <div class="item-airline-logo">
                                <img src="{{term.allJourney.flights[0].flightDTO[0].flightAirline.airlineLogo}}" alt="airline logo" title="Image Title">
                            </div>

                        </div>
                        <div class="col-12 col-md-7 align-self-start  flightData">
                            <!-- departing and return cities -->
                            <div class="row">
                                <div class="col-12 col-md-3">
                                    <h5 class="item-destination mt-3 mb-0">
                                        <!-- Cairo (CAI) -->
                                        <strong>{{term.allJourney.flights[0].flightDTO[0].departureTerminalAirport.cityName}}</strong>
                                        <sub>({{term.allJourney.flights[0].flightDTO[0].departureTerminalAirport.airportCode}})</sub>
                                    </h5>
                                </div>
                                <div class=" col align-self-center text-center">

                                </div>
                                <div class="col-12 col-md-5">
                                    <h5 class="item-destination mt-3 mb-0">
                                        <strong>{{flight.arrivalCity(term,0)}}</strong>

                                        <sub>({{flight.arrivalAircode(term,0)}})</sub>
                                    </h5>

                                </div>
                            </div>
                            <!-- departing and return svg -->
                            <div class="row">
                                <div class="col-12 col-md-2">
                                    <h5 class="flight-type-title mb-1" style="margin-top: 7px;"> {{"SearchResult.departure"|translate}}
                                    </h5>
                                </div>
                                <div class="col-12 col-md-5">
                                    <img src="../../../../assets/img/round.svg" alt="">
                                </div>
                                <div class="col-12 col-md-5">
                                    <h5 class="flight-type-title mb-1" style="margin-top: 7px;"> {{"SearchResult.return"|translate}} </h5>
                                </div>
                            </div>
                            <!-- departing and return dates -->
                            <div class="row">
                                <div class="col-4 DRdate">
                                    {{term.allJourney.flights[0].flightDTO[0].departureDate | date:'mediumDate'}}
                                </div>
                                <div class="col">

                                </div>
                                <div class="col-5 DRdate">
                                    {{flight.arrivalDate(term,0) | date:'mediumDate'}}
                                </div>
                            </div>
                            <!-- time of arriva and departing -->
                            <div class="row">
                                <div class="col col-md-2 Stime ">
                                    {{term.allJourney.flights[0].flightDTO[0].departureDate | date:'shortTime'}}
                                </div>
                                <div class="col col-1 p-0">
                                    <img src="../../../../assets/img/arrow1.svg" alt="" class="arrow1">
                                </div>
                                <div class="col col-md-2 Stime ">
                                    {{flight.arrivalDate(term,0) | date:'shortTime'}}
                                </div>
                                <div class="col">

                                </div>
                                <div class="col col-md-2 Stime ">
                                    {{term.allJourney.flights[1].flightDTO[0].departureDate | date:'shortTime'}}
                                </div>
                                <div class="col col-1 p-0">
                                    <img src="../../../../assets/img/arrow1.svg" alt="" class="arrow1">
                                </div>
                                <div class="col col-md-2 Stime ">
                                    {{flight.arrivalDate(term,1) | date:'shortTime'}}
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-3 align-self-center text-center">
                            <!-- <span class="item-price"> {{item[0].itinTotalFare.amount|exchange:'value'}}
                        <b class="currency">{{flight.response.searchCriteria.currency|exchange:'code'}}</b></span> -->
                            <b> <sub class="currency">{{flight.response.searchCriteria.currency|exchange:'code'}}</sub>
                <strong class="item-price">
                  {{term.itinTotalFare.amount|exchange:'value' | currency:'EGY':''}}
                </strong></b>

                            <!-- <span class="item-class">{{"searchFlight.class"|translate}}: {{flight.response.cabinClasses}}
                      </span> -->
                            <!-- <span class="total-passenger">{{"SearchResult.Passenger"|translate}}:
                        {{flight.response.searchCriteria?.totalPassengersNum}}</span> -->

                            <b *ngIf="term.isRefundable" class="refund">{{"SearchResult.Refundable"|translate}} </b>
                            <b *ngIf="!term.isRefundable" class="refund" style="font-size: 13px;">
                {{"SearchResult.NonRefundable"|translate}} </b>
                            <button mat-raised-button color="primary" class="bookingbutton" (click)="flight.toCheckout(flight.response.searchCriteria.searchId,item[0].sequenceNum,item[0].pkey)">{{"SearchResult.book"|translate}}</button>

                        </div>


                    </div>

                    <div class="row " [id]="'detailsterm'+m" [ngbCollapse]="trueT[m+1]">
                        <div class="col-12 flight-details">

                            <div class="row fligt-details-container">
                                <div class=" col-12 col-md-3 fligt-details-pre" style="    align-self: center;
                            padding-left: 30px;">
                                    <h6>{{"SearchResult.Departing"|translate}}</h6>
                                    <h5>{{term.allJourney.flights[0].flightDTO[0].departureDate | date:'EEEE, MMM d'}}</h5>
                                    <p><strong class="city-mob">{{term.allJourney.flights[0].flightDTO[0].departureTerminalAirport.cityName}} </strong>
                                        <img src="../../../../assets/img/arrow1.svg" alt="arrow" class="arrow1"><strong class="city-mob">
                      {{flight.arrivalCity(term,0)}}</strong></p>
                                    <p class="mb-1 totaDur">{{term.allJourney.flights[0].elapsedTime| hourminute}}</p>
                                    <p class="stopnumber">{{flight.direct(term.allJourney.flights[0].stopsNum)}}</p>

                                </div>
                                <div class=" col-12 col-md-9">
                                    <div class="row details-wraber">
                                        <div class="col-12 col-md-1 separater-img" style="align-self: center;"><img src="../../../../assets/img/separater.svg" alt=""></div>
                                        <div class="col-12 col-md-11">
                                            <div class=" row">
                                                <ng-container *ngFor="let stop of flight.showStopStation(term,0);let s =index ">

                                                    <div class="col-12 col-md-6 segment-details">
                                                        <h5>{{stop.departureDate | date:'EEEE, MMM d'}}</h5>
                                                        <p class="sigment-time" style="margin-right: 18px;">
                                                            {{stop.departureDate | date:'shortTime'}} </p> <img src="../../../../assets/img/slash.svg" alt="" class="slash">
                                                        <p class="sigment-time" style="margin-left: 18px;"> {{stop.arrivalDate | date:'shortTime'}}
                                                        </p>
                                                        <div>
                                                            <p class="SCityName" style="margin-right: 10px;">
                                                                {{ stop.departureTerminalAirport.cityName }}</p> <img src="../../../../assets/img/long arrow.svg" class="rtl-arrow" alt="">
                                                            <span class="mobile-ar"><i class="fas fa-arrow-down"></i></span>
                                                            <p style="margin-left: 10px;" class="SCityName">{{stop.arrivalTerminalAirport.cityName}}
                                                            </p>
                                                        </div>


                                                    </div>
                                                    <div class="col-12 col-md-6 baggage-operated-by text-left" style="padding-left: 15%;">
                                                        <p class="bags-info"><i class="fas fa-suitcase-rolling" style="color: #779b3d; margin: 10px; font-size: 15px;"></i><span>
                                {{"SearchResult.baggage"|translate}} = {{ term.baggageInformation[s].baggage }}
                              </span>
                                                            <ng-container *ngIf="term.baggageInformation[s].childBaggage != '' && term.baggageInformation[s].childBaggage">
                                                                <br> <i class="fas fa-suitcase-rolling" style="color: #779b3d; margin: 0 10px; font-size: 15px;"></i> {{"SearchResult.childBags"|translate}} = <span>
                                  {{term.baggageInformation[s].childBaggage}} </span>
                                                            </ng-container>
                                                        </p>
                                                        <p class="operated-info" *ngIf="stop.operatedAirline.airlineName">
                                                            <i class="fas fa-plane " style="color: #779b3d; margin: 0 10px; font-size: 15px;"></i> {{"SearchResult.operated"| translate}}<span>
                                {{stop.operatedAirline.airlineName}}
                              </span>
                                                        </p>
                                                        <p class="cabinClass">
                                                            <i class="fab fa-creative-commons" style="color: #779b3d; margin: 10px; font-size: 15px;"></i>
                                                            <!-- {{"searchFlight.class"|translate}}: -->
                                                            {{item[0]['cabinClass']}}
                                                        </p>
                                                    </div>
                                                    <div class="col-12 col-md-12 p-0" *ngIf="stop.isStopSegment">



                                                        <p class="airport-name text-center mt-2 mb-2"> {{stop.arrivalTerminalAirport.airportName}}
                                                            <span class="flight-dur">
                                    <!-- 5h 30 m -->
                                    {{stop.transitTime }}

                                  </span>
                                                        </p>


                                                    </div>

                                                </ng-container>
                                            </div>
                                        </div>


                                    </div>

                                </div>

                            </div>
                            <div class="row fligt-details-container">
                                <div class=" col-12 col-md-3 fligt-details-pre" style="    align-self: center;
                            padding-left: 30px;">
                                    <h6>{{"SearchResult.return"|translate}}</h6>
                                    <h5>{{term.allJourney.flights[1].flightDTO[0].departureDate | date:'EEEE, MMM d'}}</h5>
                                    <p><strong class="city-mob">{{term.allJourney.flights[1].flightDTO[0].departureTerminalAirport.cityName}} </strong>
                                        <img src="../../../../assets/img/arrow1.svg" alt="arrow" class="arrow1">
                                        <strong class="city-mob">
                      {{flight.arrivalCity(term,1)}}</strong></p>
                                    <p class="mb-1 totaDur">{{term.allJourney.flights[1].elapsedTime| hourminute}}</p>
                                    <p class="stopnumber">{{flight.direct(term.allJourney.flights[1].stopsNum)}}</p>

                                </div>
                                <div class=" col-12 col-md-9">
                                    <div class="row details-wraber">
                                        <div class="col-12 col-md-1 separater-img" style="align-self: center;"><img src="../../../../assets/img/separater.svg" alt=""></div>
                                        <div class="col-12 col-md-11">
                                            <div class=" row">
                                                <ng-container *ngFor="let stop2 of flight.showStopStation(term,1);let r =index ">

                                                    <div class="col-12 col-md-6 segment-details">
                                                        <h5>{{stop2.departureDate | date:'EEEE, MMM d'}}</h5>
                                                        <p class="sigment-time" style="margin-right: 18px;">
                                                            {{stop2.departureDate | date:'shortTime'}} </p> <img src="../../../../assets/img/slash.svg" alt="" class="slash">
                                                        <p class="sigment-time" style="margin-left: 18px;"> {{stop2.arrivalDate | date:'shortTime'}}
                                                        </p>
                                                        <div>
                                                            <p class="SCityName" style="margin-right: 10px;">
                                                                {{ stop2.departureTerminalAirport.cityName }}</p> <img src="../../../../assets/img/long arrow.svg" class="rtl-arrow" alt="">
                                                            <span class="mobile-ar"><i class="fas fa-arrow-down"></i></span>
                                                            <p style="margin-left: 10px;" class="SCityName">{{stop2.arrivalTerminalAirport.cityName}}
                                                            </p>
                                                        </div>


                                                    </div>
                                                    <div class="col-12 col-md-6 baggage-operated-by text-left" style="padding-left: 15%;">
                                                        <p class="bags-info"><i class="fas fa-suitcase-rolling" style="color: #779b3d; margin: 10px; font-size: 15px;"></i><span>
                                {{"SearchResult.baggage"|translate}} = {{ term.baggageInformation[r].baggage }}
                              </span>
                                                            <ng-container *ngIf="term.baggageInformation[r].childBaggage != '' && term.baggageInformation[r].childBaggage">
                                                                <br> <i class="fas fa-suitcase-rolling" style="color: #779b3d; margin: 0 10px; font-size: 15px;"></i>{{"SearchResult.childBags"|translate}} = <span>
                                  {{term.baggageInformation[r].childBaggage}} </span>
                                                            </ng-container>
                                                        </p>
                                                        <p class="operated-info" *ngIf="stop2.operatedAirline.airlineName">
                                                            <i class="fas fa-plane " style="color: #779b3d; margin: 0 10px; font-size: 15px;"></i> {{"SearchResult.operated"| translate}}<span>
                                {{stop2.operatedAirline.airlineName}}
                              </span>
                                                        </p>
                                                        <p class="cabinClass">
                                                            <i class="fab fa-creative-commons" style="color: #779b3d; margin: 10px; font-size: 15px;"></i>
                                                            <!-- {{"searchFlight.class"|translate}}: -->
                                                            {{item[0]['cabinClass']}}
                                                        </p>
                                                    </div>
                                                    <div class="col-12 col-md-12 p-0" *ngIf="stop2.isStopSegment">



                                                        <p class="airport-name text-center mt-2 mb-2"> {{stop2.arrivalTerminalAirport.airportName}}
                                                            <span class="flight-dur">
                                    <!-- 5h 30 m -->
                                    {{stop2.transitTime }}

                                  </span>
                                                        </p>


                                                    </div>

                                                </ng-container>
                                            </div>
                                        </div>


                                    </div>

                                </div>

                            </div>
                            <!-- togglemodelt(i,m,term.sequenceNum) -->
                            <div class="row detais-footer">
                                <button mat-button color="accent" data-toggle="modal" (click)="open(term.sequenceNum,term.pKey)">{{"SearchResult.terms"|translate}}</button>


                            </div>



                        </div>
                    </div>


                    <button type="button" class="btn seeMore lessbtn " (click)="moreT=!moreT" [attr.aria-expanded]="!moreT" aria-controls="!moreT" *ngIf="m+2 == item.length">
            <span class="more-btn-txt">
              {{"SearchResult.less"|translate}}
            </span>
            <span class="more-btn-num">
              <i class="fa fa-chevron-up fa-x"></i>
            </span>

          </button>

                </div>
            </div>
        </ng-container>








        <!-- more flights card  -->
    </div>





</ng-template>

<ng-template #loadingkst>
    <div class="preloader">
        <img src=" ../../../../../../assets/img/loading.gif" />
    </div>

</ng-template>