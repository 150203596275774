import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  switchHF:boolean = false;
  type:string="";
  // images = [1, 2, 3].map(() => `https://picsum.photos/900/500?random&t=${Math.random()}`);
  constructor() { }
  

  ngOnInit() {
    console.log("type",this.type);
  }
  switch(HorF:boolean){
   this.switchHF = HorF;
   console.log(this.switchHF);
  }

  onChoose(ctype:string){
     this.type=ctype;
  }

}
