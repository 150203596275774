

<div class="terms-page">
    <header class="header"></header>
    <app-big-headder class="animated fadeInDown" id="navbar"></app-big-headder>
</div>

<div class="wraber terms-content" [ngClass]="{'ar':this.translate.currentLang == 'ar'}">
  <div class="container">
      <div class="row title">
          <h1 class="col-2">{{'termsOfUse.headerTxt' | translate}}</h1>
      </div>
      <div class="row align-self-center justify-content-center">
          <div class="col-xs-12 col-sm-8 col-md-10 align-self-center justify-content-center">
              <div class="boxNewGrid privcy-terms-content hotelTerms">
                  <div class="accordianBlock">
                      <h3 id="a1">{{'termsOfUse.one' | translate}}</h3>
                      <div class="accord-dis">
                          <p>
                              {{'termsOfUse.oneTxt' | translate}}
                          </p>
                      </div>
                  </div>
                  <div class="accordianBlock">
                      <h3 id="b1">{{'termsOfUse.two' | translate}}</h3>
                      <div class="accord-dis">
                          <p>
                              {{'termsOfUse.twoTxt' | translate}}
                          </p>
                      </div>
                  </div>
                  <div class="accordianBlock">
                      <h3 id="c1">{{'termsOfUse.three' | translate}}</h3>
                      <div class="accord-dis">
                          <p>
                              {{'termsOfUse.threeTxt' | translate}}
                          </p>
                      </div>
                  </div>
                  <div class="accordianBlock">
                      <h3 id="d1">{{'termsOfUse.four' | translate}}</h3>
                      <div class="accord-dis">
                          <p>
                              {{'termsOfUse.fourTxt' | translate}}
                          </p>
                      </div>
                  </div>
                  <div class="accordianBlock">
                      <h3 id="e1" #E1>{{'termsOfUse.five' | translate}}</h3>
                      <div class="accord-dis">
                          <p>
                              {{'termsOfUse.fiveTxt' | translate}}
                          </p>
                      </div>
                  </div>
                  <div class="accordianBlock">
                      <h3 id="f1" #F1>{{'termsOfUse.six' | translate}}</h3>
                      <div class="accord-dis">
                          <p>
                              {{'termsOfUse.sixTxt' | translate}}
                          </p>
                      </div>
                  </div>
                  <div class="accordianBlock">
                      <h3 id="g1" #G1>{{'termsOfUse.seven' | translate}}</h3>
                      <div class="accord-dis">
                          <p>
                              {{'termsOfUse.sevenTxt' | translate}}
                          </p>
                      </div>
                  </div>
                  <div class="accordianBlock">
                      <h3 id="h1" #H1>{{'termsOfUse.eight' | translate}}</h3>
                      <div class="accord-dis">
                          <p>
                              {{'termsOfUse.eightTxt' | translate}}
                          </p>
                      </div>
                  </div>

                  <div class="accordianBlock">
                      <h3 id="i1" #I1>{{'termsOfUse.nine' | translate}}</h3>
                      <div class="accord-dis">
                          <p>
                              {{'termsOfUse.nineTxt' | translate}}
                          </p>
                      </div>
                  </div>
                  <div class="accordianBlock">
                      <h3 id="l1" #L1>{{'termsOfUse.ten' | translate}}</h3>
                      <div class="accord-dis">
                          <p>
                              {{'termsOfUse.textTxt' | translate}}
                          </p>
                      </div>
                  </div>
                  <div class="accordianBlock">
                      <h3 id="m1" #M1>{{'termsOfUse.eleven' | translate}}</h3>
                      <div class="accord-dis">
                          <p>
                              {{'termsOfUse.elevenTxt' | translate}}
                          </p>
                      </div>
                  </div>
                  <div class="accordianBlock">
                      <h3 id="n1" #N1>{{'termsOfUse.tweleve' | translate}}</h3>
                      <div class="accord-dis">
                          <p>
                              {{'termsOfUse.tweleveTxt' | translate}}
                          </p>
                      </div>
                  </div>
                  <div class="accordianBlock">
                      <h3 id="o1" #O1>{{'termsOfUse.thirteen' | translate}}</h3>
                      <div class="accord-dis">
                          <div>
                              {{'termsOfUse.thirteenTxt' | translate}}

                              <ul>
                                  <li>
                                      {{'termsOfUse.thirteenTxtSub1' | translate}}
                                  </li>
                                  <li>
                                      {{'termsOfUse.thirteenTxtSub2' | translate}}
                                  </li>
                                  <li>
                                      {{'termsOfUse.thirteenTxtSub3' | translate}}
                                  </li>
                                  <li>
                                      {{'termsOfUse.thirteenTxtSub4' | translate}}
                                  </li>
                              </ul>

                              {{'termsOfUse.thirteenTxtSub5' | translate}}
                          </div>
                      </div>
                  </div>

                  <div class="accordianBlock">
                      <h3 id="p1" #P1>{{'termsOfUse.fourteen' | translate}}</h3>
                      <div class="accord-dis">
                          <p>
                              {{'termsOfUse.fourteenTxt' | translate}}
                          </p>
                      </div>
                  </div>
                  <div class="accordianBlock">
                      <h3 id="q1" #Q1>{{'termsOfUse.fivteen' | translate}}</h3>
                      <div class="accord-dis">
                          <p>
                              {{'termsOfUse.fivteenTxt' | translate}}
                          </p>
                      </div>
                  </div>

                  <div class="accordianBlock">
                      <h3 id="j1" #J1>{{'termsOfUse.sixteen' | translate}}</h3>
                      <div class="accord-dis">
                          <p>
                              {{'termsOfUse.sixteenTxt' | translate}}
                          </p>
                      </div>
                  </div>
                  <div class="accordianBlock">
                      <h3 id="r1" #R1>{{'termsOfUse.sevnteen' | translate}}</h3>
                      <div class="accord-dis">
                          <p>
                              {{'termsOfUse.seventeenTxt' | translate}}
                          </p>
                      </div>
                  </div>

                  <div class="accordianBlock">
                      <h3 id="s1" #S1>{{'termsOfUse.eighteen' | translate}}</h3>
                      <div class="accord-dis">
                          <p>
                              {{'termsOfUse.eighteenTxt' | translate}}
                          </p>
                      </div>
                  </div>

                  <div class="accordianBlock">
                      <h3 id="t1" #T1>{{'termsOfUse.ninteen' | translate}}</h3>
                      <div class="accord-dis">
                          <p>
                              {{'termsOfUse.ninteenTxt' | translate}}
                          </p>
                      </div>
                  </div>

                  <div class="accordianBlock">
                      <h3 id="w1" #W1>{{'termsOfUse.twenty' | translate}}</h3>
                      <div class="accord-dis">
                          <p>
                              {{'termsOfUse.twentyTxt' | translate}}
                          </p>
                      </div>
                  </div>
                  <div class="accordianBlock">
                      <h3 id="x1" #X1>{{'termsOfUse.twentyOne' | translate}}</h3>
                      <div class="accord-dis">
                          <p>
                              {{'termsOfUse.twentyOneTxt' | translate}}
                          </p>
                      </div>
                  </div>

                  <div class="accordianBlock">
                      <h3 id="y1" #Y1>{{'termsOfUse.twentyTwo' | translate}}</h3>
                      <div class="accord-dis">
                          <p>
                              {{'termsOfUse.twentyTwoTxt' | translate}}
                          </p>
                      </div>
                  </div>
                  <div class="accordianBlock">
                      <h3 id="a2" #A2>{{'termsOfUse.twentyThree' | translate}}</h3>
                      <div class="accord-dis">
                          <p>
                              {{'termsOfUse.twentyThreeTxt' | translate}}
                          </p>
                      </div>
                  </div>

                  <div class="accordianBlock">
                      <h3 id="b2" #B2>{{'termsOfUse.twentyFour' | translate}}</h3>
                      <div class="accord-dis">
                          <p>
                              {{'termsOfUse.twentyFourTxt' | translate}}
                          </p>
                      </div>
                  </div>

                  <div class="accordianBlock">
                      <h3 id="c2" #C2>{{'termsOfUse.twnetyFive' | translate}}</h3>
                      <div class="accord-dis">
                          <p>
                              {{'termsOfUse.twentyFiveTxt' | translate}}
                          </p>
                      </div>
                  </div>

                  <div class="accordianBlock">
                      <h3 id="d2" #D2>{{'termsOfUse.twentySix' | translate}}</h3>
                      <div class="accord-dis">
                          <p>
                              {{'termsOfUse.twentySixTxt' | translate}}
                          </p>
                      </div>
                  </div>
                  <div class="accordianBlock">
                      <h3 id="e2" #E2>{{'termsOfUse.twentySeven' | translate}}</h3>
                      <div class="accord-dis">
                          <p>
                              {{'termsOfUse.twentySevenTxt' | translate}}
                          </p>
                      </div>
                  </div>

                  <div class="accordianBlock">
                      <h3 id="f2" #F2>{{'termsOfUse.twentyEight' | translate}}</h3>
                      <div class="accord-dis">
                          <p>
                              {{'termsOfUse.twentyEightTxt' | translate}}
                          </p>
                      </div>
                  </div>
                  <div class="accordianBlock">
                      <h3 id="g2" #G2>{{'termsOfUse.twentyNine' | translate}}</h3>
                      <div class="accord-dis">
                          <p>
                              {{'termsOfUse.twentyNineTxt' | translate}}
                          </p>
                      </div>
                  </div>
                  <div class="accordianBlock">
                      <h3 id="h2" #H2>{{'termsOfUse.thirty' | translate}}</h3>
                      <div class="accord-dis">
                          <p>
                              {{'termsOfUse.thirtyTxt' | translate}}
                          </p>
                      </div>
                  </div>

                  <div class="accordianBlock">
                      <h3 id="i2" #I2>{{'termsOfUse.thirtyOne' | translate}}</h3>
                      <div class="accord-dis">
                          <p>
                              {{'termsOfUse.thirtyOneTxt' | translate}}
                          </p>
                      </div>
                  </div>
                  <div class="accordianBlock">
                      <h3 id="j2" #J2>{{'termsOfUse.thirtyTwo' | translate}}</h3>
                      <div class="accord-dis">
                          <p>
                              {{'termsOfUse.thirtyTwoTxt' | translate}}
                          </p>
                      </div>
                  </div>

                  <div class="accordianBlock">
                      <h3 id="k2" #K2>{{'termsOfUse.thirtyThree' | translate}}</h3>
                      <div class="accord-dis">
                          <p>
                              {{'termsOfUse.thirtyThreeTxt' | translate}}
                          </p>
                      </div>
                  </div>
                  <div class="accordianBlock">
                      <h3 id="l2" #L2>{{'termsOfUse.thirtyFour' | translate}}</h3>
                      <div class="accord-dis">
                          <p>
                              {{'termsOfUse.thirtyFourTxt' | translate}}
                          </p>
                      </div>
                  </div>
                  <div class="accordianBlock">
                      <h3 id="m2" #M2>{{'termsOfUse.thirtyFive' | translate}}</h3>
                      <div class="accord-dis">
                          <p>
                              {{'termsOfUse.thirtyFiveTxt' | translate}}
                          </p>
                      </div>
                  </div>
                  <div class="accordianBlock">
                      <h3 id="n2" #N2>{{'termsOfUse.thirtySix' | translate}}</h3>
                      <div class="accord-dis">
                          <p>
                              {{'termsOfUse.thirtySixTxt' | translate}}
                          </p>
                      </div>
                  </div>

                  <div class="accordianBlock">
                      <h3 id="o2" #O2>{{'termsOfUse.thirtySeven' | translate}}</h3>
                      <div class="accord-dis">
                          <p>
                              {{'termsOfUse.thirtySevenTxt' | translate}}
                          </p>
                      </div>
                  </div>

                  <div class="accordianBlock">
                      <h3 id="p2" #P2>{{'termsOfUse.thirtyEight' | translate}}</h3>
                      <div class="accord-dis">
                          <p>
                              {{'termsOfUse.thirtyEightTxt' | translate}}
                          </p>
                      </div>
                  </div>
                  <div class="accordianBlock">
                      <h3 id="q2" #Q2>{{'termsOfUse.thirtyNine' | translate}}</h3>
                      <div class="accord-dis">
                          <p>
                              {{'termsOfUse.thirtyNineTxt' | translate}}
                          </p>
                      </div>
                  </div>

                  <div class="accordianBlock">
                      <h3 id="r2" #R2>{{'termsOfUse.fourty' | translate}}</h3>
                      <div class="accord-dis">
                          <p>
                              {{'termsOfUse.fourtyTxt' | translate}}
                          </p>
                      </div>
                  </div>
                  <div class="accordianBlock">
                      <h3 id="s2" #S2>{{'termsOfUse.fourtyOne' | translate}}</h3>
                      <div class="accord-dis">
                          <p>
                              {{'termsOfUse.fourtyOneTxt' | translate}}
                          </p>
                      </div>
                  </div>
                  <div class="accordianBlock">
                      <h3 id="t2" #T2>{{'termsOfUse.fourtyTwo' | translate}}</h3>
                      <div class="accord-dis">
                          <p>
                              {{'termsOfUse.fourtyTwoTxt' | translate}}
                          </p>
                      </div>
                  </div>
                  <div class="accordianBlock">
                      <h3 id="w2" #W2>{{'termsOfUse.fourtyThree' | translate}}</h3>
                      <div class="accord-dis">
                          <p>
                              {{'termsOfUse.fourtyThreeTxt' | translate}}
                          </p>
                      </div>
                  </div>
                  <div class="accordianBlock">
                      <h3 id="x2" #X2> {{'termsOfUse.fourtyFour' | translate}}</h3>
                      <div class="accord-dis">
                          <p>
                              {{'termsOfUse.fourtyFourTxt' | translate}}
                          </p>
                      </div>
                  </div>
                  <div class="accordianBlock">
                      <h3 id="y2" #Y2>{{'termsOfUse.fourtyFive' | translate}}</h3>
                      <div class="accord-dis">
                          <p>
                              {{'termsOfUse.fourtyFiveTxt' | translate}}
                          </p>
                      </div>
                  </div>
                  <div class="accordianBlock">
                      <h3 id="z2" #Z2>{{'termsOfUse.fourtySix' | translate}}</h3>
                      <div class="accord-dis">
                          <p>
                              {{'termsOfUse.fourtySixTxt' | translate}}
                          </p>
                      </div>
                  </div>
                  <div class="accordianBlock">
                      <h3 id="a3" #A3> {{'termsOfUse.fourtySeven' | translate}}</h3>
                      <div class="accord-dis">
                          <p>
                              {{'termsOfUse.fourtySevenTxt' | translate}}
                          </p>
                      </div>
                  </div>
                  <div class="accordianBlock">
                      <h3 id="b3" #b3>{{'termsOfUse.fourtyEight' | translate}}</h3>
                      <div class="accord-dis">
                          <p>
                              {{'termsOfUse.fourtyEightTxt' | translate}}
                          </p>
                      </div>
                  </div>
                  <div class="accordianBlock">
                      <h3 id="c3" #C3>{{'termsOfUse.fourtyNine' | translate}}</h3>
                      <div class="accord-dis">
                          <p>
                              {{'termsOfUse.fourtyNineTxt' | translate}}
                          </p>
                      </div>
                  </div>

                  <div class="accordianBlock">
                      <h3 id="d3" #D3>{{'termsOfUse.fifty' | translate}}</h3>
                      <div class="accord-dis">
                          <p>
                              {{'termsOfUse.fiftyTxt' | translate}}
                          </p>
                      </div>
                  </div>
                  <div class="accordianBlock">
                      <h3 id="e3" #E3>{{'termsOfUse.fiftyOne' | translate}}</h3>
                      <div class="accord-dis">
                          <p>
                              {{'termsOfUse.fiftyOneTxt' | translate}}
                          </p>
                      </div>
                  </div>
                  <div class="accordianBlock">
                      <h3 id="f3" #F3>{{'termsOfUse.fiftyTwo' | translate}}</h3>
                      <div class="accord-dis">
                          <p>
                              {{'termsOfUse.fiftyTwoTxt' | translate}}
                          </p>
                      </div>
                  </div>
                  <div class="accordianBlock">
                      <h3 id="g3" #G3>{{'termsOfUse.fiftyThree' | translate}}</h3>
                      <div class="accord-dis">
                          <p>
                              {{'termsOfUse.fiftyThreeTxt' | translate}}
                          </p>
                      </div>
                  </div>
                  <div class="accordianBlock">
                      <h3 id="h3" #H3>{{'termsOfUse.fiftyFour' | translate}}</h3>
                      <div class="accord-dis">
                          <p>
                              {{'termsOfUse.fiftyFourTxt' | translate}}
                          </p>
                      </div>
                  </div>
                  <div class="accordianBlock">
                      <h3 id="i3" #I3>{{'termsOfUse.fiftyFive' | translate}}</h3>
                      <div class="accord-dis">
                          <p>
                              {{'termsOfUse.fiftyFiveTxt' | translate}}
                          </p>
                      </div>
                  </div>
                  <div class="accordianBlock">
                      <h3 id="j3" #J3>{{'termsOfUse.fiftySix' | translate}}</h3>
                      <div class="accord-dis">
                          <p>
                              {{'termsOfUse.fiftySixTxt' | translate}}
                          </p>
                      </div>
                  </div>

                  <div class="accordianBlock">
                      <h3 id="K3" #K3>{{'termsOfUse.fiftySeven' | translate}}</h3>
                      <div class="accord-dis">
                          <p>
                              {{'termsOfUse.fiftySevenTxt' | translate}}
                          </p>
                      </div>
                  </div>
                  <div class="accordianBlock">
                      <h3 id="l3" #L3>{{'termsOfUse.fiftyEight' | translate}}</h3>
                      <div class="accord-dis">
                          <p>
                              {{'termsOfUse.fiftyEightTxt' | translate}}
                          </p>
                      </div>
                  </div>
                  <div class="accordianBlock">
                      <h3 id="m3" #M3>{{'termsOfUse.fiftyNine' | translate}}</h3>
                      <div class="accord-dis">
                          <p>
                              {{'termsOfUse.fiftyNineTxt' | translate}}
                          </p>
                      </div>
                  </div>
                  <div class="accordianBlock">
                      <h3 id="n3" #N3>{{'termsOfUse.sixty' | translate}}</h3>
                      <div class="accord-dis">
                          <p>
                              {{'termsOfUse.sixtyTxt' | translate}}
                          </p>
                      </div>
                  </div>
                  <div class="accordianBlock">
                      <h3 id="o3" #O3>{{'termsOfUse.sixtyOne' | translate}}</h3>
                      <div class="accord-dis">
                          <p>
                              {{'termsOfUse.sixtyOneTxt' | translate}}
                          </p>
                      </div>
                  </div>

                  <div class="accordianBlock">
                      <h3 id="p3" #P3>{{'termsOfUse.sixtyTwo' | translate}}</h3>
                      <div class="accord-dis">
                          <p>
                              {{'termsOfUse.sixtyTwoTxt' | translate}}
                          </p>
                      </div>
                  </div>
                  <div class="accordianBlock">
                      <h3 id="q3" #Q3>{{'termsOfUse.sixtyThree' | translate}}</h3>
                      <div class="accord-dis">
                          <p>
                              {{'termsOfUse.sixtyThreeTxt' | translate}}
                          </p>
                      </div>
                  </div>
                  <div class="accordianBlock">
                      <h3 id="r3" #R3>{{'termsOfUse.sixtyFour' | translate}}</h3>
                      <div class="accord-dis">
                          <p>
                              {{'termsOfUse.sixtyFourTxt' | translate}}
                          </p>
                      </div>
                  </div>
                  <div class="accordianBlock">
                      <h3 id="s3" #S3>{{'termsOfUse.sixtyFive' | translate}}</h3>
                      <div class="accord-dis">
                          <p>
                              {{'termsOfUse.sixtyFiveTxt' | translate}}
                          </p>
                      </div>
                  </div>
                  <div class="accordianBlock">
                      <h3 id="t3" #T3>{{'termsOfUse.sixtySix' | translate}}</h3>
                      <div class="accord-dis">
                          <p>
                              {{'termsOfUse.sixtySixTxt' | translate}}
                          </p>
                      </div>
                  </div>
                  <div class="accordianBlock">
                      <h3 id="w4" #W4>{{'termsOfUse.sixtySeven' | translate}}</h3>
                      <div class="accord-dis">
                          <p>
                              {{'termsOfUse.sixtySevenTxt' | translate}}
                          </p>
                      </div>
                  </div>

                  <div class="accordianBlock">
                      <h3 id="x3" #X3>{{'termsOfUse.sixtyEight' | translate}}</h3>
                      <div class="accord-dis">
                          <p>
                              {{'termsOfUse.sixtyEightTxt' | translate}}
                          </p>
                      </div>
                  </div>
                  <div class="accordianBlock">
                      <h3 id="y3" #Y3>{{'termsOfUse.sixtyNine' | translate}}</h3>
                      <div class="accord-dis">
                          <p>
                              {{'termsOfUse.sixtyNineTxt' | translate}}
                          </p>
                      </div>
                  </div>

                  <div class="accordianBlock">
                      <h3 id="z3" #z3>{{'termsOfUse.seventy' | translate}}</h3>
                      <div class="accord-dis">
                          <p>
                              {{'termsOfUse.seventyTxt' | translate}}
                          </p>
                      </div>
                  </div>
                  <div class="accordianBlock">
                      <h3 id="A4" #A4>{{'termsOfUse.seventyOne' | translate}}</h3>
                      <div class="accord-dis">
                          <p>
                              {{'termsOfUse.seventyOneTxt' | translate}}
                          </p>
                      </div>
                  </div>

                  <div class="accordianBlock">
                      <h3 id="b4" #B4>{{'termsOfUse.seventyTwo' | translate}}</h3>
                      <div class="accord-dis">
                          <p>
                              {{'termsOfUse.seventyTwoTxt' | translate}}
                          </p>
                      </div>
                  </div>
                  <div class="accordianBlock">
                      <h3 id="c4" #C4>{{'termsOfUse.seventyThree' | translate}}</h3>
                      <div class="accord-dis">
                          <p>
                              {{'termsOfUse.seventyThreeTxt' | translate}}
                          </p>
                      </div>
                  </div>

                  <div class="accordianBlock">
                      <h3 id="d4" #D4>{{'termsOfUse.seventyFour' | translate}}</h3>
                      <div class="accord-dis">
                          <p>
                              {{'termsOfUse.seventyFourTxt' | translate}}
                          </p>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>



  <app-footer></app-footer>