<div class="topDes">
   <div class="container-fluid">
      <div class="row">
         <div class="col-md-12 text-center mb-5">
            <div class="title">
              <h3>
                {{'topCity.topDestination' | translate }}
              </h3>
            </div>
          </div>
      </div>
         <div class="row ">
               <div class="col-1 next justify-content-center p-0" (click)="prev()">
                  <i class="fas fa-chevron-left"></i>
                  
               </div>
               <div class="col-10 p-0">
                 <div class="row">
                    <div *ngFor="let list of windowlist" [@fadeInOut]  class="col-12 col-md-4 col-lg-3 pl-1 pr-2">
                        <app-destination style="display: inline-block;" [title]='list.title' [imageSrc]='list.imgur' [content]='list.body' [flight]='list.flight' [hotel]='list.hotel'></app-destination>
                    </div>
                 </div>
                  
               </div>
               <div class="col-1 next justify-content-center p-0" (click)="next()">
                  <i class="fas fa-chevron-right"></i>
                  
               </div>
              </div>
   </div>

</div>

