
<div class=" row justify-content-around">
    <div class="col-12 col-md-6 col-lg-3 cont ">
      <a href="#Date" class="chosse" role="img" (click)="topnationalityPicked('Italy')">
        <img src="../../../assets/img/italy-162326.svg" class="d-block w-100" height="100px" alt="..." style="min-width: 199px;">
        <div class="carousel-caption d-none d-md-block">
          <!-- <h5>Italy</h5> -->
        </div>
      </a>
    </div>
    <div class="col-12 col-md-6 col-lg-3 cont ">
      <a href="#Date" class="chosse" role="img" (click)="topnationalityPicked('UNITED STATES OF AMERICA')">
        <img src="../../../assets/img/united-26967.svg" class="d-block w-100" height="100px" alt="..." style="min-width: 199px;">
        <div class="carousel-caption d-none d-md-block">
          <!-- <h5>United States</h5> -->
        </div>
      </a>
    </div>
    <div class="col-12 col-md-6 col-lg-3 cont ">
      <a href="#Date" class="chosse" role="img" (click)="topnationalityPicked('Canada')">
        <img src="../../../assets/img/canada-27003.svg" class="d-block w-100" alt="..." height="100px" style="min-width: 199px;">
        <div class="carousel-caption d-none d-md-block">
          <!-- <h5>Canada</h5> -->
        </div>
      </a>
    </div>
  </div>