import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  engisActive(){
    if(localStorage.getItem("lang")=="en"){
      return true
    }
    else{
      return false
    }
  }
  constructor(public router:Router) { }

  ngOnInit() {
  }
  gotoHomePage(){
    this.router.navigate(['/'])
  }
}
