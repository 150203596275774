<nav class="navbar navbar-expand-lg navbar-light bg-light" style="background-color:white!important;">
    <div class="container-fluid">


        <a class="navbar-brand" routerLink="/"><img src="../../../assets/img/travellii logo.png" alt=" logo" class="img-fluid" width="200"></a>

        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

        <div class=" collapse navbar-collapse main-menu" id="navbarSupportedContent">
            <ul>
                <li class="submenu "><a routerLink="/aboutUs">{{"header.aboutUs"|translate}}</a></li>
                <li class="submenu "><a routerLink="/Contact">{{"header.contact"|translate}}</a></li>
                <li class="submenu "><a routerLink="/termsOfuse">{{"header.TOU"|translate}}</a></li>
                <li class="submenu "><a routerLink="/privacyPolicy">{{"header.PP"|translate}}</a></li>
            </ul>
            <ul class="login">
                <li class="nav-item" style="font-size: 16px; padding-top: 3px;">
                    <a class="nav-link login" routerLink="/profile" *ngIf="logedin">
                        <i class="fa fa-user"></i> {{ "user.profile" | translate }}
                    </a>
                    <a class="nav-link login" routerLink="/login" *ngIf="!logedin">
                        <i class="fa fa-user"></i> {{ "user.login" | translate }}
                    </a>
                </li>
            </ul>
        </div>



    </div>
</nav>