
<div class="about-page">
  <!-- #header -->
  <header id="header">  </header>
  <app-big-headder class="animated fadeInDown" id="navbar"></app-big-headder>
  <!-- #header -->
</div>



<section id="intro" class="clearfix" [ngClass]="{'ar': translate.currentLang== 'ar'}">
  <div class="container d-flex h-100">
    <div class="row justify-content-center align-self-center">
      <div class="col-md-6 intro-info order-md-first order-last">
        <h2>{{'aboutUs.anEasyBook' | translate}}<br /><span>{{'aboutUs.go' | translate}}</span> {{'aboutUs.onlineSoultion'|translate}}</h2>
        <div>
          <a href="" class="btn-get-started scrollto">{{'aboutUs.getStarted' | translate}}</a>
        </div>
      </div>

      <div class="col-md-6 intro-img order-md-last order-first">
        <img src="../../../assets/img/intro-img.svg" alt="" class="img-fluid">
      </div>
    </div>

  </div>
</section><!-- #intro -->
<main id="main" [ngClass]="{'ar': translate.currentLang== 'ar'}">

  <!--==========================
    About Us Section
  ============================-->
  <section id="about" [ngClass]="{'ar': translate.currentLang== 'ar'}">

    <div class="container">
      <div class="row">

        <div class="col-lg-5 col-md-6">
          <div class="about-img">
            <img src="../../../assets/img/about-us-plane.jpg" alt="plane">
          </div>
        </div>

        <div class="col-lg-7 col-md-6">
          <div class="about-content">
            <h2>{{'aboutUs.ourCompany' | translate}}</h2>
            <!-- <h3>It all started with a simple idea.</h3> -->
            <p>{{'aboutUs.ourCompanyTxt' | translate}}</p>
          </div>
        </div>
      </div>
    </div>

  </section><!-- #about -->

 <!--==========================
    Features Section
  ============================-->
  <section id="features" [ngClass]="{'ar': translate.currentLang== 'ar'}">
      <div class="container">
  
        <div class="row feature-item">
          <div class="col-lg-6 wow fadeInUp">
            <img src="../../../assets/img/mission.jpg" class="img-fluid" alt="">
          </div>
          <div class="col-lg-6 wow fadeInUp pt-5 pt-lg-0">
            <h3>{{'aboutUs.ourMission' | translate}}</h3>
            <p>
              {{'aboutUs.ourMissionTxt' | translate}}            </p>
          </div>
        </div>
  
        <div class="row feature-item mt-5 pt-5">
          <div class="col-lg-6 wow fadeInUp order-1 order-lg-2">
            <img src="../../../assets/img/vision.jpg" class="img-fluid" alt="">
          </div>
  
          <div class="col-lg-6 wow fadeInUp pt-4 pt-lg-0 order-2 order-lg-1">
            <h3>{{'aboutUs.ourVision' | translate}}</h3>
            <p>
              {{'aboutUs.ourVisionTxt' | translate}}            </p>
          
          </div>
          
        </div>
        <div class="row feature-item mt-5 pt-5">
            <div class="col-lg-6 wow fadeInUp">
              <img src="../../../assets/img/puzz.jpg" class="img-fluid" alt="">
            </div>
            <div class="col-lg-6 wow fadeInUp pt-5 pt-lg-0">
                <h3>Travellii.com </h3>
                <p>
                  {{'aboutUs.firstLinkTxt' | translate}}                </p>
                <p>
                 <span style='color:#779b3d;'
                 >
                 Travellii.com 
                </span>{{'aboutUs.firstLinkSubTxt' | translate}}
                </p>
           
            </div>
          </div>
      </div>
    </section><!-- #about -->
  
  <!--==========================
    Services Section
  ============================-->
  <section id="services" class="section-bg" [ngClass]="{'ar': translate.currentLang== 'ar'}">
    <div class="container">

      <div class="section-header">
        <h3>{{'aboutUs.services' | translate}}</h3>
        <p>{{'aboutUs.servicesTxt' | translate}}</p>
      </div>

      <div class="row  align-items-center justify-content-center">
        

        <div class="col-md-2 wow bounceInUp " data-wow-duration="1.4s">
          <div class="box">
            <div class="icon" style="background: #779b3d;"><i class="fas fa-plane" style="color:#fff"></i></div>
            <h4 class="title"><a href="">{{'aboutUs.flights' | translate}}</a></h4>
        
          </div>
        </div>
        <div class="col-md-2 wow bounceInUp" data-wow-duration="1.4s">
          <div class="box">
            <div class="icon" style="background: #779b3d;"><i class="fas fa-hotel" style="color:#fff"></i></div>
            <h4 class="title"><a href="">{{'aboutUs.hotels' | translate}}</a></h4>
      
          </div>
        </div>

        <div class="col-md-2 wow bounceInUp" data-wow-delay="0.1s" data-wow-duration="1.4s">
          <div class="box">
            <div class="icon" style="background: #779b3d;"> <i class="fas fa-car" style="color:#fff"></i></div>
            <h4 class="title"><a href="">{{'aboutUs.carRentals' | translate}}</a></h4>
 
          </div>
        </div>
        <div class="col-md-2 wow bounceInUp" data-wow-delay="0.1s" data-wow-duration="1.4s">
          <div class="box">
            <div class="icon" style="background: #779b3d;"> <i class="fas fa-map-marked-alt" style="color:#fff"></i></div>
            <h4 class="title"><a href="">{{'aboutUs.packages' | translate}}</a></h4>

          </div>
        </div>

        <div class="col-md-2 wow bounceInUp" data-wow-delay="0.1s" data-wow-duration="1.4s">
          <div class="box">
            <div class="icon" style="background: #779b3d;"><i class="far fa-futbol" style="color:#fff"></i></div>
            <h4 class="title"><a href="">{{'aboutUs.sport' | translate}}</a></h4>

          </div>
        </div>

  
      </div>

    </div>
  </section><!-- #services -->



</main>
<!-- <app-contact></app-contact> -->
<app-footer></app-footer>