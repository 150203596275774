
    <div class="content">

        <header></header>
        <ng-container *ngIf="Loading">
          <ng-container *ngTemplateOutlet="loadingkst"></ng-container>
        </ng-container>
        <ng-container *ngIf="Failed">
          <ng-container *ngTemplateOutlet="failed"></ng-container>
      </ng-container>
  
  
        <ng-container *ngIf="sucsess">
          <ng-container *ngTemplateOutlet="Sucsess"></ng-container>
        </ng-container>
   <app-footer></app-footer>
      </div>







      <ng-template #Sucsess>
          <div class="container">
  
            <!-- confirmation - title  -->
            <div class="page-header">
              <div class="row">
                  <div class="col-12 col-sm-6">
                      <h2 class="page-header-title" >
                        <div class="pg-hdr-title-txt"><span>
                           <!-- Your Booking is confirmed   -->
                           {{bookingHotel.status}}
                          </span></div>
                        </h2>
                   
                  </div>
     
                </div>
          </div>
     <!-- confirmation - title  -->
          <div class="confirmation-container">
            <div class="row">
                 <!-- hotel-info  -->
                 <!-- name-rating-checkin-checkout-passengersNo-guest email -->
                 <div class="col-12 col-md-8">
                    <!-- <ngx-gallery [options]="galleryOptions" [images]="galleryImages"></ngx-gallery> -->
                    <div class="row hotelthumbRow">
                      <div class="col-12 col-md-4">
                          <div class="hotel-thumb">
                            <img  [src]="bookingHotel.hotel.hotelThumb" />
                        </div>
                      </div>
                      <div class="col-12 col-md-4">
                        <div class="hotel-details">
                                                <!-- <div class="rating" *ngIf="hotelData"> -->
                                                    <div class="rating">
                                                        <!-- <ng-container *ngFor="let star of getRating()"> -->
                                                            <ng-container *ngFor="let star of getRating(bookingHotel.hotel.hotelStars)">
                                                          <span class="fa fa-star checked"></span>                           
                                                        </ng-container>
                                                      </div>         
                                                      <h5 class="card-subtitle  hotel-title">
                                                        <!-- {{ hotelData?.hotelName}} -->
                                                        <!-- ATLANTIS THE PALM -->
                                                        {{bookingHotel.hotel.hotelName}}
                              
                                                      </h5>
                                                      <!-- <p class="card-text hotel-desc"> 
                                                        {{hotelData?.hotelDescription}}
                                                        The ATLANTIS THE PALM is a Beach Resort,Child Friendly,Family Style,Leisure,Resort,Tourist hotel. Located in Palm Jumeirah area. Shopping is accessible by bus/taxi and the nightlife/restaurants are located in the hotel.
                                                      </p> -->
                                                      <div class="hotel-loc">
                                                        <h4><span><i class="fas fa-map-marker-alt"></i></span> <p>{{bookingHotel.hotel.Location}},{{bookingHotel.hotel.City}},{{bookingHotel.hotel.Country}}   </p></h4>
                                                        
                                                      </div>
                        </div>
                        
                      </div>
                      <div class="col-12 col-md-4">
                        <ul class="book-Info">
                          <li class="book-item">
                            <i class="fas fa-calendar-check"placement="top" ngbTooltip="Check In"tooltipClass="my-custom-class"></i>
                             <p>{{"hotelConfirmation.checkIn" |translate}} :<span class="book-item-info">{{getDate(bookingHotel.hotel.CheckIn)}}</span></p>
                            
                          </li>
                          <li class="book-item">
                            <i class="fas fa-sign-out-alt"placement="top" ngbTooltip="Check Out" tooltipClass="my-custom-class"></i>
                             <p>{{"hotelConfirmation.checkOut" |translate}}: <span class="book-item-info">{{getDate(bookingHotel.hotel.CheckOut)}}</span></p>
                            
                          </li>
                
                      
                        </ul>
                  
                      </div>
                    </div>
                
               

        

                     <!-- guest-info -->
                  
                   <div class="info-box guest-info">
                     <h4>{{"hotelConfirmation.guestDetails" |translate}}</h4>
                     
                      <ul class="book-Info">
                          <ng-container *ngFor="let guest of bookingHotel.travellers ">
                          <li class="book-item">
                              
                                  <i class="fas fa-info"></i>     
                                  <p> <span class="title">{{guest.Title}}</span> :<span class="book-item-info">{{guest.FirstName}} {{guest.LastName}} </span></p>
                          
                               
                            </li>
                   
                          </ng-container>
                          <li class="book-item">
                              <i class="fas fa-envelope"></i>
                              <p>{{"hotelConfirmation.email" |translate}} :<span class="book-item-info">{{bookingHotel.mail}}</span></p>
                          </li>
  
                       </ul>
                 
                </div>
                     <!-- guest-info -->
  
                   
  
                 </div>
                 <div class="col-12 col-md-4">
  
                  <!-- bookingNumber - confirmation number -->
                    <div class="info-box">
                        <ul class="list-unstyled">
                          <!-- <li class="booking-number-item">
                              <span class="label">ks number</span>
                              <span class="number btn btn-primary bordered small ">KS22114566789</span>
                          </li> -->
                          <li class="booking-number-item">
                              <span class="label">{{"hotelConfirmation.bookingNumber" |translate}} </span>
                              <span class="btn btn-primary bordered small number">{{bookingHotel.bookingNum}}</span>
                          </li>
                        </ul>
                  
                
                      </div>
                
                  
                  <!-- bookingNumber - confirmation number -->
                   <!-- checkin-out-total guest -->
                   <div class="info-box">
                     <ul class="book-Info">
                       <li class="book-item">
                          <i class="fas fa-calendar-day"></i>
                          <p>{{"hotelConfirmation.checkIn" |translate}} :<span class="book-item-info">{{getDate(bookingHotel.hotel.CheckIn)}}</span></p>
                         
                       </li>
                       <li class="book-item">
                          <i class="fas fa-calendar-day"></i>
                          <p>{{"hotelConfirmation.checkOut" |translate}}: <span class="book-item-info">{{getDate(bookingHotel.hotel.CheckOut)}}</span></p>
                         
                       </li>
                        <li class="book-item">
                            <i class="fas fa-user-friends"></i>
                            <p>{{"hotelConfirmation.guests" |translate}} :<span class="book-item-info">{{getGuestTotal()}}</span></p>
                        </li>
             
                   
                     </ul>
               
                   </div>
                     <!-- checkin-out-total guest -->
           
                   <!-- rooms-name-price total  -->
                   <div class="info-box">
                     
                     
                          <ul class="book-Info">
                            <!-- loop room -->
                            <ng-container *ngFor="let room of bookingHotel.rooms">
                                <li class="book-item">
                                    <span class="roomName">
                                        <i class="fas fa-bed"></i>
                                        <span class="">{{room.RoomType}}</span>
                                    </span>
                                    <!-- adult-child no -->
                                    <p class="gust-no">
                                        <span class="adultNo">
                                            <b>{{room.Adult}} </b> {{"hotelConfirmation.Adults" |translate}}
                                          </span>
                                          <span class="childNo">
                                              <b>{{room.Child}} </b>{{"hotelConfirmation.child" |translate}}
                                            </span>
                                    </p>
                       
                                  <!-- adult-child no -->
                             
                             
                                   
                                      <button mat-raised-button color="accent" class="price-btn">
                              
                                             <span *ngIf="room.IsRefundable" style="color: chartreuse">
                                             <small>{{"hotelConfirmation.Refundable" |translate}}</small> 
                                           </span>
                                           <span *ngIf="!room.IsRefundable" style="color: tomato">
                                             <small> {{"hotelConfirmation.nonRefundable" |translate}}</small> 
                                           </span>                                             
                                      </button>
                               
                                      
                                  </li>

                            </ng-container>
         
                          <!-- loop room -->
                          </ul>           
                           <!--total price -->
                           <div class="total">
                             <div class="row total-price">
                               <div class="col">
                                 <span class="">{{"hotelConfirmation.TotalPrice" |translate}} </span>
                               </div>
                               <div class="col">
                                  <span class="total-cost"> {{bookingHotel.hotel.TotalSellPrice|exchange:'value'}}</span><span class="Curr">{{bookingHotel.hotel.sellCurrency|exchange:'code'}}</span>
                               </div>
                             </div>
                          </div>
                        
                            <!--total price -->
                    </div>
                 <!-- rooms-name-price total  -->
  
       
                  </div>
  
  
  
            </div>
  
            </div>
  <!-- cost-summery  -->
  <!-- bookingNumber  -->
  <!-- hgNumber  -->
  
          </div>
  
  
          
          
        
        </ng-template>
  
  
  <ng-template #failed>
          <!-- <div class="container ">
                  <div class="row justify-content-center loadingcard">
                      <div class="col text-center">
                              <h1 class="Title "> Sorry, Something went wrong please Try again </h1>
                      </div>
                      <div class="col-12 text-center MoreDetBtn">
                         <button type="button" class="btn btn-outline-primary  moreResult " style="max-width:25%;" [routerLink]="['']">New search</button>
                      </div>
                  </div>
          
              </div> -->
              <app-normalerror [normalError]='normalError' ></app-normalerror>
  </ng-template>


  
  
  <ng-template #loadingkst>
    <div class="preloader">
        <div class="sk-circle">
            <div class="sk-circle1 sk-child"></div>
            <div class="sk-circle2 sk-child"></div>
            <div class="sk-circle3 sk-child"></div>
            <div class="sk-circle4 sk-child"></div>
            <div class="sk-circle5 sk-child"></div>
            <div class="sk-circle6 sk-child"></div>
            <div class="sk-circle7 sk-child"></div>
            <div class="sk-circle8 sk-child"></div>
            <div class="sk-circle9 sk-child"></div>
            <div class="sk-circle10 sk-child"></div>
            <div class="sk-circle11 sk-child"></div>
            <div class="sk-circle12 sk-child"></div>
          </div>
    </div>
  
  </ng-template> 


























<!-- 

<div class="container" *ngIf="sucsess">
    <div class="row justify-content-center">
      <div class="col-12 col-md-6">
        <h2 class="title"> Congratulations your booking is confirmed</h2>
      </div>
      <div class="col-12 col-md-12">
      </div>
    </div>
  </div>

<div class="container" *ngIf="Loading">
  <div class="row justify-content-center">
    <div class="col-12 col-md-6">
      <h2 class="title animationLoading"> Your payment is under processing </h2>
    </div>
    <div class="col-12 col-md-12">
      <ng-container *ngIf="Failed">
        <ng-container *ngTemplateOutlet="failed"></ng-container>
      </ng-container>
      <ng-container *ngIf="Loading">
        <ng-container *ngTemplateOutlet="loadingkst"></ng-container>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #loadingkst>
    <div class="preloader">
      
      <div class="sk-circle">
        <div class="sk-circle1 sk-child"></div>
        <div class="sk-circle2 sk-child"></div>
        <div class="sk-circle3 sk-child"></div>
        <div class="sk-circle4 sk-child"></div>
        <div class="sk-circle5 sk-child"></div>
        <div class="sk-circle6 sk-child"></div>
        <div class="sk-circle7 sk-child"></div>
        <div class="sk-circle8 sk-child"></div>
        <div class="sk-circle9 sk-child"></div>
        <div class="sk-circle10 sk-child"></div>
        <div class="sk-circle11 sk-child"></div>
        <div class="sk-circle12 sk-child"></div>
      </div>
    </div>
  
  </ng-template> -->