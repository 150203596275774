<div class="container">
    <mat-accordion *ngIf="!loading">
        <mat-expansion-panel *ngIf="upcomingl > 0 ">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Up coming
                </mat-panel-title>
                <mat-panel-description>
                    you have {{upcomingl}} upcoming trip
                </mat-panel-description>
            </mat-expansion-panel-header>
            <ng-container *ngIf="upcomingl > 0">
                <ng-container *ngTemplateOutlet="Upcoming"></ng-container>
            </ng-container>

        </mat-expansion-panel>
        <mat-expansion-panel *ngIf="historyl > 0">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{'user.historyH'|translate}}
                </mat-panel-title>
                <mat-panel-description>
                    {{'user.youhave'|translate}} {{historyl}} {{'user.ofhistory'|translate}}
                </mat-panel-description>
            </mat-expansion-panel-header>
            <ng-container *ngIf="historyl > 0">
                <ng-container *ngTemplateOutlet="History"></ng-container>
            </ng-container>
        </mat-expansion-panel>
    </mat-accordion>
    <ng-container *ngIf="loading">
        <ng-container *ngTemplateOutlet="loadingkst"></ng-container>
    </ng-container>
</div>
<h2 class="text-center no-result" *ngIf="historyl == 0 && upcomingl == 0 && !loading"> you dont have any reservation </h2>
<ng-template #Upcoming>
    <ng-container *ngFor="let item of upcoming">
        <div class="card mt-2">

            <div class="card-body">
                <h4>{{'user.BookingStatus'|translate}} : {{item.status}}</h4>
                <h4>{{'user.BookingNo'|translate}} : {{item.bookingNum}}</h4>
                <div class="row hotel">

                </div>

            </div>


        </div>
    </ng-container>
</ng-template>
<ng-template #History>
    <ng-container *ngFor="let item of history">
        <div class="card mt-2">

            <div class="card-body">
                <h4>{{'user.BookingStatus'|translate}} : {{item.status}}</h4>
                <h4>{{'user.BookingNo'|translate}} : {{item.bookingNum}}</h4>
                <div class="row hotel">
                    <div class="hotel-item">
                        <div class="row">
                            <div class="col-12 col-md-4">
                                <!-- hotel-gallery  -->
                                <div class="hotelGallery">
                                    <div class="single">
                                        <div class="galley-img">
                                            <img class="img-fluid" [defaultImage]="defaultImage" width="100%" [lazyLoad]="item['hotel']['hotelThumb'] " />
                                        </div>
                                    </div>

                                </div>

                                <!-- hotel-gallery  -->
                            </div>
                            <!-- main-details -->
                            <div class="col-12 col-md-8">

                                <div class="row">
                                    <div class="col-12 col-md-8">
                                        <div class="Hotel-title">
                                            <!-- hotel name -->
                                            <h2> {{item.hotel.hotelName}}</h2>
                                            <p class="hotel-loc">
                                                <!-- city && country  -->
                                                <!-- 1115 Corniche El Nile, Cairo 12334  -->
                                                {{item.hotel.Address}}
                                            </p>
                                            <p class="date">
                                                {{item.hotel.CheckIn}} to {{item.hotel.CheckOut}}
                                            </p>

                                        </div>

                                    </div>

                                </div>
                                <hr>
                                <!-- rating & price  -->
                                <div class="row">
                                    <div class="col-12 col-sm-6">
                                        <div class="price">
                                            <!-- hotel price  -->

                                            <h4>
                                                <span>
                       <!-- sellCurrency -->
                        {{item.hotel.sellCurrency|exchange:'code'}} 
                        <!-- $ -->
                      </span>
                                                <!-- TotalSellPrice -->
                                                {{item.hotel.TotalSellPrice|exchange:'value' }}
                                                <!-- 263.99 -->
                                            </h4>
                                        </div>

                                    </div>
                                    <div class="col-12 col-sm-6">

                                        <div class="rating">
                                            <!--looping-stars -->
                                            <!-- <ng-container *ngFor="let star of getRating(hotel.hotelStars)">
                        <i class="fa fa-star"></i>
                     </ng-container> -->
                                            <!--looping-stars -->

                                        </div>

                                    </div>

                                </div>

                                <!-- rating & price  -->

                            </div>
                            <!-- main-details -->


                        </div>

                    </div>
                </div>


            </div>


        </div>
    </ng-container>
</ng-template>
<ng-template #loadingkst>
    <div class="preloader">

        <div class="sk-circle">
            <div class="sk-circle1 sk-child"></div>
            <div class="sk-circle2 sk-child"></div>
            <div class="sk-circle3 sk-child"></div>
            <div class="sk-circle4 sk-child"></div>
            <div class="sk-circle5 sk-child"></div>
            <div class="sk-circle6 sk-child"></div>
            <div class="sk-circle7 sk-child"></div>
            <div class="sk-circle8 sk-child"></div>
            <div class="sk-circle9 sk-child"></div>
            <div class="sk-circle10 sk-child"></div>
            <div class="sk-circle11 sk-child"></div>
            <div class="sk-circle12 sk-child"></div>
        </div>
    </div>

</ng-template>