import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpParams
} from '@angular/common/http';
import { MyApiService } from '../Services/my-api.service';
@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  constructor(private myapi:MyApiService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const token: string = sessionStorage.getItem('cls');
    if (token && req.url.includes(this.myapi.users)||req.url.includes(this.myapi.admin)) {
      console.log(token);
      req = req.clone({ headers: req.headers.set('Authorization', 'Bearer ' + token) });
      return next.handle(req);
    } else {
      return next.handle(req);
    }
  }
}
