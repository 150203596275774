<app-payment-erorr [paymentError]="paymentError" *ngIf="paymentErrorStaus"></app-payment-erorr>
<app-normalerror [normalError]='normalError' *ngIf="normalErrorStatus"></app-normalerror>
<div class="container" *ngIf="Loading">
<div class="row justify-content-center">
<div class="col-12 col-md-6">
  <h2 class="title animationLoading">  Your payment is under processing </h2>
</div>
<div class="col-12 col-md-12">
    <ng-container *ngIf="Failed">
        <ng-container *ngTemplateOutlet="failed"></ng-container>
      </ng-container>
    <ng-container *ngIf="Loading">
        <ng-container *ngTemplateOutlet="loadingkst"></ng-container>
      </ng-container>
</div>
</div>
</div>
<ng-template #failed>
  <div class="container ">
          <div class="row justify-content-center loadingcard">
              <div class="col text-center">
                      <h1 class="Title "> Sorry, Something went wrong please Try again </h1>
              </div>
              <div class="col-12 text-center MoreDetBtn">
                 <button type="button" class="btn btn-outline-primary  moreResult " style="max-width:25%;"   id='faieldPayment'>New search</button>
              </div>
          </div>
  
      </div>
</ng-template>

<ng-template #loadingkst>
  <div class="preloader">
    
    <div class="sk-circle">
      <div class="sk-circle1 sk-child"></div>
      <div class="sk-circle2 sk-child"></div>
      <div class="sk-circle3 sk-child"></div>
      <div class="sk-circle4 sk-child"></div>
      <div class="sk-circle5 sk-child"></div>
      <div class="sk-circle6 sk-child"></div>
      <div class="sk-circle7 sk-child"></div>
      <div class="sk-circle8 sk-child"></div>
      <div class="sk-circle9 sk-child"></div>
      <div class="sk-circle10 sk-child"></div>
      <div class="sk-circle11 sk-child"></div>
      <div class="sk-circle12 sk-child"></div>
    </div>
  </div>

</ng-template>