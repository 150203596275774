import { CitiesModule } from 'src/app/models/cities/cities.module';
import { HotelsCitiesModule } from './../../models/hotels-cities/hotels-cities.module';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-hotel-top-dist',
  templateUrl: './hotel-top-dist.component.html',
  styleUrls: ['./hotel-top-dist.component.css']
})
export class HotelTopDistComponent implements OnInit {
  // @Input() Isflight:boolean= false;
  @Input('isflight') Isflight: boolean;
  dubai:any;
  paris:any;
  pavaria:any;
  cairo:any;
  kuwait:any;
  marakesh:any
  @Output() hcity:EventEmitter<HotelsCitiesModule> = new EventEmitter();
  @Output() city:EventEmitter<string> = new EventEmitter();


  constructor() { }

  ngOnInit() {
    if(this.Isflight){
        this.dubai = 'Dubai-Dubai International Airport-DXB';
        this.paris="Paris-Ch. De Gaulle-CDG";
        // this.pavaria="BAVARIA";
        this.pavaria="Hamburg-Fuhlsbuettel Airport-HAM";
        this.cairo="Cairo-Cairo International Airport-CAI";
        this.kuwait="Kuwait-Kuwait International Airport -KWI";
        // this.marakesh="MARRAKECH";
        this.marakesh="Casablanca-Mohamed V Airport-CMN";
    }
    else{
      this.dubai={
        CityId: 6125,
        City: 'DUBAI',
        Country: 'UNITED ARAB EMIRATES',
        CityWithCountry: 'DUBAI,UNITED ARAB EMIRATES'
    };
    this.paris={
      CityId: 18745,
       City: 'PARIS',
       Country: 'FRANCE',
       CityWithCountry: 'PARIS,FRANCE'
   };
  //  this.pavaria=
  //  {
  //   CityId: 1150,
  //   City: 'BAVARIA',
  //   Country: 'GERMANY',
  //   CityWithCountry: 'BAVARIA,GERMANY' };
  this.pavaria=
  {
       "CityId": 10131,
       "City": "HAMBURG",
       "Country": "GERMANY",
       "CityWithCountry": "HAMBURG,GERMANY"
   };
    this.cairo= {
      "CityId": 2865,
      "City": "CAIRO",
      "Country": "EGYPT",
      "CityWithCountry": "CAIRO,EGYPT"
  };
    this.kuwait=   {
      "CityId": 13006,
      "City": "KUWAIT",
      "Country": "KUWAIT",
      "CityWithCountry": "KUWAIT,KUWAIT"
  };
  //   this.marakesh= {
  //     "CityId": 15396,
  //     "City": "MARRAKECH",
  //     "Country": "MOROCCO",
  //     "CityWithCountry": "MARRAKECH,MOROCCO"
  // };
  this.marakesh=    {
    "CityId": 3486,
    "City": "CASABLANCA",
    "Country": "MOROCCO",
    "CityWithCountry": "CASABLANCA,MOROCCO"
};

    }
  }
//  return selected city
  cityPick (cityName){
    if(this.Isflight)
    {
      this.city.emit(cityName);
    }
    else
    {
      this.hcity.emit(cityName);
    }

  }

  // FUNCTION TO CHECK INPUT  IF  HOTEL OR FLIGHT 


}
