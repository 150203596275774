import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-top-nationalities',
  templateUrl: './top-nationalities.component.html',
  styleUrls: ['./top-nationalities.component.css']
})
export class TopNationalitiesComponent implements OnInit {
  @Output() nationality: EventEmitter<string> = new EventEmitter();
  constructor() { }

  ngOnInit() {

  }
  topnationalityPicked(national: string) {
    this.nationality.emit(national);
  }

}
