<ng-container *ngIf="Loading">
  <ng-container *ngTemplateOutlet="loadingkst"></ng-container>
</ng-container>

<ng-container *ngIf="Failed">
  <ng-container *ngTemplateOutlet="failed"></ng-container>
</ng-container>

<ng-container *ngIf="sucsess">
  <ng-container *ngTemplateOutlet="Trip"></ng-container>

</ng-container>



<app-normalerror [normalError]='normalError' *ngIf="normalErrorStatus"></app-normalerror>

<ng-template #Trip>
  <div id="content" #content>
    <div class="checkOutpage">
      <header [disable]='disabled'></header>
      <h1 class="Title">
        <span>{{"confirmation.confirmMsg"|translate}} </span>
      </h1>
    </div>
    <div class="container ">

      <div class="row confirmation-container">
        <div class="col-12 col-md-12">
          <div class="row mb-md-2">
            <div class="col-12 col-md-4">
              <a [routerLink]="['/']" routerLinkActive="router-link-active" class="logoConfirm"><img
                  src="../../../assets/img/travellii logo.png" alt=""></a>
            </div>
            <div class="col-12 col-md-8 page-t">
              <h3>{{"confirmation.thankMsg"|translate}} </h3>
            </div>
          </div>


        </div>
        <div class=" col-12 col-md-5">
          <div class=" flight-list">
              <h5 class="summary-title">{{"confirmation.bookInfo" |translate}}</h5> 
            <!-- <h3> {{"confirmation.bookInfo" |translate}}</h3> -->
         <div class="" style="position: relative;top: 20px;">
            <div class="price-item">
                <span>   {{"confirmation.confirmNumber"|translate}} </span>
                <span class="price"> 
                    {{confermation.hgNumber}}
                </span>
          
              </div>
              <div class="price-item">
                  <span>   {{"confirmation.bookNumber"|translate}} </span>
                  <span class="price"> 
                      {{confermation.pnr}}
                  </span>
            
                </div>

         </div>
       
          </div>
        </div>
        <div class=" col-12 col-md-5 ">
            <div class="flightPrice">
                <h5 class="summary-title">{{"checkout.TripCostSummarry"|translate}}</h5> 
                <div class="price-item">
                    <span>   {{"checkout.Fare"|translate}} </span>
                    <span class="price"> 
                        {{flight.itinTotalFare.amount-flight.itinTotalFare.totalTaxes|number:'1.2-3'}}
                         <b class="currency">   {{flight.itinTotalFare.currencyCode}}</b>
                    </span>
                 
        
                  </div>
        
              <div class="price-item">
                  <span>   {{"checkout.Taxes"|translate}} </span>
                  <span class="price"> 
                      {{flight.itinTotalFare.totalTaxes}}
                        <b class="currency"> {{flight.itinTotalFare.currencyCode}}</b>
                  </span>
                
    
                </div>
                <div class="price-item total-price">
                    <span>   {{"checkout.Total"|translate}} </span>
                    <span class="price"> 
                        {{flight.itinTotalFare.amount}}
                          <b class="currency"> {{flight.itinTotalFare.currencyCode}}</b>
                    </span>
                  
    
                  </div>
            </div>
       




        </div>





        <div class=" col-12 col-md-10">
          <div class="flight-list">
            <h3>
          {{"confirmation.FlightReview" |translate}}
            </h3>
            <div class="row">
              <div class="col-12 ">
                <app-roundtrip [item]="confermation.airItineraries" [i]='0' [over]='true'
                  *ngIf="flight.flightType == 'Return'"></app-roundtrip>
                <app-one-way [item]="confermation.airItineraries" [i]='0' [over]='true'
                  *ngIf="flight.flightType == 'OneWay'">
                </app-one-way>
                <app-multicity [item]="confermation.airItineraries" [i]='0' [over]='true'
                  *ngIf="flight.flightType != 'OneWay' && flight.flightType != 'Return'"></app-multicity>
              </div>
            </div>
          </div>


        </div>
        <!-- prica sammuarry -->
        <div class=" col-12 col-md-10 ">
          <div class=" flight-list">
            <h3 class="departure-landing">{{"confirmation.gestin"|translate}}  {{confermation.bookingEmail}}</h3>
            <div class="row align-items-center guestDetails" *ngFor="let guest of confermation.passengersDetails">
              <div class="col-6  ">
                <p> 
                  <i class="fas fa-info"></i> 
                  <span class="titlein">{{guest.title}}</span>
                   :<span  class="gestin">{{guest.firstName}} {{guest.lastName}} </span>
                  </p>

              </div>
              <div class="col-6 " *ngIf="guest.ticketNumber && guest.ticketNumber != 0">
                <p><i class="fas fa-ticket-alt"></i> <span class="titlein">ticket number</span>:<span
                    class="gestin">{{guest.ticketNumber}}</span></p>
              </div>
            </div>

          </div>





        </div>


      </div>
    </div>

  </div>

</ng-template>
<ng-template #failed>
  <div class="container ">
    <div class="row justify-content-center loadingcard">
      <div class="col text-center">
        <h1 class="Title "> {{"confirmation.errMsg"|translate}} </h1>
      </div>
      <div class="col-12 text-center MoreDetBtn">
        <button type="button" class="btn btn-outline-primary  moreResult " style="max-width:25%;"
          [routerLink]="['']">{{"confirmation.newsearch"|translate}}</button>
      </div>
    </div>

  </div>
</ng-template>
<ng-template #loadingkst>
  <div class="preloader">
    <div class="sk-circle">
      <div class="sk-circle1 sk-child"></div>
      <div class="sk-circle2 sk-child"></div>
      <div class="sk-circle3 sk-child"></div>
      <div class="sk-circle4 sk-child"></div>
      <div class="sk-circle5 sk-child"></div>
      <div class="sk-circle6 sk-child"></div>
      <div class="sk-circle7 sk-child"></div>
      <div class="sk-circle8 sk-child"></div>
      <div class="sk-circle9 sk-child"></div>
      <div class="sk-circle10 sk-child"></div>
      <div class="sk-circle11 sk-child"></div>
      <div class="sk-circle12 sk-child"></div>
    </div>
  </div>

</ng-template>

<app-footer></app-footer>