<div class="privacy">
    <header class="header"></header>
    <app-big-headder class="animated fadeInDown" id="navbar"></app-big-headder>
</div>

<div class="wraber" [ngClass]="{'ar':this.translate.currentLang == 'ar'}">
  <div class="container">
      <div class="row title">
          <h1 class="col-2">{{'privacy.headerTxt' | translate}}</h1>
      </div>
      <div class="row align-self-center justify-content-center" [ngClass]="{'ar':this.translate.currentLang == 'ar'}">
          <div class="col-xs-12 col-sm-8 col-md-10 align-self-center justify-content-center">
              <div class="boxNewGrid privcy-terms-content hotelTerms">
                  <div class="accordianBlock">
                      <h3>{{'privacy.one' | translate}}</h3>

                      <div class="accord-dis">
                          <p>
                              {{'privacy.oneTxt' | translate}}
                          </p>
                      </div>
                  </div>
                  <div class="accordianBlock">
                      <h3>{{'privacy.two' | translate}}</h3>

                      <div class="accord-dis">
                          <p>
                              {{'privacy.twoTxt' | translate}}
                          </p>
                      </div>
                  </div>
                  <div class="accordianBlock">
                      <h3>{{'privacy.three' | translate}}</h3>

                      <div class="accord-dis">
                          <p>
                              {{'privacy.threeTxt' | translate}}
                          </p>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>



  <app-footer></app-footer>