<div class="flight-search">
  <div class="search-box container">
      <form [formGroup]="searchFlight" class="SearchForm " (ngSubmit)="onSubmit()" >
  <!-- flight-type -->
  <div class="reserv-type  row">

      <div class="col-12 col-md-9">
          <mat-radio-group aria-label="Select an option" formControlName="flightType" >
              <mat-radio-button value="oneway" class="">{{"searchFlight.oneway"|translate}}</mat-radio-button>
              <mat-radio-button value="RoundTrip">{{"searchFlight.RoundTrip"|translate}}</mat-radio-button>
              <mat-radio-button value="Multicity">{{"searchFlight.multi"|translate}}</mat-radio-button>
            </mat-radio-group>
          
       
        </div>

              <div class="col-12 col-md-3">
        <!-- <h4 class="title">flights</h4> -->
        <mat-checkbox class="" formControlName="Direct">{{"searchFlight.dirc"|translate}}</mat-checkbox>
      </div>
    </div>
    <!-- flight-type -->

        <!-- flight-details -->
        <div class="flight-details row">

                    <div class="col-12 col-md-9">
                     <!-- edit without round -->
                     <div class="row">
                       <!-- oneway   -->
                        <ng-container formArrayName="Flights">
                            <ng-container *ngFor="let item of searchFlight.get('Flights')['controls']| slice:0:1; let i= index;">
                              <ng-container [formGroupName]='0'>
                                <!-- departure city  -->
                                  <mat-form-field   class=" col-12 col-md-4" color="primary" appearance="outline"  (click)="mystepper('Step0')">
                                      <input type="text" placeholder='{{"searchFlight.dpart"|translate}}' aria-label="City" matInput  formControlName="departing"
                                        [matAutocomplete]="autoa" #firstD>
                                      <mat-autocomplete #autoa="matAutocomplete">
                                        <mat-option *ngFor="let city of cities|filterCity:item.get('departing').value|limitTo:5" [value]="city.cityName +'-'+ city.airportName +'-'+ city.airportCode">
                                          {{city.airportCode}}-{{city.airportName}}-{{city.cityName}}
                                        </mat-option>
                                      </mat-autocomplete>
                                    </mat-form-field>
                                    <!-- departur city -->
                                    <!-- landing-city -->
                                    <mat-form-field class=" col-12 col-md-4"   color='primary' appearance="outline" (click)="mystepper('Step1')">
                                        <input type="text" placeholder='{{"searchFlight.land"|translate}}' aria-label="City" matInput formControlName="landing"
                                          [matAutocomplete]="autob" #firstAr>
                                        <mat-autocomplete #autob="matAutocomplete" >
                                          <mat-option *ngFor="let city of cities|filterCity:item.get('landing').value|limitTo:5" [value]="city.cityName +'-'+ city.airportName +'-'+ city.airportCode"  >
                                            {{city.airportCode}}-{{city.airportName}}-{{city.cityName}}
                                          </mat-option>
                                        </mat-autocomplete>
                                      </mat-form-field>
                              <!-- landing-city -->
                              <!-- departur date  -->
                              <mat-form-field  *ngIf="!multiDest " class="col-12 col-md-2 pr-lg-0" [ngClass]="{'col-md-4': !roundType}"  appearance="outline" color="accent"   (click)="mystepper('Step2')">
                                  <input type="text" placeholder="From" aria-label="Number" matInput readonly [value]='formday|date' id="Date" formControlName='departingD'>
                              </mat-form-field>
                              <!-- departure date  -->
                              <!-- departure date in multi  -->
                              <mat-form-field  *ngIf="multiDest " class=" col-12 col-md-4   datepicker " color='accent' appearance="outline">
                                  <input matInput [matDatepicker]="picker1" placeholder='{{"searchFlight.dpartd"|translate}}'
                                    formControlName='departingD' value="{{value|date:'MMMM dd, y'}}" [min]='todayDate()' disabled
                                    style="display: none" #firstARd>
                                  {{searchFlight.get('Flights')['controls'][0]?.value['departingD']|date:'MMMM dd, y'}}
                                  <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                  <mat-datepicker #picker1 disabled="false"></mat-datepicker>
                                </mat-form-field>
                        <!-- departure date in multi  -->
                            </ng-container>
                          </ng-container>
                       </ng-container>
                         <!-- oneway   -->
                         <!-- roundtrip -->
                               <!-- return date  -->
                              <mat-form-field    class="col-12 col-md-2  pl-lg-0"  [ngClass]="{'col-md-2 ': roundType,'hidden': !roundType }"  appearance="outline" color="accent"  (click)="mystepper('Step2')"  *ngIf="searchFlight.get('flightType').value === 'RoundTrip' ">
                                  <input type="text" placeholder="To" aria-label="Number" matInput readonly [value]="today|date" formControlName='returnDate'>
                                </mat-form-field>
                              <!-- return date  -->
                        <!-- roundtrip -->
                        <!-- multi  -->
                        <ng-container *ngIf="searchFlight.get('flightType').value === 'Multicity' ">
                          <ng-container formArrayName="Flights" class="col-12"
                            *ngIf="searchFlight.get('flightType').value === 'Multicity' ">
                            <ng-container class="form-group"
                              *ngFor="let item of searchFlight.get('Flights')['controls']| slice:1; let i= index;">

                              <div [formGroupName]='indexadd1(i)' class="row multi-flight">
                                <div class="col-12 col-md-4">
                                  <mat-form-field class=" padding0" color='primary' appearance="outline">
                                    <input type="text" placeholder='{{"searchFlight.dpart"|translate}}' aria-label="City" matInput
                                      formControlName="departing" [matAutocomplete]="ii">
                                    <mat-autocomplete #ii="matAutocomplete">
                                      <mat-option *ngFor="let city of cities|filterCity:item.get('departing').value|limitTo:5"
                                        [value]="city.cityName +'-'+ city.airportName +'-'+ city.airportCode">
                                        {{city.airportCode}}-{{city.airportName}}-{{city.cityName}}
                                      </mat-option>
                                    </mat-autocomplete>
                                  </mat-form-field>

                                </div>

                            
                                <div class="col-12 col-md-4">
                                  <mat-form-field class=" padding0" color='primary' appearance="outline">
                                    <input type="text" placeholder='{{"searchFlight.land"|translate}}' aria-label="City" matInput
                                      formControlName="landing" [matAutocomplete]="iii">
                                    <mat-autocomplete #iii="matAutocomplete">
                                      <mat-option *ngFor="let city of cities|filterCity:item.get('landing').value|limitTo:5"
                                        [value]="city.cityName +'-'+ city.airportName +'-'+ city.airportCode">
                                        {{city.airportCode}}-{{city.airportName}}-{{city.cityName}}
                                      </mat-option>
                                    </mat-autocomplete>
                                  </mat-form-field>
                                </div>
                                <div class="col-12 col-md-4">
                         
                                  <mat-form-field class=" DateMulti padding0 mr-2 datepicker " color='primary' appearance="outline">
                                    <input matInput [matDatepicker]="iiii" placeholder='{{"searchFlight.dpartd"|translate}}'
                                      formControlName='departingD'
                                      [min]="searchFlight.get('Flights')['controls'][i].value['departingD']"
                                      
                                      [value]="searchFlight.get('Flights')['controls'][i].value['departingD']"
                                      style="display: none;" #mg>
                                    <span> {{mg.value|date:'MMMM dd, y'}} </span>

                                    <mat-datepicker-toggle matSuffix [for]="iiii"></mat-datepicker-toggle>
                                    <mat-datepicker #iiii></mat-datepicker>
                                  </mat-form-field>
                                </div>

                              </div>

                            </ng-container>

                          </ng-container>

                        </ng-container>
                        <!-- multi  -->
                     </div>

                    </div>
                    <div class="col-12 col-md-3">
                      <div class="row align-items-center justify-content-center  " [ngClass]="{addingbtns: multiDest}">
                        <div class=" col-12" *ngIf="!multiDest">
                            <mat-form-field appearance="outline" color="accent" (click)="mystepper('Step3')" >
                                <mat-label > 
                                  <!-- {{hotelSearch.get('roomN').value}}  -->
                                 {{alltravellerNo}} travellers , {{searchFlight.get('class').value}}
                                  
                                </mat-label> 
                                
                                <input type="text" placeholder=" 0 traveller , Economy " aria-label="Number" matInput readonly id="traveller">
                              </mat-form-field>
                        </div>
                        <div *ngIf="searchFlight.get('flightType').value === 'Multicity' " >
                    
                          
                                <button type="button" class="addbtn" mat-fab color="accent"  (click)='onAddFlight()' *ngIf="searchFlight.get('Flights').length <4">
                                    <img src="../../../assets/img/plus-solid.svg" alt="">
                                    <!-- {{"searchFlight.add"|translate}} -->
                                  </button>
                             <br>
                                <button type="button" class="addbtn" mat-fab color="accent"  (click)='removeflight()'  *ngIf="searchFlight.get('Flights').length >2" >
                                    <img src="../../../assets/img/minus-solid.svg" alt="">
                                    <!-- {{"searchFlight.remove"|translate}} -->
                                </button>
                         

                          
                        
                              </div>
               
                      </div>
           


                    </div>
  

 


        </div>


    <!-- flight-details -->
      </form>
        

          <!-- pointer -->
          <ng-container  *ngIf="!multiDest">
              <div class="row  animated Pointer flightpointer"  
              [ngClass]="{pos2: step == 'Step2', pos3: step == 'Step3', pos4: step == 'Step4' , pos1: step =='Step1',pos0:step=='Step0'}"  >
                  <p> </p>
              </div>
          </ng-container>

          <!-- pointer -->


  </div>


</div>




  <!-- align-steps-center -->
  <div class="all-steps">

      <!-- Steps call --> 
    <!-- steps in oneway -round -->
   <ng-container *ngIf="!multiDest">
      <!-- to city step  -->
      <ng-container *ngIf="step === 'Step0'|| step==='Step1' ">
        <ng-container *ngTemplateOutlet="step1"></ng-container>
      </ng-container>
      <!-- to city end -->
      <!-- date picker step -->
      <ng-container *ngIf="step === 'Step2'">
        <ng-container *ngTemplateOutlet="step2"></ng-container>
      </ng-container>
  <!-- date picker step end -->
  <!-- traveller - class step  -->
    <ng-container *ngIf="step === 'Step3'">
      <ng-container *ngTemplateOutlet="step3"></ng-container>
    </ng-container>
    <!-- traveller - class step  -->
   </ng-container>
   <!-- steps in oneway -round -->
   <!--  multi destination -->
   <ng-container *ngIf="multiDest">
   
         <ng-container *ngTemplateOutlet="step3"></ng-container>
          
   </ng-container>
   <!--  multi destination -->

    <!-- search button  -->
    <div class="search-btn">
      <div class="container">
          <div class="row justify-content-center">
              <div class="col-4 align-self-center">
                 <button mat-raised-button color="accent" class="searcButton mt-4" (click)="onSubmit()">Search</button>
              </div>
             </div>
      </div>
    </div>
    <!-- search button  -->
  
    </div>



<!-- top destination content start  -->
<ng-template #step1 >
    <div class="container animated slideInUp fast" >
      <div class="row ">
        <div class=" col-12 text-center">
          <h2 class="text-center step-title">{{"searchFlight.pickCites"|translate}}</h2>
        </div>
  
        <div class="col-12">
          <app-hotel-top-dist (city)=pickedFromTop($event) [isflight]='isflight'></app-hotel-top-dist>
        </div>
  
      </div>
    </div>
  </ng-template>
  <!-- top destination content end -->


<!-- inline date picker start -->
<ng-template #step2>
    <ng-container *ngIf="step == 'Step2'">
      <div class="container animated datecalender  "
        [ngClass]="{'slideInUp':step == 'Step2','fast':step == 'Step2','lightSpeedOut': step != 'Step2','faster':step != 'Step2'}">
        <div class="row  justify-content-center">
          <div class="col-12 col-lg-10 text-center">
            <h2 class="step-title">{{"searchFlight.pickDates"|translate}}</h2>
            <ngb-datepicker #dp (select)="onDateSelection($event)" [displayMonths]="2" [dayTemplate]="t"
              outsideDays="hidden"
              [minDate]="currentday">
            </ngb-datepicker>
          </div>
  
  
          <ng-template #t let-date let-focused="focused">
            <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)"
              [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date"
              (mouseleave)="hoveredDate = null" *ngIf="!single">
              {{ date.day }}
            </span>
            <span class="custom-days" [class.focused]="focused" [class.range]="isRange(date)"
              [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date"
              (mouseleave)="hoveredDate = null" *ngIf="single">
              {{ date.day }}
            </span>
          </ng-template>
        </div>
      </div>
    </ng-container>
  
  </ng-template>
  <!-- inline date picker end -->

  <!-- traveller && class -->
  <ng-template #step3>
    <div class="container" [formGroup]="searchFlight">
        <div class="row">
          <div class="col text-center">
              <h2 class="step-title">{{"searchFlight.travelclass"|translate }}</h2>
          </div>
        </div>
    <!-- passenger -class  -->
    <div class="row f-sec f-passenger">
        <ng-container formGroupName="passengers">
          
  
            <!-- <mat-form-field appearance="outline" color='primary'>
              <mat-label>{{"searchFlight.adults"|translate}}</mat-label>
              <input matInput placeholder='{{"searchFlight.adults"|translate}}' value="1" type="number" min="1"
                formControlName="adults">
            </mat-form-field> -->

            <mat-form-field class="col-12 col-md-3 " appearance="outline" color="primary" placeholder="adults"  >
                <mat-label>{{"searchFlight.adults"|translate}} </mat-label>
                <select #adultNum matNativeControl formControlName="adults" placeholder='{{"searchFlight.adults"|translate}}'>
                  <option *ngFor="let num of travellerNo" [value]="num">
                    {{num}}
                  </option>
                </select>
              </mat-form-field>


        
            <!-- <mat-form-field appearance="outline" color='primary'>
              <mat-label>{{"searchFlight.child"|translate}}</mat-label>
              <input matInput placeholder='{{"searchFlight.child"|translate}}' value="0" type="number" min="0"
                formControlName="child">
            </mat-form-field> -->
            <mat-form-field class="col-12 col-md-3 " appearance="outline" color="primary"  placeholder="adults" >
                <mat-label>{{"searchFlight.child"|translate}}</mat-label>
                <select matNativeControl #ChildNum formControlName="child" placeholder='{{"searchFlight.child"|translate}}'  >
                  <option *ngFor="let num of travellerNo" [value]="num">
                    {{num}}
                  </option>
                </select>
            </mat-form-field>

        
     

            <!-- <mat-form-field appearance="outline" color='primary'>
              <mat-label>{{"searchFlight.infent"|translate}}</mat-label>
              <input matInput placeholder='{{"searchFlight.infent"|translate}}' value="0" type="number" min="0"
                formControlName="infent">
            </mat-form-field> -->
            <mat-form-field class="col-12 col-md-3 " appearance="outline" color="primary"  placeholder="adults" >
                <mat-label>{{"searchFlight.infent"|translate}}</mat-label>
                <select matNativeControl #infentN formControlName="infent" placeholder='{{"searchFlight.infent"|translate}}'  >
                  <option *ngFor="let num of travellerNo" [value]="num">
                    {{num}}
                  </option>
                </select>
            </mat-form-field>

        

        </ng-container>
        <div class="col-12 col-md-3">
          <mat-form-field appearance="outline" color='primary'>
            <mat-label>{{"searchFlight.class"|translate}}</mat-label>
            <select matNativeControl class="custom-select" formControlName="class">
              <option value="Economy">
                {{"searchFlight.economy"|translate}}
              </option>
              <option value="Business">
                {{"searchFlight.business"|translate}}
              </option>
              <option value="First">
                {{"searchFlight.first"|translate}}
              </option>
            </select>
          </mat-form-field>
        </div>
      </div>
      <!-- passenger -class  -->
     
      </div>
    </ng-template>
  <!-- traveller && class -->



  <ng-container *ngIf="searchFlight.touched">
      <ng-container *ngTemplateOutlet="alerts">

      </ng-container>
    </ng-container>




     <ng-template #alerts>
        <p *ngIf="!searchFlight.get('passengers.adults').valid && searchFlight.get('passengers.adults').touched ">
          <ngb-alert type="warning"> {{"searchFlight.alert1"|translate}}</ngb-alert>
        </p>
        <p *ngIf="!searchFlight.get('passengers.infent').valid && searchFlight.get('passengers.infent').touched ">
          <ngb-alert type="warning">{{"searchFlight.alert2"|translate}}</ngb-alert>
        </p>
        <ng-container *ngIf="!searchFlight.get('passengers').valid">
          <p *ngIf="searchFlight.get('passengers').errors">
            <ngb-alert type="warning">{{"searchFlight.alert3"|translate}} </ngb-alert>
          </p>
        </ng-container>
        <ng-container *ngFor="let flight of searchFlight.get('Flights')['controls']">
          <p *ngIf="!flight.valid && flight.dirty && flight.errors">
            <ngb-alert type="warning">{{"searchFlight.alert4"|translate}}</ngb-alert>
          </p>
          <p *ngIf="!flight['controls']['departing'].valid && flight['controls']['departing'].dirty">
            <ngb-alert type="warning">{{"searchFlight.alert5"|translate}}</ngb-alert>
          </p>
          <p *ngIf="!flight['controls']['landing'].valid && flight['controls']['landing'].dirty">
            <ngb-alert type="warning">{{"searchFlight.alert6"|translate}}</ngb-alert>
          </p>
        </ng-container>
      </ng-template>
