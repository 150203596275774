<footer id="footer" class="section-bg">
  <div class="footer-top">
    <div class="container">

      <div class="row">

        <div class="col-lg-4">

          <div class="row">

              <div class="col-sm-6">


              </div>

              <div class="col-sm-12">


                <div class="footer-links">
                  <h4>{{"contact.contactUs"|translate}}</h4>
                  <p>
                    {{"contact.address"|translate}} 
                    <br>
                    
                    <strong>{{"contact.phone"|translate}}:</strong> <span style="direction: ltr;unicode-bidi: embed;">+965 22662000</span><br>
                    <strong>{{"contact.email"|translate}}:</strong> info@Travellii.com<br>
                  </p>
                </div>

                <div class="social-links">
                  <a href="https://www.twitter.com" target="blank" class="twitter"><i class="fab fa-twitter"></i></a>
                  <a href="https://www.facebook.com" target="blank" class="facebook"><i class="fab fa-facebook"></i></a>
                  <a href="https://www.instagram.com" target="blank" class="instagram"><i class="fab fa-instagram"></i></a>
                  <a href="https://www.linkedin.com" target="blank" class="linkedin"><i class="fab fa-linkedin"></i></a>
                </div>

              </div>

          </div>

        </div>

        <div class="col-lg-8">

          <div class="form">
            
            <h4>{{"contact.sendMsg"|translate}}</h4>
            <form action="" method="post" role="form" class="contactForm">
              <div class="form-group">
                <input type="text" name="name" class="form-control" id="name" placeholder='{{"contact.name"|translate}}' data-rule="minlen:4" data-msg="Please enter at least 4 chars" />
                <div class="validation"></div>
              </div>
              <div class="form-group">
                <input type="email" class="form-control" name="email" id="email" placeholder='{{"contact.email"|translate}}'  data-rule="email" data-msg="Please enter a valid email" />
                <div class="validation"></div>
              </div>
              <div class="form-group">
                <input type="text" class="form-control" name="subject" id="subject" placeholder='{{"contact.subject"|translate}}' data-rule="minlen:4" data-msg="Please enter at least 8 chars of subject" />
                <div class="validation"></div>
              </div>
              <div class="form-group">
                <textarea class="form-control" name="message" rows="5" data-rule="required" data-msg="Please write something for us" placeholder='{{"contact.msg"|translate}}'></textarea>
                <div class="validation"></div>
              </div>

              <div id="sendmessage">{{"contact.sucess"|translate}}</div>
              <div id="errormessage"></div>

              <div class="text-center"><button type="submit" title="Send Message">{{"contact.send"|translate}}</button></div>
            </form>
          </div>

        </div>

        

      </div>

    </div>
  </div>

 
</footer><!-- #footer -->
