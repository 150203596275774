import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FareRules } from './../../interface/fare-rules';
import { CurrencyModule } from 'src/app/models/currency/currency.module';
import { CurruncyServiceService } from 'src/app/Services/curruncy-service.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { DatePipe } from '@angular/common';
import { MyApiService } from 'src/app/Services/my-api.service';
import { Subscription } from 'rxjs';
import { SearchFlightModule } from './../../models/search-flight/search-flight.module';
import { FilterInputsModule } from './../../models/filter-inputs/filter-inputs.module';
import { FormGroup, FormArray, FormControl } from '@angular/forms';
import { FlightSearchResult } from './../../flight-search-result';
import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, HostListener, Inject, ViewEncapsulation } from '@angular/core';
import { trigger, state, transition, style, animate } from '@angular/animations';
import { DOCUMENT } from '@angular/common';
import { Options } from 'ng5-slider';
import { FlightServiceService } from '../../Services/flight-service.service';

declare var $: any;
interface flightFilter {
  priceMin: number,
  priceMax: number,
  durationMin: number,
  durationMax: number,
  depatingMin: number,
  departingMax: number,
  returnMin: number,
  returnMax: number,
  stops: number[],
  airlines: string[]

}

@Component({
  selector: 'app-flight-result',
  templateUrl: './flight-result.component.html',
  styleUrls: ['./flight-result.component.css', './loader.css']

})
export class FlightResultComponent implements OnInit {
  loading: boolean = true;
  ResultFound: boolean = false;
  // trueT:boolean=true;
  isShowen: boolean = false;
  rate: number = 2;
  code: string = "AED"
  panelOpenState: boolean[] = [false, false, false, false, false];
  response: FlightSearchResult;
  orgnizedResponce: any[] = [];
  resultCard: boolean = false;
  noResultAlert: boolean = false;
  roundT: boolean = false;
  Multi: boolean = false;
  minValue: number = 0
  maxValue: number = 5000
  trueT: boolean[] = [];
  moreT: boolean[] = [];
  airlinesA: any[] = [];
  normalError: string = 'Something went wrong <br> please search again';
  normalErrorStatus: boolean = false;
  filterForm: FormGroup;
  filter: FilterInputsModule;
  activeIds: string[] = ['panel-1', 'panel-2', 'panel-3', 'panel-4', 'panel-5'];
  myapi: SearchFlightModule;
  flightsInfo: string;
  flightType: string = '';
  incomingdata: any;
  lan: string = "";
  sequenceNumber: number[] = [];
  providerKey: string;
  private subscription: Subscription = new Subscription();
  filterUpdated: boolean = false;
  fare: FareRules[];
  serachId: string = '';
  FareLoading: boolean = true;
  @ViewChild('sidenav') sidenav: ElementRef;
  scrollH: number = 177;
  addscroll: boolean = false;


  options3: Options = {
    floor: 0,
    ceil: 2000,
    noSwitching: true,
    translate: (value: number): string => {
      let h = value / 60 | 0;
      let m = value % 60 | 0;
      return h + ":" + m;
    }
  };

  options: Options = {
    noSwitching: true,
    translate: (value: number): string => {
      return this.getcurr() + value;

    }
  };

  optionsdeparting: Options = {
    floor: 0,
    ceil: 2000,
    noSwitching: true,
    translate: (value: number): string => {
      let h = value / 60 | 0;
      let m = value % 60 | 0;
      return h + ":" + m;
    }
  };
  optionsDurathion: Options = {
    floor: 0,
    ceil: 2000,
    noSwitching: true,
    translate: (value: number): string => {
      let h = value / 60 | 0;
      let m = value % 60 | 0;
      return h + ":" + m;
    }
  }
  getcurr() {
    let curr = this.curruncy.currentCurruncy.Currency_Code
    if (curr) {
      return curr
    }
    else {
      return "AED"
    }
  }
  constructor(@Inject(DOCUMENT) document,
    private search: MyApiService,
    private datepipe: DatePipe,
    private router: Router,
    private route: ActivatedRoute,
    private curruncy: CurruncyServiceService,
    private cdRef: ChangeDetectorRef,
    private flight: FlightServiceService
  ) { }
  get myrate() { return this.rate }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    if (window.pageYOffset > 30) {
      let element = document.getElementById('navbar');
      element.classList.add('sticky');
    } else {
      let element = document.getElementById('navbar');
      element.classList.remove('sticky');
    }
  }
  ngAfterViewChecked() {
    // console.log("sidenav",this.sidenav, this.sidenav.opened);
    // this.sidenav.opened;
    this.cdRef.detectChanges();

  }
  ngOnInit() {
    this.curruncy.CurruncyChange.subscribe(
      (result: CurrencyModule) => { this.rate = result.rate; this.code = result.Currency_Code; }
    )
    this.filterForm = new FormGroup({
      airline: new FormGroup({
        airlines: new FormArray([])
      }),
      stopsForm: new FormGroup({
        noStops: new FormControl(false),
        oneStop: new FormControl(false),
        twoAndm: new FormControl(false)
      }),
      priceSlider: new FormControl([30, 1000]),
      durationSlider: new FormControl([30, 700]),
      dpartingSlider: new FormControl([30, 7000]),
      returnSlider: new FormControl([30, 7000])
    });

    //  callling the api from the router to get flight result
    this.subscription.add(this.route.params.subscribe(
      (params: Params) => {
        this.loading = true;
        this.resultCard = false;
        this.lan = localStorage.getItem('lang');
        let Currency = params['currency'];
        let pointOfReservation = params['SearchPoint'];
        this.flightType = params['flightType'];
        this.flightsInfo = params['flightInfo'];
        console.log("flightsInfo", this.flightsInfo);

        this.serachId = params['searchId'];
        this.flight.serachId = this.serachId;
        let passengers = params['passengers'];
        let Cclass = params['Cclass'];
        let showDirect: boolean;

        if (params['directOnly'] == 'false') {
          showDirect = false;
        }
        else {
          showDirect = true;
        }
        let searchApi: SearchFlightModule = new SearchFlightModule(this.lan, Currency, pointOfReservation, this.flightType, this.flightsInfo, passengers, Cclass, this.serachId, showDirect, 'all');
        if (SearchFlightModule) {
          this.myapi = searchApi;
          this.subscription.add(this.search.searchFlight(this.myapi).subscribe(
            (result) => {
              if (result.status == 'Valid') {
                this.loading = false;
                this.ResultFound = true;
                this.response = result;
                this.flight.response = this.response;
                console.log("flightResult", this.response);
                this.incomingdata = this.response.searchCriteria;
                this.fillSequenceArr();
                console.log("searchCritria", this.incomingdata)


              } else {
                if (localStorage.getItem('lang') == 'en') {
                  this.normalError = "No result found. <br> please search again"
                } else {
                  this.normalError = " عفوا لا توجد نتائج . <br>  برجاء البحث مجددا "
                };
                this.normalErrorStatus = true;
                this.loading = false;
                this.ResultFound = false;
              }


            }
          ));
        }

      }));
    // this is the form that control the fillter
    this.subscription.add(this.search.resultUpdate.subscribe(
      (res) => {
        this.response = res;
        console.log("resultUpdate", this.response);
        if (this.response.status != 'Valid') {
          this.loading = false;
          this.resultCard = false;
          this.noResultAlert = true;
          this.roundT = false;
          this.Multi = false;
        }
        else {

          this.filterForm.get('durationSlider').setValue(this.findDurationMinMax(this.response.airItineraries));
          this.filterForm.get('durationSlider').updateValueAndValidity();
          this.filterForm.get('dpartingSlider').setValue(this.findDepartingnMinMax(this.response.airItineraries));
          this.filterForm.get('dpartingSlider').updateValueAndValidity();
          this.filterForm.get('priceSlider').setValue(this.minAnMax());
          this.filterForm.get('priceSlider').updateValueAndValidity();
          // console.log(this.filterForm.get('priceSlider'),"check this out")
          this.options3.floor = this.findDurationMinMax(this.response.airItineraries)[0] - 100;
          this.options3.ceil = this.findDurationMinMax(this.response.airItineraries)[1] + 100;
          this.optionsdeparting.floor = this.findDepartingnMinMax(this.response.airItineraries)[0] - 100;
          this.optionsdeparting.ceil = this.findDepartingnMinMax(this.response.airItineraries)[1] + 100;
          this.removeArrayControllers();
          this.airlinesA = this.response.airlines;
          this.airlinesA.forEach(element => {
            (<FormArray>this.filterForm.get('airline').get('airlines')).push(new FormControl(false));
          });

          // console.log(this.filterForm.get('airline').get('airlines').value);
          if (this.response['searchCriteria']['flightType'] == 'roundtrip') {
            this.resultCard = false;
            this.loading = false;
            this.noResultAlert = false;
            this.roundT = true;
            this.Multi = false;
            this.orgnizedResponce = this.orgnize(this.response.airItineraries);
            this.scrollH = 177 * this.orgnizedResponce.length + 100;
            // this.minAnMax();
            this.valuesoftrue(this.response.airItineraries);
            this.valuesoftrueM(this.orgnizedResponce);
            // console.log(this.trueT);
          }

          else if (this.response['searchCriteria']['flightType'] == 'multicity') {

            this.resultCard = false;
            this.loading = false;
            this.noResultAlert = false;
            this.roundT = false;
            this.Multi = true;
            this.orgnizedResponce = this.orgnize(this.response.airItineraries);
            this.scrollH = 177 * this.orgnizedResponce.length + 100;
            // this.minAnMax();
            this.valuesoftrue(this.response.airItineraries);
            this.valuesoftrueM(this.orgnizedResponce);
            // console.log(this.trueT);
          }
          else {
            this.resultCard = true;
            this.loading = false;
            this.noResultAlert = false;
            this.roundT = false;
            this.Multi = false;
            this.orgnizedResponce = this.orgnize(this.response.airItineraries);
            // this.minAnMax();
            this.valuesoftrue(this.response.airItineraries);
            this.valuesoftrueM(this.orgnizedResponce);
            this.scrollH = 177 * this.orgnizedResponce.length + 100;
            // console.log(this.trueT);
          }

        };

      }
    ));

    // new filter subscribe
    this.subscription.add(this.filterForm.valueChanges.subscribe(
      (val) => {
        let t1 = performance.now();
        if (this.response && !this.loading) {
          // console.log("")

          this.filter = new FilterInputsModule(this.filterForm.get("priceSlider").value[0],
            this.filterForm.get("priceSlider").value[1],
            // new 
            this.filterForm.get('durationSlider').value[0],
            this.filterForm.get('durationSlider').value[1],
            this.filterForm.get('dpartingSlider').value[0],
            this.filterForm.get('dpartingSlider').value[1],
            this.filterForm.get('returnSlider').value[0],
            this.filterForm.get('returnSlider').value[1],
            this.stopsvalues(),
            this.filteringbyairline(this.filterForm.get('airline').get('airlines').value)
          )
          this.oneForAll(this.filter, this.response.airItineraries, this.roundT);
          let t2 = performance.now();
          console.log(t2 - t1 + "ms");
          this.scrollH = 177 * this.orgnizedResponce.length + 100;
        }
      }
    ));

    // fareRules
    // this.search.fareRules(this.serachId,0).subscribe(
    //   (result)=>{
    //     this.fare = result;
    //     console.log("fareslength",this.fare);
    //   });



  }





  // filter func


  // new filteration method
  oneForAll(filter: flightFilter, fligtsArray: any[], round: boolean) {

    let filterdarray: any[] = [];
    fligtsArray.forEach(element => {

      // price test
      if (element.itinTotalFare.amount >= filter.priceMin && element.itinTotalFare.amount <= filter.priceMax) {

        //duration test
        if (element.totalDuration >= filter.durationMin && element.totalDuration <= filter.durationMax) {

          //departion test
          if (this.convertToMin(element.deptDate) >= filter.depatingMin && this.convertToMin(element.deptDate) <= filter.departingMax) {

            //stops filter
            if (this.stopscheck(filter.stops, element.allJourney.flights)) {
              // airline test
              if (filter.airlines.indexOf(element.allJourney.flights[0]['flightAirline']['airlineName']) !== -1 || filter.airlines.length == 0) {

                //return test
                if (false) {
                  // if(!round){
                  if (this.convertToMin(element.allJourney.flights[1]['flightDTO'][0]['departureDate']) >= filter.returnMin && this.convertToMin(element.allJourney.flights[1]['flightDTO']['departureDate']) <= filter.returnMax) {

                    filterdarray.push(element);
                  }
                  else {
                    return
                  }
                }
                else {
                  filterdarray.push(element);
                }
              }
              else {
                return
              }
            }
            else {
              return
            }
          }
          else {
            return
          }
        }
        else {
          return
        }

      }
      else {
        return
      }

    });
    let t1 = performance.now();

    this.orgnizedResponce = this.orgnize(filterdarray);
    //  this.valuesoftrue(this.orgnizedResponce);
    this.valuesoftrueM(this.orgnizedResponce);
    let t2 = performance.now();
    console.log("orgnizing and true value", t2 - t1 + "ms");
    //  // console.log('should be filterd',this.orgnizedResponce);
  }


  //  return min duration
  findDurationMinMax(array: any[]) {
    let min = array[0].totalDuration;
    let max = array[0].totalDuration;
    array.forEach(element => {
      if (element.totalDuration < min) {
        min = element.totalDuration;
      }
      if (element.totalDuration > max) {
        max = element.totalDuration;
      }
    });
    return [min, max + 100];
  }


  //filter by departing date
  findDepartingnMinMax(array: any[]) {
    let min = this.convertToMin(array[0].deptDate);
    let max = this.convertToMin(array[0].deptDate);
    array.forEach(element => {
      let t = this.convertToMin(element.deptDate)
      if (t < min) {
        min = t;
      }
      if (t > max) {
        max = t;
      }
    });
    return [min, max];
  }


  //  take date string return number
  convertToMin(time: string): number {
    let date = time;
    let T = date.indexOf('T');
    let h = date.slice(T + 1);
    let hr = +h.slice(0, 2) * 60;
    let m = +h.slice(3, 5);
    let tm = hr + m;
    return tm
  }


  minAnMax() {
    debugger;
    let len: number = 20;
    let minValue = this.response.airItineraries[0]['itinTotalFare']['amount'] - 1;
    len = this.response.airItineraries.length;
    len = len - 1;

    let maxValue1 = this.response.airItineraries[len]['itinTotalFare']['amount'] + 1;
    let maxValue = Math.max(...this.response.airItineraries.map(o => o['itinTotalFare']['amount']), 0);

    return [0, maxValue + 100];
  }

  //  remove airlines arr
  removeArrayControllers() {
    if (((<FormArray>this.filterForm.get('airline').get("airlines")).length)) {
      while (((<FormArray>this.filterForm.get('airline').get("airlines"))).length >= 1) {
        // (<FormArray>this.airlineForm.get("airline").get('airlines')).removeAt(0);
        (<FormArray>this.filterForm.get('airline').get("airlines")).removeAt(0);
      }
    }

  }


  //grouping by price and airline
  orgnize(array: any[]) {

    let ar = array
    let out: any[] = []
    ar.forEach(element => {
      let price: number = Math.round(element.itinTotalFare.amount);
      let airLine: string = element.allJourney.flights[0]['flightAirline']['airlineName'];
      // let lairLine: string = airLine.toLocaleLowerCase()
      let lairLine: string;
      if (localStorage.getItem('lang') == 'ar') {
        lairLine = airLine;
      }
      else {
        lairLine = airLine.toLocaleLowerCase()
      }
      let item = [];
      if (out.length == 0) {
        item.push(element);
        out.push(item);
      }
      else {
        let found: boolean = false;
        let i = 0
        while (i < out.length || i > 60) {
          let elmentO = out[i];
          let first = elmentO[0];
          let price2: number = Math.round(first.itinTotalFare.amount);
          // let airLine2: string = first.allJourney.flights[0]['flightAirline']['airlineName'];
          // let lairLine2: string = airLine.toLocaleLowerCase()
          let lairLine2: string;
          if (localStorage.getItem('lang') == 'ar') {
            lairLine2 = airLine;
          }
          else {
            lairLine2 = airLine.toLocaleLowerCase()
          }


          if (lairLine == lairLine2 && price == price2) {
            elmentO.push(element);
            found = true;
            break
          }
          else {
            i = i + 1
          }
        }
        if (!found) {
          item.push(element);
          out.push(item);
        }
      }

    });
    console.log(out);
    return out
  }
  // create an array with the same length of the output
  valuesoftrue(array: any[]) {
    let out: any[] = [];
    let arryalengty = array.length;
    for (let index = 0; index < arryalengty; index++) {
      let truth: boolean = true;
      out.push(truth);

    }
    this.moreT = out;
    return this.trueT = out;
  }
  // create an array with the same length of the output
  valuesoftrueM(array: any[]) {
    let out: any[] = [];
    let arryalengty = array.length;
    for (let index = 0; index < arryalengty; index++) {
      let truth: boolean = true;
      out.push(truth);

    }
    return this.moreT = out;
  }
  //get stops values 
  stopsvalues() {
    let out: number[] = [];
    if (this.filterForm.get('stopsForm').get('noStops').value) {
      out.push(0)
    }
    if (this.filterForm.get('stopsForm').get('oneStop').value) {
      out.push(1)
    }
    if (this.filterForm.get('stopsForm').get('twoAndm').value) {
      out.push(2);
      out.push(3);
      out.push(4);
    }
    if (!this.filterForm.get('stopsForm').get('noStops').value && !this.filterForm.get('stopsForm').get('oneStop').value && !this.filterForm.get('stopsForm').get('twoAndm').value) {
      out = [0, 1, 2, 3, 4];
    }
    return out
  }

  // airline filter 
  filteringbyairline(val: any[]) {
    let airL: any[] = [];
    for (let index = 0; index < val.length; index++) {
      const element = val[index];
      if (element) {
        airL.push(this.airlinesA[index]);
      }

    };
    if (airL.length == 0) {
      let out = airL;
      return out
    }
    else {
      return airL;
    }
  }


  stopscheck(stops: any[], flight: any[]) {
    let status: Boolean = true;
    let t1 = performance.now();
    flight.forEach(element => {
      if (stops.indexOf(element.stopsNum) == -1) {
        status = false;
      }

    });
    return status
  }


  // getDepartueCity&& departueCountry
  departureCity() {
    // item[0].allJourney.flights[0].flightDTO[0].departureTerminalAirport.cityName
    let city: string = this.response.airItineraries[0].allJourney.flights[0].flightDTO[0].departureTerminalAirport.cityName;
    let country: string = this.response.airItineraries[0].allJourney.flights[0].flightDTO[0].departureTerminalAirport.countryName;
    return city + "," + country;
  }
  // getlanding in oneway 
  landingCity() {
    // airItineraries[""0""].allJourney.flights[""0""].flightDTO[""0""].arrivalTerminalAirport

    let city: string = "";
    let country: string = '';
    // check if stops more than one
    let stops = this.response.airItineraries[0].allJourney.flights[0].stopsNum;

    if (stops > 0) {
      let flength = this.response.airItineraries[0].allJourney.flights[0].flightDTO.length;
      city = this.response.airItineraries[0].allJourney.flights[0].flightDTO[flength - 1].arrivalTerminalAirport.cityName;
      country = this.response.airItineraries[0].allJourney.flights[0].flightDTO[flength - 1].arrivalTerminalAirport.countryName;

    }
    else {
      city = this.response.airItineraries[0].allJourney.flights[0].flightDTO[0].arrivalTerminalAirport.cityName;
      country = this.response.airItineraries[0].allJourney.flights[0].flightDTO[0].arrivalTerminalAirport.countryName;
    }
    return city + "," + country;
  }

  // get depatrure in oneway 
  getDepartueDate() {


    let flight = this.flightsInfo.split("-");
    let departure = flight[2];
    return departure;
  }



  // Round Trip  //
  arrivalCity(item: any, flightN: number) {
    let ar: [] = item.allJourney.flights[flightN]["flightDTO"];
    let m: number = ar.length;
    let i: number = m - 1
    return item.allJourney.flights[flightN].flightDTO[i]["arrivalTerminalAirport"]["cityName"];
  }

  arrivalAircode(item: any, flightN: number) {
    let ar: [] = item.allJourney.flights[flightN]["flightDTO"];
    let m: number = ar.length;
    let i: number = m - 1
    return item.allJourney.flights[flightN].flightDTO[i]["arrivalTerminalAirport"]["airportCode"];
  }
  arrivalDate(item: any, flightN: number) {
    let ar: [] = item.allJourney.flights[flightN]["flightDTO"];
    let m: number = ar.length;
    let i: number = m - 1
    return item.allJourney.flights[flightN].flightDTO[i]['arrivalDate'];
  }

  arrivalStopCity(item: any, flightN: number) {
    let ar: [] = item.allJourney.flights[flightN]["flightDTO"];
    let m: number = ar.length;
    let i: number = 0
    for (i; i < m - 1; i++) {
      return item.allJourney.flights[flightN].flightDTO[i]["arrivalTerminalAirport"]["cityName"];
    }

  }
  arrivalStopAirPort(item: any, flightN: number) {
    let ar: [] = item.allJourney.flights[flightN]["flightDTO"];
    let m: number = ar.length;
    let i: number = 0
    for (i; i < m - 1; i++) {
      return item.allJourney.flights[flightN].flightDTO[i]["arrivalTerminalAirport"]["airportName"];
    }

  }
  arrivalStopAircode(item: any, flightN: number) {
    let ar: [] = item.allJourney.flights[flightN]["flightDTO"];
    let m: number = ar.length;
    let i: number = 0;
    for (i; i < m - 1; i++) {
      return item.allJourney.flights[flightN].flightDTO[i]["arrivalTerminalAirport"]["airportCode"];
    }
  }

  arrivalStopDate(item: any, flightN: number) {
    let ar: [] = item.allJourney.flights[flightN]["flightDTO"];
    let m: number = ar.length;
    let i: number = 0
    for (i; i < m - 1; i++) {
      return item.allJourney.flights[flightN].flightDTO[i]['arrivalDate'];
    }
  }

  direct(item: any) {
    if (item == 0) {
      return 'Direct';
    }
    else {
      return item + 'Stops ';
    }

  }


  showStopStation(item: any, flightN: number) {
    let stopArr: [] = [];
    let ar: [] = item.allJourney.flights[flightN]["flightDTO"];
    let m: number = ar.length;
    let i: number = 0;
    for (i; i < m; i++) {
      stopArr.push(ar[i]);
    }
    return stopArr;


  }

  fillSequenceArr() {

    for (let i = 0; i < this.response.airItineraries.length; i++) {
      this.sequenceNumber.push(this.response.airItineraries[i].sequenceNum);

    }
    console.log("sequenceNum", this.sequenceNumber)

  }


  // fareRulesbtn
  showFareRules(squencNumber: number, pKey: string) {

    console.log("called")
    this.FareLoading = true;
    this.search.fareRules(this.serachId, squencNumber, pKey).subscribe(
      (result) => {
        this.FareLoading = false;
        this.fare = result;
        console.log("fareslength", this.fare);
      }

    );
  }


  togglemodel(index: number, sequenceNum: number, pKey: string) {
    let id = '#fareModal' + index;
    $(id).modal('toggle');
    this.showFareRules(sequenceNum, pKey);
  }

  togglemodelt(i: number, m: number, sequenceNum: number, pKey: string) {
    let id = '#fareModal' + i + 'l' + m;
    $(id).modal('toggle');
    this.showFareRules(sequenceNum, pKey);
  }

  // sorting flights
  sortByDuration() {
    if (this.response && !this.loading) {

      this.filter = new FilterInputsModule(this.filterForm.get("priceSlider").value[0],
        this.filterForm.get("priceSlider").value[1],
        // new 
        this.filterForm.get('durationSlider').value[0],
        this.filterForm.get('durationSlider').value[1],
        this.filterForm.get('dpartingSlider').value[0],
        this.filterForm.get('dpartingSlider').value[1],
        this.filterForm.get('returnSlider').value[0],
        this.filterForm.get('returnSlider').value[1],
        this.stopsvalues(),
        this.filteringbyairline(this.filterForm.get('airline').get('airlines').value)
      )
    }
    let flightResult = this.response.airItineraries
    // console.log(flightResult);
    //  totalDuration=610
    flightResult = flightResult.sort((a, b) => { return a.totalDuration - b.totalDuration });
    this.oneForAll(this.filter, flightResult, this.roundT);
    this.scrollH = 177 * this.orgnizedResponce.length + 100;
    // console.log(flightResult);
    // this.resultUpdate.emit(flightResult)

  }

  sortByDeprting() {
    if (this.response && !this.loading) {

      this.filter = new FilterInputsModule(this.filterForm.get("priceSlider").value[0],
        this.filterForm.get("priceSlider").value[1],
        // new 
        this.filterForm.get('durationSlider').value[0],
        this.filterForm.get('durationSlider').value[1],
        this.filterForm.get('dpartingSlider').value[0],
        this.filterForm.get('dpartingSlider').value[1],
        this.filterForm.get('returnSlider').value[0],
        this.filterForm.get('returnSlider').value[1],
        this.stopsvalues(),
        this.filteringbyairline(this.filterForm.get('airline').get('airlines').value)
      )
    }
    let flightResult = this.response.airItineraries
    // console.log(flightResult);
    //  deptDate=2019-06-27T17:10:00
    flightResult = flightResult.sort((a, b) => { return <any>new Date(a.deptDate) - <any>new Date(b.deptDate) });
    this.oneForAll(this.filter, flightResult, this.roundT);
    this.scrollH = 177 * this.orgnizedResponce.length + 100;
    console.log(flightResult, 'hellow');
    // this.resultUpdate.emit(flightResult)

  }

  sortByArrival() {
    if (this.response && !this.loading) {

      this.filter = new FilterInputsModule(this.filterForm.get("priceSlider").value[0],
        this.filterForm.get("priceSlider").value[1],
        // new 
        this.filterForm.get('durationSlider').value[0],
        this.filterForm.get('durationSlider').value[1],
        this.filterForm.get('dpartingSlider').value[0],
        this.filterForm.get('dpartingSlider').value[1],
        this.filterForm.get('returnSlider').value[0],
        this.filterForm.get('returnSlider').value[1],
        this.stopsvalues(),
        this.filteringbyairline(this.filterForm.get('airline').get('airlines').value)
      )
    }
    let flightResult = this.response.airItineraries
    // console.log(flightResult);
    //  arrivalDate=2019-06-28T01:20:00
    flightResult = flightResult.sort((a, b) => { return <any>new Date(a.arrivalDate) - <any>new Date(b.arrivalDate) });
    this.oneForAll(this.filter, flightResult, this.roundT);
    this.scrollH = 177 * this.orgnizedResponce.length + 100;
    // console.log(flightResult);
    // this.resultUpdate.emit(flightResult)

  }
  //sorting function
  sortByPrice() {
    if (this.response && !this.loading) {

      this.filter = new FilterInputsModule(this.filterForm.get("priceSlider").value[0],
        this.filterForm.get("priceSlider").value[1],
        // new 
        this.filterForm.get('durationSlider').value[0],
        this.filterForm.get('durationSlider').value[1],
        this.filterForm.get('dpartingSlider').value[0],
        this.filterForm.get('dpartingSlider').value[1],
        this.filterForm.get('returnSlider').value[0],
        this.filterForm.get('returnSlider').value[1],
        this.stopsvalues(),
        this.filteringbyairline(this.filterForm.get('airline').get('airlines').value)
      )
    }
    let flightResult = this.response.airItineraries
    // console.log(flightResult);
    flightResult = flightResult.sort((a, b) => { return a.itinTotalFare.amount - b.itinTotalFare.amount });
    this.oneForAll(this.filter, flightResult, this.roundT);
    this.scrollH = 177 * this.orgnizedResponce.length + 100;
    // console.log(flightResult);
    // this.resultUpdate.emit(flightResult)

  }
  moretoggle(i: number, terms: number) {
    this.moreT[i] = !this.moreT[i];
    // this.addscroll = !this.addscroll;
    if (this.moreT[i]) {
      this.scrollH = this.scrollH - 177 * (terms - 1);

    } else {
      this.scrollH = this.scrollH + 177 * (terms - 1);
    }
    console.log('???');
  }
  // sort results from the menue 
  sortMyResult(val: number) {
    debugger
    console.log(val);
    if (val == 1) {
      this.sortByPrice();
    }
    if (val == 2) {
      this.sortByDuration();
    }
    if (val == 3) {
      this.sortByDeprting();
    }
    if (val == 4) {
      this.sortByArrival();
    }
  }

  // route to check out
  toCheckout(sid: string, sequenceNum: number) {
    this.router.navigate(['/checkout'], { queryParams: { 'sid': sid, 'sequenceNum': sequenceNum } });
  }
  //  route to home page flight as adefult 
  toHome(type: string) {
    this.router.navigate(['/']);
    sessionStorage.setItem('type', type);
  }




}


