import { TopDestinationService } from './../../Services/top-destination.service';
import { DatePipe } from '@angular/common';
import { CurruncyServiceService } from './../../Services/curruncy-service.service';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { NgbDate, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { HotelsCitiesModule } from 'src/app/models/hotels-cities/hotels-cities.module';
import { MyApiService } from 'src/app/Services/my-api.service';
import { SearchHoteltModule } from 'src/app/models/search-hotel/search-hotel.module';
import { PointOfsaleModule } from 'src/app/models/point-ofsale/point-ofsale.module';
import countriesen from 'src/assets/countries/countriesen.json';
import { CountriescodeModule } from 'src/app/models/countriescode/countriescode.module';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-hotel-search',
  templateUrl: './hotel-search.component.html',
  styleUrls: ['./hotel-search.component.css'],
  providers: [DatePipe]
})
export class HotelSearchComponent implements OnInit ,OnDestroy{
 
  value: any;
  hotelSearch: FormGroup;
  roomN: number = 1;
  dateT: boolean = false;
  dateChanged: boolean = false;
  dateErr: boolean = false;
  datStep: boolean = false;
  hoveredDate: NgbDate;
  step = 'Step1';
  fromDate: NgbDate;
  toDate: NgbDate;
  formday: Date;
  today: Date;
  currentday: NgbDate;
  RoomsA = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
  childrenA =[0,1,2];
  private subscription: Subscription = new Subscription();
  arrhotelcities: HotelsCitiesModule[];
  arrhotelnations: CountriescodeModule[];
  allGuest:number=0;
  nationArr:string[]=[];

  cityInputValidation:boolean = false;
  countries:any[];
  pointof: PointOfsaleModule;
  pointofC ='EG'
  citiesNames: string[] = [];
  cityLoader:boolean = false
  nationLoader:boolean = false

  constructor(
    private router: Router,
    calendar: NgbCalendar,
    public  myapi: MyApiService,
    private carruncy: CurruncyServiceService,
    private datePipe: DatePipe,
    private cdRef:ChangeDetectorRef,
    private topD:TopDestinationService, public translate:TranslateService) {

    this.fromDate = calendar.getToday();
    this.currentday = calendar.getToday();
    this.toDate = calendar.getNext(calendar.getToday(), 'd', 7);
    this.formday = new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day);
    this.today = new Date(this.toDate.year, this.toDate.month - 1, this.toDate.day);
  }


  
// detect change after rooms btn changes
  ngAfterViewChecked(){
    this.cdRef.detectChanges();

 }
  ngOnInit() {
    this.topD.isflight = false;
    this.subscription.add(this.topD.topDestinationevent.subscribe(
      (topdes)=>{
        this.hotelSearch.get('location').setValue(topdes);
        this.hotelSearch.get('location').updateValueAndValidity();
      }
    ));

    this.countries = countriesen;
    this.extractNationality(this.countries);

    this.hotelSearch = new FormGroup({
      location: new FormControl("", [Validators.required, Validators.minLength(3)]),
      nation: new FormControl("Kuwait", [Validators.minLength(3),this.natinotfound.bind(this)]),
      checkIn: new FormControl(this.formday, Validators.required),
      checkOut: new FormControl(this.today, Validators.required),
      roomN: new FormControl(1, [Validators.required, Validators.min(1)]),
      guestInfo: new FormArray([], [this.maxValueReached.bind(this)]),
    });

    (<FormArray>this.hotelSearch.get("guestInfo")).push(
      new FormGroup({
        adultN: new FormControl(2, [Validators.required, Validators.min(1), Validators.max(5)]),
        childN: new FormControl(0, [Validators.required, Validators.max(2)]),
        childGroup: new FormArray([])

    }));
    this.subscription.add(this.hotelSearch.get("roomN").valueChanges.subscribe(
      (val) => {
        if (val != null) {
          this.roomN = val;
        }
        else {
          this.roomN = 1;
        }


      }));

      this.subscription.add(this.hotelSearch.get("checkIn").valueChanges.subscribe(
      (val) => {}
    ));

    //Call Hotel Cities Request 
    this.subscription.add(
      this.hotelSearch.get('location')?.valueChanges.subscribe((res)=>{
        if(res.length >= 3){
          this.getCitiesById(res)
        }
      })    
    )
    //Call Hotel Nationality Request 
    this.subscription.add(
      this.getNationality('en') 
    )

    //get pointOfSale
    this.subscription.add(this.myapi.pointOfSale().subscribe(
      (result: PointOfsaleModule) => { this.pointof = result;
      this.pointofC= this.pointof.country; },
      (error) => { console.log(error) }
    ))

  }
getNationality(Key: string){
  this.nationLoader = true
  this.subscription.add(
    this.myapi.getCountries(Key).subscribe((res) => {
      this.nationLoader = false
      this.arrhotelnations = res;
    },err=>{
      this.nationLoader = false
    }))
}
  getCitiesById(Key: string) {
    this.cityLoader = true
    this.subscription.add(
      this.myapi.getHotelsCities(Key).subscribe((res) => {
        this.cityLoader = false
        this.arrhotelcities = res;
      },err=>{
        this.cityLoader = false
      }))
  }

  getGuestNum(rooms: number) {
    if (rooms <= 5) {
      for (let i = 0; i < rooms; i++) {
        (<FormArray>this.hotelSearch.get("guestInfo")).push(
          new FormGroup({
            adultN: new FormControl(2, [Validators.required, Validators.min(1), Validators.max(5)]),
            childN: new FormControl(0, [Validators.required, Validators.max(2)]),
            childGroup: new FormArray([])

          }));
      }


    }

  }

  //empty guest 
  guestEmpty() {
    while ((<FormArray>this.hotelSearch.get("guestInfo")).length > 0) {
      (<FormArray>this.hotelSearch.get("guestInfo")).removeAt(1);
    }
  }

  //get date of day
  todayDate() {
    let date = new Date();
    return date.toISOString().split('T')[0];

  }

  //get minDate of checkout
  minDate() {
    let date = new Date();
    if (this.dateChanged) {
      let tomorrow = this.hotelSearch.get("checkIn").value;
      tomorrow.setDate(tomorrow.getDate());
      return tomorrow.toISOString().split('T')[0];
    }
    else {
      return date.toISOString().split('T')[0];
    }
  }


  setMinDate(event: MatDatepickerInputEvent<Date>) {
    this.dateChanged = true;
    this.hotelSearch.get("checkOut").setValue(this.hotelSearch.get("checkIn").value);
    this.hotelSearch.get("checkOut").updateValueAndValidity();
  }

// add childAge
    addChildAge(guestGroup: FormGroup) {
    let childN = guestGroup.get('childN').value;
    if((<FormArray>guestGroup.get("childGroup")).length ==1)
    {
      if(childN == 0)
      {
      (<FormArray>guestGroup.get("childGroup")).removeAt(0);
 
      }
      if(childN == 2)
      {
        (<FormArray>guestGroup.get("childGroup")).push(new FormGroup({
          age: new FormControl(2, [Validators.required, Validators.min(2), Validators.max(11)]),
        }));

      }

    }
    else if((<FormArray>guestGroup.get("childGroup")).length ==2)
    {
      if(childN == 0)
      {

          while (0 < (<FormArray>guestGroup.get("childGroup")).length) {
          (<FormArray>guestGroup.get("childGroup")).removeAt(0);

        }
 
      }
      if(childN == 1)
      {
        (<FormArray>guestGroup.get("childGroup")).removeAt(0);
      }
    }
    else
    {
      if (childN <= 2) {
          for (let c = 0; c < childN; c++) {
            (<FormArray>guestGroup.get("childGroup")).push(new FormGroup({
              age: new FormControl(2, [Validators.required, Validators.min(2), Validators.max(11)]),
            }));
          }
        }
    }

  }

  // guest  number cant be more than 9
  maxValueReached(search: FormArray): { [b: string]: boolean } {
    let adults = 0;
    let childs = 0;

    for (let i = 0; i < search.length; i++) {

      adults += Number((<FormArray>this.hotelSearch.get("guestInfo")).controls[i].get('adultN').value)
      childs += Number((<FormArray>this.hotelSearch.get("guestInfo")).controls[i].get('childN').value)
    }
    this.allGuest=adults+childs;

    if (adults + childs > 9) {
      return { 'maxReched': true };
    }
    return null;
  }

  getcurr() {
    let curr = this.carruncy.currentCurruncy.Currency_Code
    if (curr) {
      return curr
    }
    else {
      return "AED"
    }
  }

  id() {
    let date = new Date();
    let myId = date.getFullYear() + 'B' + date.getUTCMonth() + 'I' + date.getUTCDay() + 'S' + date.getMilliseconds() + 'H' + Math.floor(Math.random() * (9 - 0 + 1)) + 0 + 'B' + Math.floor(Math.random() * (9 - 0 + 1)) + 0 + 'I'
      + Math.floor(Math.random() * (9 - 0 + 1)) + 0
      + 'S' + Math.floor(Math.random() * (9 - 0 + 1)) + 0 + 'H' + Math.floor(Math.random() * (9 - 0 + 1)) + 0 + 'I' + Math.floor(Math.random() * (9 - 0 + 1)) + 0;
    return myId;
  }

  formatGuestInfo(guestInfo: FormArray) {
    let guesttxt = '';

    for (let i = 0; i < guestInfo.length; i++) {
      guesttxt += "R" + i + "A" + guestInfo[i]['adultN'] + "C" + guestInfo[i]['childN']
      for (let j = 0; j < guestInfo[i]["childGroup"].length; j++) {
        guesttxt += "G" + guestInfo[i].childGroup[j]["age"];
      }
    }
    return guesttxt;
  }

// extract nationality
extractNationality(countries:any[]){
  for(let i=0;i<countries.length;i++)
  {
      this.nationArr.push(countries[i].countryName.toLowerCase());
  }
}
  // nationalit is not in nation array
 natinotfound(input: FormControl): { [n: string]: boolean } {

      if (this.nationArr.indexOf((<string>input.value).toLowerCase()) === -1) {
        return { 'notValidNation': true };
    }

    return null;
  }

  onSubmit() {
    if (this.hotelSearch.valid) {
      localStorage.setItem('hotelform', JSON.stringify(this.hotelSearch.value));

      let location = this.hotelSearch.get("location").value;
      let locationId = location.CityId;
      let citywithcountry = location.CityWithCountry;

      let nation = this.hotelSearch.get("nation").value;
      let checkIn = this.datePipe.transform(this.hotelSearch.get("checkIn").value, 'MMMM dd, y');

      let checkOut = this.datePipe.transform(this.hotelSearch.get("checkOut").value, 'MMMM dd, y');
      let roomNumber = this.hotelSearch.get("roomN").value;
      let guestInfo = this.hotelSearch.get("guestInfo").value;
      let stringGuest = this.formatGuestInfo(guestInfo);
      let searchApi: SearchHoteltModule = new SearchHoteltModule(localStorage.getItem('lang')?localStorage.getItem('lang'):'en', this.getcurr(), this.pointofC, this.id(), locationId, citywithcountry, nation, checkIn, checkOut, roomNumber, guestInfo);
      let language = searchApi.lan;
      let currency = searchApi.Currency;
      let SearchPoint = searchApi.POS;
      let searchId = searchApi.serachId;
     let cityId= this.hotelSearch.get('location')?.value.CityId

      if(typeof(this.hotelSearch.get("location").value)== 'object'){
        this.cityInputValidation = false;
        const url = this.router.serializeUrl(this.router.createUrlTree(['hotelResult', language, currency, SearchPoint, searchId, locationId, citywithcountry, nation, checkIn, checkOut, roomNumber, stringGuest,cityId]));
        window.location.href='http://hotels.travellii.com/'+url
      }
      else{
        this.cityInputValidation=true;
      }
    }
  }



  // add rome to rome array
  addRome() {
    let num = this.hotelSearch.get('roomN').value;
    this.hotelSearch.get('roomN').setValue(num + 1);
    this.hotelSearch.get('roomN').updateValueAndValidity();
    (<FormArray>this.hotelSearch.get("guestInfo")).push(
      new FormGroup({
        adultN: new FormControl(1, [Validators.required, Validators.min(1), Validators.max(5)]),
        childN: new FormControl(0, [Validators.required, Validators.max(2)]),
        childGroup: new FormArray([])

      }));
      (<FormArray>this.hotelSearch.get("guestInfo")).updateValueAndValidity();
      this.roomN = this.roomN+1;
  }
  //remove room
  removeRome() {
    let num = this.hotelSearch.get('roomN').value;
    this.hotelSearch.get('roomN').setValue(num - 1);
    this.hotelSearch.get('roomN').updateValueAndValidity();
    (<FormArray>this.hotelSearch.get("guestInfo")).removeAt(num-1);
    this.roomN = this.roomN-1;
  }

  //show cityname and country
  displayFn(city?: HotelsCitiesModule): string | undefined {
    return city ? city.City +','+city.Country : undefined;
  }


  // unsubscribe
  ngOnDestroy() {
    this.subscription.unsubscribe();

  }
}
