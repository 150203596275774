import { Component, OnInit,ViewChild, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
declare var $: any;


@Component({
  selector: 'app-term-ofuse',
  templateUrl: './term-ofuse.component.html',
  styleUrls: ['./term-ofuse.component.css']
})
export class TermOfuseComponent implements OnInit {
 

  constructor(public translate : TranslateService) { }

  ngOnInit() {
  }

  
}
