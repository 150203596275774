import { flight } from './../Components/checkout/checkout.component';
import { Injectable, EventEmitter } from '@angular/core';
import { MAT_RADIO_GROUP_CONTROL_VALUE_ACCESSOR } from '@angular/material/radio';

@Injectable({
  providedIn: 'root'
})
export class TopDestinationService {

  isflight:boolean = true;
  topDestinationevent:EventEmitter<any> = new EventEmitter();

  constructor() { }

  sendTopDestination(flight:any, hotel:any){

    // send top destination flight or hotel

    console.log(flight,hotel);

    if(this.isflight){
       this.topDestinationevent.emit(flight);
    }
    else{
       this.topDestinationevent.emit(hotel);
    }


  }


}
