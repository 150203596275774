import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-big-headder',
  templateUrl: './big-headder.component.html',
  styleUrls: ['./big-headder.component.css']
})
export class BigHeadderComponent implements OnInit {

  logedin: boolean = false;

  constructor() { }

  ngOnInit() {
    if (sessionStorage.getItem("cls")) {
      this.logedin = true;
    }
  }

}
