import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { MyApiService } from 'src/app/Services/my-api.service';

@Component({
  selector: 'app-upcoming-ahistory',
  templateUrl: './upcoming-ahistory.component.html',
  styleUrls: ['./upcoming-ahistory.component.css']
})
export class UpcomingAhistoryComponent implements OnInit,AfterViewInit {
  @Input()email:string;
  loading :boolean = true;
  panelOpenState = false;
  upcoming:any[];
  history:any[];
  upcomingl:number = 0;
  historyl:number = 0;
  

  constructor(private myapi:MyApiService) { 
  
  }

  ngOnInit() {
    console.log(this.email,"upcoming and history email")
   
  }
  ngAfterViewInit(){
    this.myapi.UpcomingAndHistory(this.email).subscribe(
      (result)=>{
        this.upcomingl = (<[]>result.upcomingFlights).length;
        this.historyl = (<[]>result.historyFlights).length;
        this.upcoming = result.upcomingFlights;
        this.history = result.historyFlights;
        this.loading = false;
        console.log(result);
      }
    )
  }
}
