<div class="row justify-content-center align-items-center" style="width: 100%;">
    <div class="card  col-12 col-12 col-md-4 " style="margin-top: 15vh ;padding: 20px;">
        <div class="col-12 text-center">
            <a routerLink="/">
                <img src="../../../../assets/img/travellii logo.png" class="logo" alt="logo">
            </a>
        </div>
        <div class="col-12 text-center mb-4">
            <h4>sign-up to Travellii </h4>
        </div>
        <div style="min-height: fit-content card-body">
            <form [formGroup]="signupform" class="row justify-content-center" (ngSubmit)="onSubmit()">
                <div class="col-12 col-md-10 ">
                    <mat-form-field color="accent" appearance="outline">
                        <i class="fas fa-user feildicon" matPrefix></i>
                        <input matInput placeholder="User name" required formControlName='User_Name' type="email">
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-5">
                    <mat-form-field color="accent" appearance="outline">
                        <i class="fas fa-user-tag feildicon" matPrefix></i>
                        <input matInput placeholder="First name" required formControlName='FirstName' type="email">
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-5">
                    <mat-form-field color="accent" appearance="outline">
                        <!-- <i class="fas fa-user feildicon" matPrefix></i> -->
                        <input matInput placeholder="Last name" required formControlName='LastName' type="email">
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-10">
                    <mat-form-field color="accent" appearance="outline">
                        <i class="fas fa-envelope feildicon" matPrefix></i>
                        <input matInput placeholder="Email address" required formControlName='Email' type="email" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$">
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-10">
                    <mat-form-field color="accent" appearance="outline">
                        <i class="fas fa-phone feildicon" matPrefix></i>
                        <input matInput placeholder="Phone" required formControlName='PhoneNumber' type="">
                    </mat-form-field>
                </div>

                <div class="col-12 col-md-10">
                    <mat-form-field color="accent" appearance="outline">
                        <i class="fas fa-lock feildicon" matPrefix></i>
                        <input matInput placeholder="Password" required formControlName='Password' type="password">
                    </mat-form-field>

                </div>
                <div class="col-12 col-md-10">
                    <mat-form-field color="accent" appearance="outline">
                        <i class="fas fa-lock feildicon" matPrefix></i>
                        <input matInput placeholder="Retype Password" required formControlName='ConfirmPassword' type="password">
                    </mat-form-field>
                </div>
                <div class=" col-12 col-md-10 text-center">
                    <button class="SingInButton" mat-raised-button click='onSubmit'>Sign UP</button>
                </div>

            </form>
        </div>
    </div>
</div>